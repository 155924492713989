import React from 'react';
import ThreeDMainBox from '../containers/ThreeDMainBox/ThreeDMainBox';
import sm from './styles.module.scss';
import { TContainerThreeDProps } from '../types';

const ThreeDContainer: React.FC<TContainerThreeDProps> = ({
  index,
  isBig,
  currentContainer,
  customCalcPosition,
  calculatedContainerSize
}) => {
  return (
    <div
      className={`${sm.Page_ThreeDContainer} ${
        isBig ? `${sm.Page_ThreeDContainer_big}` : ''
      }`}
    >
      <ThreeDMainBox
        isBig={isBig}
        index={index}
        currentContainer={currentContainer}
        customCalcPosition={customCalcPosition}
        calculatedContainerSize={calculatedContainerSize}
      />
    </div>
  );
};

export default ThreeDContainer;
