export const getTextByItemsLength = (
  length: number | undefined
  // textInside: string
): string => {
  return length && length === 1 ? `Some group` : `Some groups`;
};

export const getGroupsQuantityErrorMessage = (
  groupsQuantity: number
): string => {
  if (groupsQuantity >= 8 && groupsQuantity < 10) {
    return `You can create ${getTextByItemsLength(
      10 - groupsQuantity
      // 'more'
    )}`;
  }
  if (groupsQuantity === 10) {
    return 'You cannot create a group';
  }
  return '';
};
