import React from 'react';
import * as THREE from 'three';
import { Vector3 } from 'three';
import { ILineText, TNumberPossition } from '../../types';
import CustomLine from '../CustomLine/CustomLine';
import LineText from '../LineText/LineText';
import Triangle from '../Triangle/Triangle';

interface IProps extends ILineText {
  position: TNumberPossition;
  rotate: TNumberPossition;
  points: Array<Vector3 | [number, number, number]>;
  triangleEndPosition: number;
  triangleStartPosition: number;
  lineWidth: number;
  findMaxOfBiggesCoeff: number;
}

const SizeLine: React.FC<IProps> = ({
  position,
  rotate,
  points,
  lineText,
  lineTextPosition,
  lineTextRotation,
  triangleEndPosition,
  triangleStartPosition,
  lineWidth,
  findMaxOfBiggesCoeff
}) => {
  const vecrticesOne = [
    new THREE.Vector3(
      0.05 * findMaxOfBiggesCoeff,
      0,
      0.1 * findMaxOfBiggesCoeff
    ),
    new THREE.Vector3(0.1 * findMaxOfBiggesCoeff, 0, 0),
    new THREE.Vector3(0, 0, 0)
  ];

  return (
    <mesh position={position} rotation={rotate}>
      <CustomLine
        position={[0, 0, 0]}
        points={points}
        color="grey"
        lineWidth={lineWidth}
      />
      <Triangle
        vertices={vecrticesOne}
        meshPosition={[-0.05 * findMaxOfBiggesCoeff, 0, -triangleStartPosition]}
        isRotate={false}
      />
      <Triangle
        vertices={vecrticesOne}
        meshPosition={[-0.05 * findMaxOfBiggesCoeff, 0, -triangleEndPosition]}
        isRotate={!false}
      />
      <LineText
        lineText={lineText}
        lineTextPosition={lineTextPosition}
        lineTextRotation={lineTextRotation}
        findMaxOfBiggesCoeff={findMaxOfBiggesCoeff}
      />
    </mesh>
  );
};

export default SizeLine;
