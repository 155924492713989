import { useForm, UseFormReturn } from 'react-hook-form';
import { SetterOrUpdater, useRecoilState } from 'recoil';
import { feedbackOptions } from '../../constants/feedbackConstants';
import { resultsSentState } from '../../state/calculationResultsState';
import { modalOpenState } from '../../state/modalState';
import { FeedbackFormFields } from '../../types';
import { useShowFeedbackForm } from '../FeedbackForm/hooks/useShowFeedbackForm';

interface UseSuccessModalContentApi {
  showFeedbackForm: boolean;
  feedbackFormMethods: UseFormReturn<FeedbackFormFields>;
  setModalOpen: SetterOrUpdater<boolean>;
  setSentResults: SetterOrUpdater<boolean>;
}

export const useSuccessModalContent = (): UseSuccessModalContentApi => {
  const [, setSentResults] = useRecoilState(resultsSentState);
  const [, setModalOpen] = useRecoilState(modalOpenState);
  const feedbackFormMethods = useForm<FeedbackFormFields>({
    defaultValues: { message: feedbackOptions.awesome }
  });

  const { showFeedbackForm } = useShowFeedbackForm();

  return {
    showFeedbackForm,
    feedbackFormMethods,
    setModalOpen,
    setSentResults
  };
};
