import React from 'react';
import { TSpaceCalculatorLandingListData } from '../../types';
import sm from './styles.module.scss';

type TProps = {
  item: TSpaceCalculatorLandingListData;
};
const SpaceCalculatorLandingItem: React.FC<TProps> = ({ item }) => {
  const { title, featuresList, Icon } = item;
  return (
    <li className={sm.ItemDataWrrapper}>
      <div className={sm.ItemDataWrrapper_iconWrapper}>
        <Icon />
      </div>
      <h4 className={sm.ItemDataWrrapper_title}>{title}</h4>
      <ul className={sm.ItemDataWrrapper_list}>
        {featuresList.map((el) => (
          <li className={sm.ItemDataWrrapper_listItem} key={`${el}`}>
            {el}
          </li>
        ))}
      </ul>
    </li>
  );
};

export default SpaceCalculatorLandingItem;
