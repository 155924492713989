import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { scrollState } from '../state/scrollState';

const useScrollPosition = (): void => {
  const [, setIsScroll] = useRecoilState(scrollState);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY !== 0) {
        setIsScroll(true);
      } else {
        setIsScroll(false);
      }
    };
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [window.scrollY]);
};

export default useScrollPosition;
