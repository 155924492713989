import React, { CSSProperties, InputHTMLAttributes } from 'react';
import { FieldPath, useFormContext } from 'react-hook-form';
import cn from 'classnames';
import { LoadGroupFields } from '../../../../types';
import Input from '../../../../components/Input/Input';
import sm from './styles.module.scss';
import stylesField from '../styles.module.scss';
import useFieldError from '../../../../hooks/useFieldError';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  fieldName: FieldPath<LoadGroupFields>;
  label: string;
  defaultValue: string;
  color: string;
}

export type NameFieldProps = Props;

const NameField: React.FC<Props> = ({
  fieldName,
  label,
  defaultValue,
  color,
  ...rest
}) => {
  const { register } = useFormContext();
  const error = useFieldError(fieldName);
  return (
    <div>
      <label htmlFor={fieldName} className={stylesField.Field_Label}>
        {label}
      </label>
      <div className={sm.NameField}>
        <div
          className={sm.NameField_Color}
          style={{ '--loadGroup-color-variable': color } as CSSProperties}
        />
        <Input
          {...rest}
          type="text"
          classname={cn(sm.NameField_Input, {
            [sm.NameField_Input__Error]: !!error
          })}
          defaultValue={defaultValue}
          {...register(fieldName)}
        />
      </div>
    </div>
  );
};

export default NameField;
