import React from 'react';
import { ReactComponent as RedPart } from '../../assets/images/home/red.svg';
import sm from './styles.module.scss';

const SpaceCalculatorLabel: React.FC = () => {
  return (
    <div className={sm.SpaceCalculatorLabel}>
      <div className={sm.SpaceCalculatorLabel_Text}>
        <div className={sm.SpaceCalculatorLabel_Title}>Space calculator</div>
        <div className={sm.SpaceCalculatorLabel_Subtitle}>
          We help you to analyze spaciousness of things in space
        </div>
      </div>

      <RedPart />
    </div>
  );
};

export default SpaceCalculatorLabel;
