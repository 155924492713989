import { AxiosError, AxiosResponse } from 'axios';
import { UseMutateFunction, useMutation } from 'react-query';
import { useRecoilState } from 'recoil';
import { contactInfoDefault } from '../constants';
import { useShowFeedbackForm } from '../containers/FeedbackForm/hooks/useShowFeedbackForm';
import { sendFeedback } from '../network/services';
import { resultsSentState } from '../state/calculationResultsState';
import {
  contactInfoState,
  feedbackErrorState
} from '../state/contactInfoState';
import { modalOpenState } from '../state/modalState';
import { FeedbackRequestEntry } from '../types';
import { getRequestErrorByStatus } from '../utils/getRequestErrorByStatus';

interface UseFeedbackMutationApi {
  mutate: UseMutateFunction<
    AxiosResponse,
    unknown,
    FeedbackRequestEntry,
    unknown
  >;
  isLoading: boolean;
  isError: boolean;
}

export const useFeedbackMutation = (): UseFeedbackMutationApi => {
  const [, setIsSent] = useRecoilState(resultsSentState);
  const [, setModalOpen] = useRecoilState(modalOpenState);
  const [, setContactInfo] = useRecoilState(contactInfoState);
  const [isError, setErrorState] = useRecoilState(feedbackErrorState);
  const { handleSetFeedback } = useShowFeedbackForm();
  const { mutate, isLoading } = useMutation('sendFeedback', sendFeedback, {
    onSuccess: () => {
      setModalOpen(false);
      setIsSent(false);
      setContactInfo(contactInfoDefault);
      handleSetFeedback();
    },
    onError: (err) => getRequestErrorByStatus(err as AxiosError, setErrorState)
  });

  return {
    mutate,
    isLoading,
    isError
  };
};
