import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useRecoilState } from 'recoil';
import { contactInfoDefault } from '../../constants';
import { resultsSentState } from '../../state/calculationResultsState';
import { SendResultsFormFields } from '../../types';
import { sendResultValidationSchema } from '../../validation/sendResultValidationSchema';
import SendResultsForm from '../SendResultsForm/SendResultsForm';
import SuccessModalContent from '../SuccessModalContent/SuccessModalContent';
import sm from './styles.module.scss';

const ResultsModalContent: React.FC = () => {
  const [isResultsSent] = useRecoilState(resultsSentState);
  const resultsFormMethods = useForm<SendResultsFormFields>({
    resolver: yupResolver(sendResultValidationSchema),
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: {
      contactInfo: contactInfoDefault
    }
  });

  return (
    <div className={sm.ResultsModalContent}>
      {isResultsSent ? (
        <SuccessModalContent />
      ) : (
        <FormProvider {...resultsFormMethods}>
          <SendResultsForm formMethods={resultsFormMethods} />
        </FormProvider>
      )}
    </div>
  );
};

export default ResultsModalContent;
