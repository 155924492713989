import * as yup from 'yup';
import { AnyObjectSchema } from 'yup';

import { LoadType } from '../constants/loadGroupsConstants';

const loadGroupValidationSchema = (minValue: number): AnyObjectSchema => {
  return yup.object().shape({
    loadGroups: yup
      .array()
      .min(1)
      .max(10)
      .of(
        yup.object().shape({
          name: yup.string().required('This field is required').max(64),
          description: yup.string().max(255),
          height: yup
            .number()
            .positive()
            .min(minValue)
            .lessThan(10000)
            .required('This field is required')
            .test(
              'maxDigitsAfterDecimal',
              'number field must have 2 digits after decimal or less',
              (value) =>
                value ? /^\d+(\.\d{1,2})?$/.test(value?.toString()) : false
            ),
          loadType: yup.string().required(),
          width: yup.number().when('loadType', {
            is: (loadType: string) => loadType !== LoadType.Drums,
            then: yup
              .number()
              .positive()
              .min(minValue)
              .lessThan(10000)
              .required('This field is required')
              .test(
                'maxDigitsAfterDecimal',
                'number field must have 2 digits after decimal or less',
                (value) =>
                  value ? /^\d+(\.\d{1,2})?$/.test(value?.toString()) : false
              )
          }),
          length: yup.number().when('loadType', {
            is: (loadType: string) => loadType !== LoadType.Drums,
            then: yup
              .number()
              .positive()
              .min(minValue)
              .lessThan(10000)
              .required('This field is required')
              .test(
                'maxDigitsAfterDecimal',
                'number field must have 2 digits after decimal or less',
                (value) =>
                  value ? /^\d+(\.\d{1,2})?$/.test(value?.toString()) : false
              )
          }),
          diameter: yup.number().when('loadType', {
            is: (loadType: string) => loadType === LoadType.Drums,
            then: yup
              .number()
              .positive()
              .min(minValue)
              .lessThan(10000)
              .required('This field is required')
              .test(
                'maxDigitsAfterDecimal',
                'number field must have 2 digits after decimal or less',
                (value) =>
                  value ? /^\d+(\.\d{1,2})?$/.test(value?.toString()) : false
              )
          }),
          weight: yup
            .number()
            .positive()
            .lessThan(10000)
            .required('This field is required')
            .test(
              'maxDigitsAfterDecimal',
              'number field must have 2 digits after decimal or less',
              (value) =>
                value ? /^\d+(\.\d{1,2})?$/.test(value?.toString()) : false
            ),
          quantity: yup
            .number()
            .integer()
            .positive()
            .lessThan(1000)
            .required('This field is required'),
          pallets: yup.number().integer().lessThan(1000).optional()
        })
      )
  });
};

export default loadGroupValidationSchema;
