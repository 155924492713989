import { AxiosError, AxiosResponse } from 'axios';
import { useEffect } from 'react';
import { UseMutateFunction, useMutation } from 'react-query';
import { useRecoilState } from 'recoil';
import { calculateContainers } from '../network/services';
import {
  calculationsErrorState,
  calculationsLoadingState,
  calculationsState
} from '../state/calculationsState';
import { isDefaultContainersState } from '../state/containersState';
import { CalculateRequestEntry, CalculcationResult } from '../types';
import { getRequestErrorByStatus } from '../utils/getRequestErrorByStatus';

interface UseCalculationMutationApi {
  mutate: UseMutateFunction<
    AxiosResponse<CalculcationResult>,
    unknown,
    CalculateRequestEntry,
    unknown
  >;
  isError: boolean;
}

export const useCalculationMutation = (): UseCalculationMutationApi => {
  const [, setCalculationResult] = useRecoilState(calculationsState);
  const [, setLoadingState] = useRecoilState(calculationsLoadingState);
  const [, setContainerState] = useRecoilState(isDefaultContainersState);
  const [isError, setErrorState] = useRecoilState(calculationsErrorState);

  const { mutate, isLoading } = useMutation(
    'calculateContainers',
    calculateContainers,
    {
      onSuccess: (result) => {
        setCalculationResult(result.data);
        setContainerState(false);
      },
      onError: (err) =>
        getRequestErrorByStatus(err as AxiosError, setErrorState)
    }
  );

  useEffect(() => {
    setLoadingState(isLoading);
  }, [isLoading]);

  return {
    mutate,
    isError
  };
};
