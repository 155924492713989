import React, { CSSProperties } from 'react';
import sm from './styles.module.scss';

interface TextWithColorProps {
  color: string;
  label: string;
}

const TextWithColor: React.FC<TextWithColorProps> = ({ color, label }) => {
  return (
    <div className={sm.TextWithColor}>
      <div
        className={sm.TextWithColor_Color}
        style={{ '--loadGroup-color-variable': color } as CSSProperties}
      />
      <div className={sm.TextWithColor_Name}>{label}</div>
    </div>
  );
};

export default TextWithColor;
