import React, { useMemo } from 'react';
import { Placement, TGetSizes, TLinePosition } from '../../types';
import scaleCalc from '../../utils/scaleCalc';
import BoxesWrapper from '../BoxesWrapper/BoxesWrapper';
import CustomWrapperLines from '../CustomWrapperLines/CustomWrapperLines';
import SizeLinesOfWrapperLines from '../SizeLinesOfWrapperLines/SizeLinesOfWrapperLines';

type TProps = {
  currentContainer: Placement;
  customCalcPosition: TLinePosition[];
  calculatedContainerSize: TGetSizes;
};

const WrapBox: React.FC<TProps> = ({
  currentContainer,
  customCalcPosition,
  calculatedContainerSize
}) => {
  const {
    container: { width, height, length },
    positions
  } = currentContainer;

  const calcScale = useMemo(() => {
    return scaleCalc(width, height, length);
  }, []);

  return (
    <mesh
      position={[0, 0, 0]}
      rotation={[0.7, (Math.PI / 2) * 2.5, 0]}
      scale={calcScale.findBiggesCoeff}
    >
      <mesh position={[-calculatedContainerSize.Glength / 2 + 0.3, 0.4, 0]}>
        <CustomWrapperLines
          customLinePositions={customCalcPosition}
          borderColor="red"
        />
        <SizeLinesOfWrapperLines
          calculatedContainerSize={calculatedContainerSize}
          width={width}
          height={height}
          length={length}
          findMaxOfBiggesCoeff={calcScale.findMaxOfBiggesCoeff}
        />
        <BoxesWrapper positions={positions} />
      </mesh>
    </mesh>
  );
};

export default WrapBox;
