import { FieldPath, UseFormWatch, FieldValues } from 'react-hook-form';

interface Props<F extends Record<string, unknown>> {
  value: string;
  fieldName: FieldPath<F>;
  watch: UseFormWatch<FieldValues>;
  integerPartLength: number;
}

const setValueAsDecimal = <F extends Record<string, unknown>>({
  value,
  fieldName,
  watch,
  integerPartLength
}: Props<F>): FieldValues | string => {
  const numVal = Number(value);
  const beforeDot =
    value.includes('.') && value.split('.')[0].length < integerPartLength;
  const afterDot = value.includes('.') && value.split('.')[1].length < 3;

  const allowableValue = value.includes('.')
    ? beforeDot && afterDot
    : value.length < integerPartLength;

  if (Number.isNaN(numVal) || !allowableValue) {
    return watch(fieldName);
  }
  return value.replace(/^0+(?=\d.?)/, '');
};

export default setValueAsDecimal;
