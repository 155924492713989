import { SpaceType } from '../constants/spaceConstants';
import { Spaces } from '../types';

export const displayContainerTypeName = (
  length: number,
  containerName: Spaces
): string => {
  const defaultText =
    containerName === SpaceType.Custom
      ? `${containerName?.toLowerCase()} space`
      : containerName?.toLowerCase();
  const pluralText = `${defaultText}s`;

  if (!length) {
    return '';
  }
  if (length === 1) {
    return `${length} ${defaultText}`;
  }
  return `${length} ${pluralText}`;
};
