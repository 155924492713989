import React from 'react';
import { Line } from '@react-three/drei';
import { TLinePosition } from '../../types';

interface IProps extends TLinePosition {
  color: string;
  lineWidth?: number;
}

const CustomLine: React.FC<IProps> = ({
  position,
  points,
  color,
  lineWidth = 2
}) => {
  return (
    <>
      <Line
        points={points}
        color={color}
        lineWidth={lineWidth}
        position={position}
      />
    </>
  );
};

export default CustomLine;
