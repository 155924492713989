import React from 'react';
import { TSpaceCalculatorLandingListData } from '../../types';
import SpaceCalculatorLandingItem from '../SpaceCalculatorLandingItem/SpaceCalculatorLandingItem';
import sm from './styles.module.scss';

type Tprops = {
  listData: TSpaceCalculatorLandingListData[];
};
const SpaceCalculatorLandingList: React.FC<Tprops> = ({ listData }) => {
  return (
    <div className={sm.ListDataWrrapper}>
      <ul className={sm.ListDataWrrapper_List}>
        {listData.map((item) => (
          <SpaceCalculatorLandingItem key={`${item.title}`} item={item} />
        ))}
      </ul>
    </div>
  );
};

export default SpaceCalculatorLandingList;
