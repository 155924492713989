import React from 'react';
import BenefitsCalculatorLanding from '../BenefitsCalculatorLanding/BenefitsCalculatorLanding';
import SpaceCalculatorLanding from '../SpaceCalculatorLanding/SpaceCalculatorLanding';

import sm from './styles.module.scss';

const HomePageLanding: React.FC = () => {
  return (
    <div className={sm.HomePageLanding}>
      <h1 className={sm.HomePageLanding_Heading}>
        Simplify load planning and maximize the use of your space in trucks,
        containers, and warehouses.
      </h1>
      <SpaceCalculatorLanding />
      <BenefitsCalculatorLanding />
    </div>
  );
};

export default HomePageLanding;
