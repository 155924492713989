import { Limit } from '../types';

export const SpaceType = {
  Container: 'Container',
  Vehicle: 'Vehicle',
  Storage: 'Storage',
  Custom: 'Custom'
} as const;

export const customSpaceWeightMetric = 4535;
export const customSpaceWeightImperial = 10000;

export const spaceLimitDefault: Limit = {
  min: 15,
  max: 90
};

export const weightLimitDefault: Limit = {
  min: 15,
  max: 100
};

export const defaultColorsBarState = { color: '', index: -1 };

export const defaultSpaceFormState = {
  name: 'Container',
  length: 0,
  width: 0,
  height: 0,
  maxWeight: 0,
  volume: 0,
  spaceLimit: spaceLimitDefault,
  weightLimit: weightLimitDefault
} as const;
