import React from 'react';
import sm from './styles.module.scss';

interface SelectProps {
  options: SelectOption[];
  label: string;
  handleChange: (value: string) => void;
  value: string;
}

interface SelectOption {
  value: string;
  label: string;
}

const Select: React.FC<SelectProps> = ({
  label,
  options,
  handleChange,
  value
}) => {
  return (
    <div className={sm.Select_Container}>
      <label className={sm.Select_Label}>{label}</label>
      <div className={sm.Select}>
        <select
          onChange={(e) => handleChange(e.target.value)}
          className={sm.Select_Native}
          value={value}
        >
          {options.map(({ value: optionValue, label: optionLabel }) => (
            <option key={optionLabel} label={optionLabel} value={optionValue} />
          ))}
        </select>
      </div>
    </div>
  );
};

export default Select;
