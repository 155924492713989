import React from 'react';
import sm from './styles.module.scss';

const ErrorIndicator: React.FC = () => (
  <div className={sm.ErrorIndicator}>
    <div className={sm.ErrorIndicator_Text}>
      <span>Oops</span>
      <br />
      <span>Something went wrong</span>
    </div>
  </div>
);

export default ErrorIndicator;
