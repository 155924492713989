export const feedbackOptions = {
  awesome: 'Awesome tool',
  features: 'Missing features',
  broken: 'Something is broken',
  notLike: "Didn't like the app"
};

export const feedbackFormData = [
  {
    value: feedbackOptions.awesome,
    icon: '😄'
  },
  {
    value: feedbackOptions.features,
    icon: '😿'
  },
  {
    value: feedbackOptions.broken,
    icon: '🔧'
  },
  {
    value: feedbackOptions.notLike,
    icon: '😕'
  }
];
