import React, { CSSProperties } from 'react';
import { SetterOrUpdater } from 'recoil';
import cn from 'classnames';
import { ColorBar, ColorsBarState } from '../../types';
import { getPercentDisplaying } from '../../utils/getPercentDisplaying';
import TextWithColor from '../TextWithColor/TextWithColor';
import Tooltip from '../Tooltip/Tooltip';
import sm from './styles.module.scss';
import { defaultColorsBarState } from '../../constants/spaceConstants';

interface ColorsBarProps {
  title: string;
  colors: ColorBar[];
  totalPercent: number;
  hoveredColor: ColorsBarState;
  setHoveredColor: SetterOrUpdater<ColorsBarState>;
  index: number;
}

const ColorsBar: React.FC<ColorsBarProps> = ({
  title,
  totalPercent,
  colors,
  hoveredColor,
  setHoveredColor,
  index
}) => {
  return (
    <div className={sm.ColorsBar}>
      <div className={sm.ColorsBar_Row}>
        <div className={sm.ColorsBar_Container}>{title}</div>
        <div>{getPercentDisplaying(totalPercent)}</div>
      </div>
      <div className={sm.ColorsBar_Bar}>
        {colors.map((currentColor) => {
          const { color, percent, name } = currentColor;
          const isHovered =
            hoveredColor.color === color && hoveredColor.index === index;
          return (
            <div
              key={color}
              style={
                {
                  '--loadGroup-color-variable': color,
                  '--colorBar-width-variable': percent
                } as CSSProperties
              }
              className={cn(sm.ColorsBar_BarItem, {
                [sm.ColorsBar_BarItem__Hovered]: isHovered
              })}
              onMouseEnter={() => setHoveredColor({ color, index })}
              onMouseLeave={() => setHoveredColor(defaultColorsBarState)}
            >
              <Tooltip
                message={<TextWithColor color={color} label={name} />}
                classname={sm.ColorsBar_BarItem_Tooltip}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ColorsBar;
