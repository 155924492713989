const ENDPOINTS = {
  LOAD_GROUPS: {
    DEFAULT_DATA: '/predefined_data'
  },
  CALCULATE: '/calculate',
  EXPORT: '/export',
  FEEDBACK: '/feedback'
};

export default ENDPOINTS;
