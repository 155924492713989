import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';

import sm from './styles.module.scss';

import InputField from '../SpaceForm/FormFields/InputField/InputField';
import { unitsState } from '../../state/unitsState';
import useSpaceFormData from '../SpaceForm/hooks/useSpaceFormData';
import numberDisplay from '../../utils/displayNumber';
import useFetchData from '../../hooks/useFetchData';
import { UnitsSymbolByName } from '../../constants/unitsConstants';
import { useGlobalFormMethods } from '../GlobalFormsProvider/GlobalFormsProvider';

const SizeSelection: React.FC = () => {
  const [currentUnits] = useRecoilState(unitsState);

  const { volumeValue, spaceTypeValue } = useSpaceFormData();
  const { spaceFormValues } = useFetchData();
  const { spaceFormMethods } = useGlobalFormMethods();
  const [state, setstate] = useState<string | null>(null);

  useEffect(() => {
    if (spaceTypeValue !== state) {
      setstate(spaceTypeValue);
    }
  }, [spaceTypeValue]);

  useEffect(() => {
    if (state) {
      setTimeout(() => {
        spaceFormMethods.trigger();
      }, 0);
    }
  }, [state]);

  return (
    <div className={sm.SizeSelection}>
      <div className={sm.SizeSelection_Title}>Space size</div>

      <div className={sm.SizeSelection_Content}>
        <div className={sm.SizeSelection_Field}>
          <div
            className={sm.SizeSelection_Label}
          >{`Volume, ${UnitsSymbolByName[currentUnits].volume}`}</div>
          <div className={sm.SizeSelection_Value}>
            {numberDisplay({ num: volumeValue })}
          </div>
        </div>

        <InputField
          defaultValue={`${spaceFormValues.length}`}
          fieldName="space.length"
          label="Length"
          textAfter={UnitsSymbolByName[currentUnits].lengthM}
        />

        <InputField
          defaultValue={`${spaceFormValues.width}`}
          fieldName="space.width"
          label="Width"
          textAfter={UnitsSymbolByName[currentUnits].lengthM}
        />

        <InputField
          defaultValue={`${spaceFormValues.height}`}
          fieldName="space.height"
          label="Height"
          textAfter={UnitsSymbolByName[currentUnits].lengthM}
        />

        <div className={sm.SizeSelection_InputWeight}>
          <InputField
            defaultValue={`${spaceFormValues.maxWeight}`}
            fieldName="space.maxWeight"
            label="Weight capacity"
            textAfter={UnitsSymbolByName[currentUnits].weight}
          />
        </div>
      </div>
    </div>
  );
};

export default SizeSelection;
