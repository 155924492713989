import React, { ReactElement } from 'react';
import { ActionOptions, Spaces } from '../types';

import { ReactComponent as ContainerIcon } from '../assets/images/icon-spaceContainer.svg';
import { ReactComponent as VehicleIcon } from '../assets/images/icon-spaceVehicle.svg';
import { ReactComponent as StorageIcon } from '../assets/images/icon-spaceStorage.svg';
import { ActionTypes } from '../constants';
import { SpaceType } from '../constants/spaceConstants';

interface DataByHomePageSelectionApi {
  icon: ReactElement;
  label: string;
  space: Spaces;
}

const getDataByHomePageSelection = (
  option: ActionOptions
): DataByHomePageSelectionApi => {
  const byActionType = {
    [ActionTypes.Ship]: {
      icon: <ContainerIcon />,
      label: 'Ship a container',
      space: SpaceType.Container
    },
    [ActionTypes.Move]: {
      icon: <VehicleIcon />,
      label: 'Move',
      space: SpaceType.Vehicle
    },
    [ActionTypes.Transfer]: {
      icon: <StorageIcon />,
      label: 'Transfer to storage',
      space: SpaceType.Storage
    }
  };

  return byActionType[option];
};

export default getDataByHomePageSelection;
