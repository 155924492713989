import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  getContainersQuantity,
  getContainerType,
  getNotPlacedItems
} from '../../state/calculationsState';

import { displayContainerTypeName } from '../../utils/displayContainerTypeName';
import Button from '../../components/Button/Button';
import { modalOpenState } from '../../state/modalState';

import sm from './styles.module.scss';

const CalculationResultsTitle: React.FC = () => {
  const notPlacedItems = useRecoilValue(getNotPlacedItems);
  const containerType = useRecoilValue(getContainerType);
  const containersQuantity = useRecoilValue(getContainersQuantity);
  const [, setModalOpen] = useRecoilState(modalOpenState);

  return (
    <div className={sm.CalculationResultsTitle}>
      <div className={sm.CalculationResultsTitle_TitleRow}>
        {containersQuantity ? (
          <>
            <div
              className={sm.CalculationResultsTitle_Title}
            >{`Total ${displayContainerTypeName(
              containersQuantity,
              containerType
            )}`}</div>
            <Button
              title="Save results"
              classname={sm.CalculationResultsTitle_Button}
              onClick={() => setModalOpen(true)}
              variant="outlined"
              disabled={!!notPlacedItems}
            />
          </>
        ) : null}
        {notPlacedItems && !containersQuantity && (
          <div className={sm.CalculationResultsTitle_Title}>
            Calculation is not possible...
          </div>
        )}
      </div>
    </div>
  );
};

export default CalculationResultsTitle;
