import { useHistory } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import { useFieldArray } from 'react-hook-form';
import { Paths } from '../../../constants/paths';
import { ActionTypes } from '../../../constants';
import { ActionOptions, LoadGroupFields } from '../../../types';
import { homePageState } from '../../../state/homePageState';
import useFetchData from '../../../hooks/useFetchData';
import { useGlobalFormMethods } from '../../GlobalFormsProvider/GlobalFormsProvider';

export const useHomePageContent = (): ((
  option: ActionOptions
) => Promise<void>) => {
  const history = useHistory();
  const { loadGroupsFormMethods, spaceFormMethods } = useGlobalFormMethods();
  const [homePageStateValue, setHomePageState] = useRecoilState(homePageState);
  const { moveOptionDefaultFormData } = useFetchData();
  const { insert } = useFieldArray<LoadGroupFields>({
    control: loadGroupsFormMethods.control,
    name: 'loadGroups'
  });

  const handleOptionSelection = async (option: ActionOptions) => {
    setHomePageState(option);
    if (option !== homePageStateValue) {
      if (option === ActionTypes.Move) {
        loadGroupsFormMethods.reset();
        insert(
          loadGroupsFormMethods.getValues().loadGroups.length,
          moveOptionDefaultFormData
        );
      } else {
        loadGroupsFormMethods.reset();
      }

      spaceFormMethods.reset();
    }

    await Promise.resolve();

    history.push(Paths.LoadGroups);
  };

  return handleOptionSelection;
};
