import React from 'react';
import cn from 'classnames';
import InputField from '../../FormFields/InputField/InputField';
import sm from './styles.module.scss';
import formStyles from '../../styles.module.scss';
import TextAreaField from '../../FormFields/TextAreaField/TextAreaField';
import RadioField from '../../FormFields/RadioField/RadioField';
import useFormData from '../../hooks/useFormData';
import getLoadGroupDataByType from '../../../../utils/getLoadGroupDataByType';
import { LoadType } from '../../../../constants/loadGroupsConstants';
import { UnitsSymbolByName } from '../../../../constants/unitsConstants';

interface SecondLineFormSectionProps {
  groupIndex: number;
}

const SecondLineFormSection: React.FC<SecondLineFormSectionProps> = ({
  groupIndex
}) => {
  const {
    loadTypeValue,
    sizeValue,
    volumeValue,
    volumeSymbol,
    defaultValueConstant,
    loadTypes,
    currentUnits
  } = useFormData({
    groupIndex
  });

  const groupFieldName = `loadGroups.${groupIndex as 0}` as const;
  return (
    <div className={sm.SecondLineFormSection}>
      <div className={sm.SecondLineFormSection_Icon}>
        {getLoadGroupDataByType(loadTypeValue).icon}
      </div>
      <div className={sm.SecondLineFormSection_Row}>
        <div
          className={
            (sm.SecondLineFormSection_Column,
            sm.SecondLineFormSection_DescriptionBlock)
          }
        >
          <div
            className={cn(
              sm.SecondLineFormSection_Row,
              sm.SecondLineFormSection_MarginB
            )}
          >
            <div className={sm.SecondLineFormSection_Column}>
              <div className={formStyles.CreateLoadGroupItem_Label}>
                Load type
              </div>
              <div
                className={cn(
                  sm.SecondLineFormSection_Row,
                  sm.SecondLineFormSection_RadioContainer
                )}
              >
                {loadTypes.map((loadType) => (
                  <div key={loadType}>
                    <RadioField
                      label={getLoadGroupDataByType(loadType).label}
                      defaultValue={`${loadType}`}
                      fieldName={`${groupFieldName}.loadType` as const}
                      isSelected={loadTypeValue === loadType}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div
            className={cn(
              sm.SecondLineFormSection_Row,
              sm.SecondLineFormSection_MarginB
            )}
          >
            <TextAreaField
              defaultValue=""
              type="text"
              fieldName={`${groupFieldName}.description` as const}
              label="Description"
              placeholder="Add some description"
            />
          </div>
        </div>

        <div className={sm.SecondLineFormSection_Content}>
          <div
            className={cn(
              sm.SecondLineFormSection_Column,
              sm.SecondLineFormSection_Width
            )}
          >
            <div
              className={cn(
                sm.SecondLineFormSection_Row,
                sm.SecondLineFormSection_MarginB
              )}
            >
              <div className={sm.SecondLineFormSection_Column}>
                <div className={formStyles.CreateLoadGroupItem_Label}>Size</div>
                <div
                  className={cn(
                    sm.SecondLineFormSection_Row,
                    sm.SecondLineFormSection_RadioContainer
                  )}
                >
                  {getLoadGroupDataByType(loadTypeValue).sizes.map((size) => (
                    <div key={size.value}>
                      <RadioField
                        label={size.label}
                        defaultValue={`${size.value}`}
                        fieldName={`${groupFieldName}.size` as const}
                        isSelected={sizeValue === size.value}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div
              className={cn(
                sm.SecondLineFormSection_DimensionsRow,
                sm.SecondLineFormSection_MarginB
              )}
            >
              {loadTypeValue === LoadType.Drums && (
                <InputField
                  defaultValue={`${defaultValueConstant.diameter}`}
                  fieldName={`${groupFieldName}.diameter` as const}
                  label="Diameter"
                  textAfter={UnitsSymbolByName[currentUnits].lengthS}
                />
              )}
              {loadTypeValue !== LoadType.Drums && (
                <InputField
                  defaultValue={`${defaultValueConstant.length}`}
                  fieldName={`${groupFieldName}.length` as const}
                  label="Length"
                  textAfter={UnitsSymbolByName[currentUnits].lengthS}
                />
              )}
              {loadTypeValue !== LoadType.Drums && (
                <InputField
                  defaultValue={`${defaultValueConstant.width}`}
                  fieldName={`${groupFieldName}.width` as const}
                  label="Width"
                  textAfter={UnitsSymbolByName[currentUnits].lengthS}
                />
              )}
              <InputField
                defaultValue={defaultValueConstant.height.toString()}
                fieldName={`${groupFieldName}.height` as const}
                label="Height"
                textAfter={UnitsSymbolByName[currentUnits].lengthS}
              />
            </div>
          </div>

          <div className={sm.SecondLineFormSection_Column}>
            <div
              className={cn(
                sm.SecondLineFormSection_Row,
                sm.SecondLineFormSection_MarginB
              )}
            >
              <InputField
                defaultValue={`${defaultValueConstant.weight}`}
                fieldName={`${groupFieldName}.weight` as const}
                label="Weight"
                textAfter={UnitsSymbolByName[currentUnits].weight}
              />
            </div>

            <div
              className={cn(
                sm.SecondLineFormSection_Row,
                sm.SecondLineFormSection_MarginB
              )}
            >
              <div>
                <div
                  className={formStyles.CreateLoadGroupItem_Label}
                >{`Volume, ${volumeSymbol}`}</div>
                <div className={formStyles.CreateLoadGroupItem_Value}>
                  {volumeValue}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SecondLineFormSection;
