import React from 'react';
import * as THREE from 'three';
import { TNumberPossition } from '../../types';
import BoxWithEdge from '../BoxWithEdge/BoxWithEdge';

interface IProps {
  sizes: TNumberPossition;
  position: TNumberPossition;
  color: string;
  islastItem: boolean;
}
const Box: React.FC<IProps> = ({ position, sizes, color }) => {
  const calcPositions = [position[0], position[1], position[2]].map(
    (item, idx) => {
      const blockSize = sizes[idx] / 2;
      const res = idx !== 2 ? item + blockSize : item * -1 - blockSize;
      return Number(res);
    }
  );

  const vectorThreeArr = [];
  vectorThreeArr.push(
    new THREE.Vector3(calcPositions[0], calcPositions[1], calcPositions[2])
  );

  return (
    <mesh position={vectorThreeArr[0]}>
      <BoxWithEdge
        sizes={[sizes[0], sizes[1], sizes[2]]}
        edgeColor="#FFFFFF"
        color={color}
      />

      <lineBasicMaterial attach="material" color={color} transparent />
    </mesh>
  );
};

export default Box;
