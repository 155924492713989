import React, { useLayoutEffect, useRef } from 'react';
import { ReactComponent as EmptyIcon } from '../../assets/images/boxes.svg';
import { useGlobalFormMethods } from '../GlobalFormsProvider/GlobalFormsProvider';
import sm from './styles.module.scss';

const EmptyContainers: React.FC = () => {
  const imageRef = useRef<HTMLDivElement | null>(null);
  const { loadGroupsFormMethods } = useGlobalFormMethods();
  const { loadGroups } = loadGroupsFormMethods.getValues();

  useLayoutEffect(() => {
    if (imageRef.current) {
      if (loadGroups.length > 8) {
        imageRef.current.style.height = `400px`;
        imageRef.current.style.width = `600px`;
      } else {
        imageRef.current.style.height = `${
          document.body.getBoundingClientRect().height - 600
        }px`;
        imageRef.current.style.width = `${
          document.body.getBoundingClientRect().height - 600 + 100
        }px`;
      }
    }
  }, [
    document.body.scrollHeight,
    document.body.getBoundingClientRect().height
  ]);

  return (
    <div className={sm.EmptyContainers}>
      <div className={sm.EmptyContainers_Main}>
        <div className={sm.EmptyContainers_Title}>Total calculation</div>
        <div className={sm.EmptyContainers_Subtitle}>
          Calculate to see space usage capacity
        </div>
      </div>
      <div className={sm.EmptyContainers_Image} ref={imageRef}>
        <EmptyIcon />
      </div>
    </div>
  );
};

export default EmptyContainers;
