import React from 'react';
import cn from 'classnames';
import sm from './styles.module.scss';

import { ReactComponent as WhitePart } from '../../assets/images/home/white.svg';
import SpaceCalculatorLabel from '../../components/SpaceCalculatorLabel/SpaceCalculatorLabel';
import { ActionTypes, ScreenSizesOptions } from '../../constants';
import getDataByHomePageSelection from '../../utils/getDataByHomePageSelection';
import { ActionOptions } from '../../types';
import { useHomePageContent } from './hooks/useHomePageContent';
import { useHomePageScreenHeight } from './hooks/useHomePageScreenHeight';

const HomePageContent: React.FC = () => {
  const handleOptionSelection = useHomePageContent();
  const screen = useHomePageScreenHeight();

  return (
    <div className={sm.HomePageContent}>
      <div className={sm.HomePageContent_Main_Top}>
        <div
          className={cn(sm.HomePageContent_Main, {
            [sm.HomePageContent_Main__Small]:
              screen === ScreenSizesOptions.Small,
            [sm.HomePageContent_Main__Big]: screen === ScreenSizesOptions.Big
          })}
        >
          <div
            className={cn(sm.HomePageContent_Title, {
              [sm.HomePageContent_Title__Big]:
                screen === ScreenSizesOptions.Big,
              [sm.HomePageContent_Title__Small]:
                screen === ScreenSizesOptions.Small
            })}
          >
            What would you like to do?
          </div>
          <div className={sm.HomePageContent_Options}>
            {Object.keys(ActionTypes).map((type) => {
              const actionType = type as ActionOptions;
              return (
                <button
                  key={actionType}
                  type="button"
                  className={sm.HomePageContent_Option}
                  onClick={() => handleOptionSelection(actionType)}
                >
                  {getDataByHomePageSelection(actionType).icon}
                  <div className={sm.HomePageContent_OptionText}>
                    {getDataByHomePageSelection(actionType).label}
                  </div>
                </button>
              );
            })}
          </div>
        </div>
        <WhitePart className={sm.HomePageContent_White} />

        <div className={sm.HomePageContent_Red}>
          <SpaceCalculatorLabel />
        </div>
      </div>
    </div>
  );
};

export default HomePageContent;
