import { useFormContext } from 'react-hook-form';
import { LoadGroupFields } from '../../../types';

interface UseUpdateInputQuantityApi {
  handleAdd: (name: `loadGroups.0.quantity` | `loadGroups.0.pallets`) => void;
  handleRemove: (
    name: `loadGroups.0.quantity` | `loadGroups.0.pallets`
  ) => void;
}

const useUpdateInputQuantity = (): UseUpdateInputQuantityApi => {
  const { watch, setValue } = useFormContext<LoadGroupFields>();
  const handleAdd = (
    fieldName: `loadGroups.0.quantity` | `loadGroups.0.pallets`
  ) => {
    const fieldValue = watch(fieldName);
    if (fieldValue >= 999) {
      setValue(fieldName, 999);
    } else {
      setValue(fieldName, fieldValue + 1);
    }
  };

  const handleRemove = (
    fieldName: `loadGroups.0.quantity` | `loadGroups.0.pallets`
  ) => {
    const fieldValue = watch(fieldName);
    if (fieldValue < 1) {
      setValue(fieldName, 0);
    } else {
      setValue(fieldName, fieldValue - 1);
    }
  };

  return { handleAdd, handleRemove };
};

export default useUpdateInputQuantity;
