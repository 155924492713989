import React from 'react';

const BraketsSvg: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="none"
      viewBox="0 0 32 32"
    >
      <path
        fill="#22293E"
        d="M26.667 4h2.666v8h-2.666V6.667h-5.334V4h5.334zM5.333 4h5.334v2.667H5.333V12H2.667V4h2.666zm21.334 21.333V20h2.666v8h-8v-2.667h5.334zm-21.334 0h5.334V28h-8v-8h2.666v5.333z"
      />
    </svg>
  );
};

export default BraketsSvg;
