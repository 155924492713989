import React, { InputHTMLAttributes } from 'react';
import { FieldPath, useFormContext } from 'react-hook-form';
import cn from 'classnames';
import { LoadGroupFields } from '../../../../types';
import Button from '../../../../components/Button/Button';
import { ReactComponent as IconPlus } from '../../../../assets/images/icon-plus.svg';
import { ReactComponent as IconMinus } from '../../../../assets/images/icon-minus.svg';
import inputStyles from '../styles.module.scss';
import localStyles from './styles.module.scss';
import Input from '../../../../components/Input/Input';
import setValueAsInteger from '../../utils/setValueAsInteger';
import useFieldError from '../../../../hooks/useFieldError';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  defaultValue: string;
  label: string;
  fieldName: FieldPath<LoadGroupFields>;
  handleRightControl: () => void;
  handleLeftControl: () => void;
}

export type IputWithControlsProps = Props;

const InputWithControls: React.FC<Props> = ({
  fieldName,
  label,
  defaultValue,
  handleLeftControl,
  handleRightControl,
  ...rest
}) => {
  const { register, watch } = useFormContext();
  const error = useFieldError(fieldName);

  return (
    <div>
      <label htmlFor={fieldName} className={inputStyles.Field_Label}>
        {label}
      </label>
      <div
        className={cn(localStyles.InputWithControls, {
          [localStyles.InputWithControls__Error]: !!error
        })}
      >
        <div
          className={cn(localStyles.InputWithControls_ControlL, {
            [localStyles.InputWithControls_ControlL__Error]: !!error
          })}
        >
          <Button
            variant="control"
            iconPre={<IconMinus />}
            onClick={handleLeftControl}
            iconClassname={localStyles.InputWithControls_Icon}
          />
        </div>
        <div>
          <Input
            {...rest}
            type="number"
            min={0}
            max={999}
            step={1}
            classname={cn(
              inputStyles.Field,
              localStyles.InputWithControls_Input,
              {
                [localStyles.InputWithControls_Input__Error]: !!error
              }
            )}
            defaultValue={defaultValue}
            {...register(fieldName, {
              setValueAs: (value) =>
                setValueAsInteger({ value, fieldName, watch })
            })}
          />
        </div>
        <div
          className={cn(localStyles.InputWithControls_ControlR, {
            [localStyles.InputWithControls_ControlR__Error]: !!error
          })}
        >
          <Button
            variant="control"
            iconPre={<IconPlus />}
            iconClassname={localStyles.InputWithControls_Icon}
            onClick={handleRightControl}
          />
        </div>
      </div>
    </div>
  );
};

export default InputWithControls;
