import { useEffect } from 'react';
import { customLoadTypeDefaultData } from '../../constants/loadGroupsConstants';
import useFetchData from '../../hooks/useFetchData';
import {
  LoadGroupFields,
  LoadGroupsDimension,
  LoadTypeInfo
} from '../../types';
import getLoadGroupDataByType from '../../utils/getLoadGroupDataByType';
import { useGlobalFormMethods } from './GlobalFormsProvider';

export const useFormSideEffects = (): void => {
  const { loadGroupsFormMethods } = useGlobalFormMethods();
  const {
    watch,
    setValue,
    trigger,
    formState: { touchedFields }
  } = loadGroupsFormMethods;
  const { loadGroupsData: predefinedData } = useFetchData();

  const callBack = ({
    formValues,
    fieldName
  }: {
    formValues: LoadGroupFields;
    fieldName: string | undefined;
  }) => {
    const isLoadTypeUpdated =
      fieldName && /loadGroups\.\d\.loadType/.test(fieldName);
    const isSizeUpdated = fieldName && /loadGroups\.\d\.size/.test(fieldName);
    const isUnitsUpdated = fieldName && fieldName === 'measurementSystem';

    if (isLoadTypeUpdated) {
      const index = Number(fieldName?.split('.')[1]);
      const dimensionsFields = [
        `loadGroups.${index}.width` as const,
        `loadGroups.${index}.height` as const,
        `loadGroups.${index}.length` as const,
        `loadGroups.${index}.diameter` as const,
        `loadGroups.${index}.weight` as const
      ];
      const { loadType } = formValues.loadGroups?.[index];
      setValue(
        `loadGroups.${index as 0}.size` as const,
        getLoadGroupDataByType(loadType).sizes[0].value
      );

      trigger(dimensionsFields);
    }

    if (isSizeUpdated) {
      const index = Number(fieldName?.split('.')[1]);
      const { loadGroups, measurementSystem } = formValues;
      const { loadType, size } = loadGroups?.[index];
      const loadTypesData = (predefinedData[measurementSystem][loadType] ||
        customLoadTypeDefaultData) as LoadTypeInfo[];
      const loadTypeSize = loadTypesData.find(
        ({ name: sizeName }) => sizeName === size
      );
      const dimensionsFields = [
        `loadGroups.${index}.width` as const,
        `loadGroups.${index}.height` as const,
        `loadGroups.${index}.length` as const,
        `loadGroups.${index}.diameter` as const,
        `loadGroups.${index}.weight` as const
      ];

      const setDimensionValue = (
        dimension: keyof LoadGroupsDimension,
        defaultValue: number | undefined
      ) => {
        const isTouched = touchedFields.loadGroups?.[index]?.[dimension];
        if (!isTouched) {
          setValue(
            `loadGroups.${index as 0}.${dimension}` as const,
            loadTypeSize?.[dimension] ?? defaultValue
          );
        }
      };

      setDimensionValue('weight', 0);
      setDimensionValue('height', 0);
      setDimensionValue('width', undefined);
      setDimensionValue('length', undefined);
      setDimensionValue('diameter', undefined);

      trigger(dimensionsFields);
    }

    if (isUnitsUpdated) {
      const { loadGroups, measurementSystem } = formValues;

      loadGroups.forEach(({ loadType, size }, index) => {
        if (loadType === 'custom') {
          return;
        }
        const loadTypesData = (predefinedData[measurementSystem][loadType] ||
          customLoadTypeDefaultData) as LoadTypeInfo[];
        const loadTypeSize = loadTypesData.find(
          ({ name: sizeName }) => sizeName === size
        );

        const setDimensionValue = (
          dimension: keyof LoadGroupsDimension,
          defaultValue: number | undefined
        ) => {
          const isTouched = touchedFields.loadGroups?.[index]?.[dimension];
          if (!isTouched) {
            setValue(
              `loadGroups.${index as 0}.${dimension}` as const,
              loadTypeSize?.[dimension] ?? defaultValue
            );
          }
        };

        setDimensionValue('weight', 0);
        setDimensionValue('height', 0);
        setDimensionValue('width', undefined);
        setDimensionValue('length', undefined);
        setDimensionValue('diameter', undefined);
      });
    }
  };

  useEffect(() => {
    const subscription = watch((formValues, { name }) =>
      callBack({ formValues, fieldName: name })
    );

    return () => subscription.unsubscribe();
  }, []);
};
