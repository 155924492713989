import React from 'react';
import Toast from '../Toast/Toast';

import sm from './styles.module.scss';

const Error500Toast: React.FC = () => {
  return (
    <Toast
      title="Something went wrong"
      subtitle="Please try again later"
      positionClassname={sm.Error500Toast}
    />
  );
};

export default Error500Toast;
