import * as yup from 'yup';
import { AnyObjectSchema } from 'yup';
import { masValMessStr } from '../state/data-static';

const spaceValidationSchema = (maxValue: number): AnyObjectSchema => {
  return yup.object().shape({
    space: yup.object().shape({
      name: yup.string().required('This field is required'),
      height: yup
        .number()
        .positive()
        .max(maxValue, `${masValMessStr} ${maxValue}`)
        .required('This field is required')
        .test(
          'maxDigitsAfterDecimal',
          'number field must have 2 digits after decimal or less',
          (value) =>
            value ? /^\d+(\.\d{1,2})?$/.test(value?.toString()) : false
        ),
      width: yup
        .number()
        .positive()
        .max(maxValue, `${masValMessStr} ${maxValue}`)
        .required('This field is required')
        .test(
          'maxDigitsAfterDecimal',
          'number field must have 2 digits after decimal or less',
          (value) =>
            value ? /^\d+(\.\d{1,2})?$/.test(value?.toString()) : false
        ),
      length: yup
        .number()
        .positive()
        .max(maxValue, `${masValMessStr} ${maxValue}`)
        .required('This field is required')
        .test(
          'maxDigitsAfterDecimal',
          'number field must have 2 digits after decimal or less',
          (value) =>
            value ? /^\d+(\.\d{1,2})?$/.test(value?.toString()) : false
        ),
      maxWeight: yup
        .number()
        .positive()
        .lessThan(100000)
        .required('This field is required')
        .test(
          'maxDigitsAfterDecimal',
          'number field must have 2 digits after decimal or less',
          (value) =>
            value ? /^\d+(\.\d{1,2})?$/.test(value?.toString()) : false
        )
    })
  });
};

export default spaceValidationSchema;
