import React from 'react';
import BenefitsCalculatorList from '../BenefitsCalculatorList/BenefitsCalculatorList';
import { ReactComponent as LibraImg } from '../../assets/images/landing/libra_img.svg';
import sm from './styles.module.scss';
import BluePlateWithLink from '../../components/BluePlateWithLink/BluePlateWithLink';
import { customLogisticLink } from '../HomePageLanding/data';

const BenefitsCalculatorLanding: React.FC = () => {
  return (
    <div className={sm.BenefitsCalc}>
      <div className={sm.BenefitsCalc_wrapper}>
        <div className={sm.BenefitsCalc_imageSide}>
          <LibraImg />
        </div>
        <BenefitsCalculatorList />
      </div>
      <BluePlateWithLink
        text="See what else we can do for the supply chain industry"
        linkTxt="Learn more"
        linkPath={customLogisticLink}
      />
    </div>
  );
};

export default BenefitsCalculatorLanding;
