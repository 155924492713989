import React, { MutableRefObject } from 'react';
import { useClickOutside } from '../../hooks/useClickOutside';
import Button from '../Button/Button';

import sm from './styles.module.scss';

interface DeletePopupProps {
  onClick: () => void;
  handlePopupClose: () => void;
}

const DeletePopup: React.FC<DeletePopupProps> = ({
  handlePopupClose,
  onClick
}) => {
  const buttonRef = useClickOutside({
    onOutsideClick: handlePopupClose
  });

  return (
    <Button
      ref={buttonRef as MutableRefObject<HTMLButtonElement>}
      title="Delete group"
      variant="simple"
      classname={sm.DeletePopup}
      onClick={onClick}
    />
  );
};

export default DeletePopup;
