import React, { ReactElement } from 'react';
import { LoadGroup } from '../types';

import { ReactComponent as BoxIcon } from '../assets/images/box.svg';
import { ReactComponent as DrumIcon } from '../assets/images/drum.svg';
import { ReactComponent as TotesIcon } from '../assets/images/totes.svg';
import { ReactComponent as CustomIcon } from '../assets/images/custom.svg';
import { LoadType } from '../constants/loadGroupsConstants';

interface LoadGroupDataByTypeApi {
  icon: ReactElement;
  sizes: {
    value: string;
    label: string;
  }[];
  label: string;
}

const getLoadGroupDataByType = (
  load: LoadGroup['loadType']
): LoadGroupDataByTypeApi => {
  const byLoadType = {
    [LoadType.Boxes]: {
      icon: <BoxIcon />,
      label: 'Box',
      sizes: [
        { value: 'Small', label: 'Small' },
        { value: 'Medium', label: 'Medium' },
        { value: 'Large', label: 'Large' }
      ]
    },
    [LoadType.Drums]: {
      icon: <DrumIcon />,
      label: 'Drum',
      sizes: [{ value: 'Medium', label: 'Medium' }]
    },
    [LoadType.Totes]: {
      icon: <TotesIcon />,
      label: 'Totes',
      sizes: [
        { value: 'Large', label: 'Large' },
        { value: 'XLarge', label: 'XLarge' }
      ]
    },
    [LoadType.Custom]: {
      icon: <CustomIcon />,
      label: 'Custom',
      sizes: [{ value: 'Custom', label: 'Custom' }]
    }
  };

  return byLoadType[load];
};

export default getLoadGroupDataByType;
