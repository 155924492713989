import React from 'react';
import { PositionItem } from '../../types';
import Box from '../Box/Box';
import Cilinder from '../Cilinder/Cilinder';

type TProps = {
  positions: PositionItem[];
};
const BoxesWrapper: React.FC<TProps> = ({ positions }) => {
  return (
    <>
      {positions.map((item, idx) => {
        const isCilinder = item.type === 'drums';
        const key = `${item.x}-${item.y}-${item.z}`;
        return (
          <React.Fragment key={`${key}`}>
            {isCilinder ? (
              <Cilinder
                position={[item.x, item.z, item.y]}
                sizes={{
                  width: item.w,
                  height: item.h,
                  length: item.l,
                  diametr: item.d
                }}
                color={item.color}
                islastItem={positions.length - 1 === idx}
              />
            ) : (
              <Box
                position={[item.x, item.z, item.y]}
                sizes={[item.w, item.h, item.l]}
                color={item.color}
                islastItem={positions.length - 1 === idx}
              />
            )}
          </React.Fragment>
        );
      })}
    </>
  );
};

export default BoxesWrapper;
