import {
  DeepMap,
  FieldError,
  FieldValues,
  useFormContext
} from 'react-hook-form';

const useFieldError = (
  fieldName: string
): DeepMap<FieldValues, FieldError> | undefined => {
  const { formState } = useFormContext();

  const error = fieldName.split('.').reduce((result, keyFragment) => {
    return result?.[keyFragment] ?? undefined;
  }, formState.errors);

  if (Array.isArray(error)) {
    return undefined;
  }

  return error;
};

export default useFieldError;
