import { CalculateRequestEntry, Container, LoadGroup, Units } from '../types';

interface Props {
  loadGroupsData: LoadGroup[];
  spaceData: Container;
  units: Units;
  colors: string[];
}

export const formsDataToRequestEntry = ({
  loadGroupsData,
  spaceData,
  units,
  colors
}: Props): CalculateRequestEntry => {
  const itemGroups = loadGroupsData.map(
    (
      {
        name,
        description,
        length,
        diameter,
        height,
        width,
        weight,
        quantity,
        pallets,
        loadType
      },
      index
    ) => {
      if (loadType === 'drums') {
        return {
          name,
          description,
          type: loadType,
          item: {
            height,
            weight,
            diameter
          },
          quantity,
          palletsQuantity: pallets,
          color: colors[index]
        };
      }
      return {
        name,
        description,
        type: loadType,
        item: {
          height,
          length,
          width,
          weight
        },
        quantity,
        palletsQuantity: pallets,
        color: colors[index]
      };
    }
  );

  return {
    container: {
      ...spaceData
    },
    itemGroups,
    measurementSystem: units
  };
};
