import React from 'react';
import cn from 'classnames';
import sm from './styles.module.scss';

interface ToastProps {
  title: string;
  subtitle: string;
  positionClassname: string;
  children?: React.ReactNode;
}

const Toast: React.FC<ToastProps> = ({
  title,
  subtitle,
  positionClassname,
  children
}) => {
  return (
    <div className={cn(sm.Toast, positionClassname)}>
      <div className={sm.Toast_Line} />
      {children && children}
      <div className={sm.Toast_Content}>
        <div className={sm.Toast_Title}>{title}</div>
        <div className={sm.Toast_Subtitle}>{subtitle}</div>
      </div>
    </div>
  );
};

export default Toast;
