import React from 'react';
import { TLangingPageTopDescrCardListData } from '../../types';
import sm from './styles.module.scss';

type TProps = {
  item: TLangingPageTopDescrCardListData;
};

const LangingPageTopDescrCardItem: React.FC<TProps> = ({ item }) => {
  const { text, Icon } = item;
  return (
    <li className={sm.CardItem}>
      <div className={sm.CardItem_iconWrapper}>
        <Icon />
      </div>
      <span className={sm.CardItem_text}>{text}</span>
    </li>
  );
};

export default LangingPageTopDescrCardItem;
