import React from 'react';
import { useRecoilValue } from 'recoil';
import {
  getContainers,
  getContainersQuantity,
  getNotPlacedItems,
  getNotPlacedItemsNames
} from '../../state/calculationsState';
import ContainersListItem from '../ContainersListItem/ContainersListItem';
import sm from './styles.module.scss';
import { ReactComponent as Icon } from '../../assets/images/warning.svg';

const ContainersList: React.FC = () => {
  const containersLength = useRecoilValue(getContainersQuantity);
  const containers = useRecoilValue(getContainers);
  const notPlacedItems = useRecoilValue(getNotPlacedItems);
  const notPlacedItemsNames = useRecoilValue(getNotPlacedItemsNames);
  const notPlacedItemsNamesLabel = containersLength
    ? notPlacedItemsNames.join(', ')
    : 'all load groups';

  return (
    <div className={sm.ContainersList}>
      {notPlacedItems && (
        <div className={sm.ContainersList_Error}>
          <Icon />
          <div className={sm.ContainersList_ErrorText}>
            Load dimensions of {notPlacedItemsNamesLabel} are bigger than
            selected space size. You can edit groups to change them.
          </div>
        </div>
      )}
      {containersLength > 0 ? (
        <div className={sm.ContainersList_List}>
          {containers.map(({ container }, index) => {
            return (
              <ContainersListItem
                key={`${index + 1}${container.name}`}
                index={index}
                container={containers[0]}
                totalSumQuantity={containers[index].totalQuantity}
              />
            );
          })}
        </div>
      ) : null}
    </div>
  );
};

export default ContainersList;
