import React from 'react';
import numberDisplay from '../../utils/displayNumber';
import sm from './styles.module.scss';

interface TotalProps {
  label: string;
  value: number;
  units: string;
}

const Total: React.FC<TotalProps> = ({ label, value, units }) => {
  return (
    <>
      <div className={sm.Total_Label}>{label}</div>
      <div className={sm.Total_Content}>
        <span className={sm.Total_Value}>{numberDisplay({ num: value })}</span>
        <span className={sm.Total_Units}>{units}</span>
      </div>
    </>
  );
};

export default Total;
