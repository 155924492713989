import React from 'react';
import { Text } from '@react-three/drei';
import { ILineText } from '../../types';

const LineText: React.FC<ILineText> = ({
  lineTextPosition,
  lineTextRotation,
  lineText,
  findMaxOfBiggesCoeff
}) => {
  const fontScaleCoeff = findMaxOfBiggesCoeff * 2.5;

  return (
    <mesh position={lineTextPosition} rotation={lineTextRotation}>
      <Text
        scale={[fontScaleCoeff, fontScaleCoeff, fontScaleCoeff]}
        color="black"
        anchorX="center"
        anchorY="middle"
      >
        {lineText}
      </Text>
    </mesh>
  );
};

export default LineText;
