import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import cn from 'classnames';
import { useRecoilState } from 'recoil';
import Button from '../../components/Button/Button';
import Loader from '../../components/Loader';
import { FeedbackFormFields } from '../../types';
import RadioField from './FormFields/RadioField/RadioField';

import sm from './styles.module.scss';
import useFeedbackForm from './hooks/useFeedbackForm';
import { modalOpenState } from '../../state/modalState';
import { resultsSentState } from '../../state/calculationResultsState';
import {
  feedbackFormData,
  feedbackOptions
} from '../../constants/feedbackConstants';
import Error500Toast from '../../components/Error500Toast/Error500Toast';

interface FeedbackFormProps {
  formMethods: UseFormReturn<FeedbackFormFields>;
}

const FeedbackForm: React.FC<FeedbackFormProps> = ({ formMethods }) => {
  const {
    handleSubmit,
    onSubmit,
    isLoading,
    messageValue,
    isError
  } = useFeedbackForm(formMethods);
  const [, setSentResults] = useRecoilState(resultsSentState);
  const [, setModalOpen] = useRecoilState(modalOpenState);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className={sm.FeedbackForm}>
        {isLoading ? (
          <div className={sm.FeedbackForm_Loader}>
            <Loader />
          </div>
        ) : (
          <div>
            <div className={sm.FeedbackForm_Text}>
              Please give your feedback
            </div>

            {Object.keys(feedbackOptions).map((option, index) => (
              <div
                className={sm.FeedbackForm_Field}
                key={feedbackFormData[index].value}
              >
                <RadioField
                  label={feedbackFormData[index].value}
                  defaultValue={feedbackFormData[index].value}
                  emoji={feedbackFormData[index].icon}
                  fieldName="message"
                  isSelected={messageValue === feedbackFormData[index].value}
                />
              </div>
            ))}

            <input
              type="submit"
              value="Rate"
              className={cn(
                sm.FeedbackForm_Button,
                sm.FeedbackForm_Button__Primary
              )}
            />
            <Button
              variant="linkLike"
              title="Skip"
              classname={cn(
                sm.FeedbackForm_Button,
                sm.FeedbackForm_Button__Link
              )}
              onClick={() => {
                setModalOpen(false);
                setSentResults(false);
              }}
            />
          </div>
        )}
      </form>
      {isError && <Error500Toast />}
    </>
  );
};

export default FeedbackForm;
