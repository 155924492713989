export const ActionTypes = {
  Ship: 'Ship',
  Move: 'Move',
  Transfer: 'Transfer'
} as const;

export const ScreenSizesOptions = {
  Small: 'small',
  Big: 'big',
  Default: 'default'
} as const;

export const contactInfoDefault = { email: '', name: '' };
