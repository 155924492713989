const numberDisplay = ({
  num,
  decimalCount = 2,
  divider = ','
}: {
  num: number;
  decimalCount?: number;
  divider?: string;
}): string => {
  if (num === 0) {
    return num.toString();
  }

  const arrWithDecimals = sliceDecimalNumbers(num, decimalCount).split('.');

  const modifiedArr = arrWithDecimals[0].toString().split('').reverse();
  let arrLength = modifiedArr.length;
  for (let i = 3; i < arrLength; i += 3) {
    if (arrWithDecimals[0].length <= 3) {
      modifiedArr.splice(i, 0, '');
    } else {
      modifiedArr.splice(i, 0, divider);
    }
    i += 1;
    arrLength += 1;
  }

  const result = `${modifiedArr.reverse().join('')}.${arrWithDecimals[1]}`;

  return result;
};

export default numberDisplay;

export const sliceDecimalNumbers = (
  num: number,
  decimalCount: number
): string => {
  const numberWithDecimalsArray = num.toString().split('.');
  const slicedDecimalPart = numberWithDecimalsArray[1]?.slice(0, decimalCount);

  const decimals = pad(slicedDecimalPart, decimalCount);

  const result = [numberWithDecimalsArray[0], decimals].join('.');

  return result;
};

function pad(subString: string, length: number) {
  let str = subString;

  if (!subString) {
    str = new Array(length + 1).join(`0`);
  }

  while (str?.length < length) {
    str = `${str}0`;
  }

  return str;
}
