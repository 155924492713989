import { UseFormReturn, UseFormHandleSubmit } from 'react-hook-form';
import { useRecoilState } from 'recoil';
import { useFeedbackMutation } from '../../../hooks/useFeedbackMutation';
import { contactInfoState } from '../../../state/contactInfoState';

import { FeedbackFormFields } from '../../../types';

interface UseFeedbackFormApi {
  onSubmit: (data: FeedbackFormFields) => void;
  handleSubmit: UseFormHandleSubmit<FeedbackFormFields>;
  isLoading: boolean;
  isError: boolean;
  messageValue: string;
}

const useFeedbackForm = (
  methods: UseFormReturn<FeedbackFormFields>
): UseFeedbackFormApi => {
  const { handleSubmit, watch } = methods;
  const { mutate, isLoading, isError } = useFeedbackMutation();
  const [contactInfo] = useRecoilState(contactInfoState);
  const messageValue = watch('message');

  const onSubmit = async (data: FeedbackFormFields) => {
    mutate({
      ...data,
      ...contactInfo
    });
  };

  return {
    handleSubmit,
    onSubmit,
    isLoading,
    messageValue,
    isError
  };
};

export default useFeedbackForm;
