import { useState } from 'react';

interface UsePoliciesApi {
  handleSetPolicies: () => void;
  allowPolicies: boolean;
}

export const usePolicies = (): UsePoliciesApi => {
  const [allowPolicies, setAllowPolicies] = useState(
    Boolean(localStorage.getItem('allowPolicies'))
  );

  const handleSetPolicies = () => {
    localStorage.setItem('allowPolicies', 'true');

    setAllowPolicies(Boolean(localStorage.getItem('allowPolicies')));
  };

  return {
    allowPolicies,
    handleSetPolicies
  };
};
