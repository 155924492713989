import React from 'react';
import { useRecoilState } from 'recoil';
import sm from './styles.module.scss';
import { unitsState } from '../../state/unitsState';
import useFetchData from '../../hooks/useFetchData';
import LoadGroupItem from '../../components/LoadGroupItem/LoadGroupItem';
import TitleSection from './TitleSection/TitleSection';
import { useGlobalFormMethods } from '../GlobalFormsProvider/GlobalFormsProvider';
import useCreateLoad from '../CreateLoadFormContainer/useCreateLoad';
import { getTotalVolumeCalculation } from '../CreateLoadGroupItem/utils/getTotalVolumeCalculation';
import { getTotalWeightCalculation } from '../CreateLoadGroupItem/utils/getTotalWeightCalculation';
import { UnitsSymbolByName } from '../../constants/unitsConstants';

const LoadGroupsList: React.FC = () => {
  const [currentUnits] = useRecoilState(unitsState);
  const { colorsList, palletsData } = useFetchData();
  const { loadGroupsFormMethods } = useGlobalFormMethods();
  const { getValues, watch } = loadGroupsFormMethods;
  const { loadGroups } = getValues();
  const { totalLoadGroupsVolume, totalLoadGroupsWeight } = useCreateLoad();

  return (
    <div className={sm.LoadGroupsList}>
      <div className={sm.LoadGroupsList_Head}>
        <TitleSection
          totalVolume={totalLoadGroupsVolume}
          totalWeight={totalLoadGroupsWeight}
        />
      </div>

      <div className={sm.LoadGroupsList_Main}>
        <div className={sm.LoadGroupsList_Table}>
          <div>Group name</div>
          <div>{`Weight, ${UnitsSymbolByName[currentUnits].weight}`}</div>
          <div>{`Volume, ${UnitsSymbolByName[currentUnits].volume}`}</div>
        </div>

        {loadGroups.map((group, index) => {
          const { name } = group;
          const { totalVolume } = getTotalVolumeCalculation({
            groupFieldName: `loadGroups.${index}` as const,
            watch,
            currentUnits,
            palletsData
          });
          const totalWeight = getTotalWeightCalculation({
            groupFieldName: `loadGroups.${index}` as const,
            watch,
            palletsData
          });
          return (
            <div key={`${colorsList[index]}`} className={sm.Row}>
              <LoadGroupItem
                label={name}
                color={colorsList[index]}
                weight={totalWeight}
                volume={totalVolume}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default LoadGroupsList;
