import { atom, selector, selectorFamily } from 'recoil';
import { UnitsValues } from '../constants/unitsConstants';
import {
  CalculcationResult,
  ColorBar,
  Limit,
  Placement,
  PlacementItem,
  Spaces
} from '../types';

export const calculationsLoadingState = atom<boolean>({
  key: 'loading',
  default: false
});

export const calculationsErrorState = atom<boolean>({
  key: 'calculationsError',
  default: false
});

export const calculationsState = atom<CalculcationResult>({
  key: 'calculations',
  default: {
    combinedVolume: 0,
    combinedWeight: 0,
    measurementSystem: UnitsValues.imperial,
    notPlacedItems: null,
    placements: []
  }
});

export const getNotPlacedItems = selector<PlacementItem[] | null>({
  key: 'notPlacedItems',
  get: ({ get }) => get(calculationsState).notPlacedItems
});

export const getNotPlacedItemsNames = selector<string[]>({
  key: 'notPlacedItemsNames',
  get: ({ get }) => get(getNotPlacedItems)?.map(({ name }) => name) || []
});

export const getContainers = selector<Placement[]>({
  key: 'containers',
  get: ({ get }) => get(calculationsState).placements
});

export const getContainersQuantity = selector<number>({
  key: 'containersQuantity',
  get: ({ get }) => get(getContainers)?.length || 0
});

export const getContainerType = selector<Spaces>({
  key: 'containerType',
  get: ({ get }) => get(getContainers)?.[0]?.container.name
});

export const getContainerSpaceMinLimit = selectorFamily<Limit['min'], number>({
  key: 'containerSpaceMinLimit',
  get: (placementIndex) => ({ get }): Limit['min'] => {
    return get(getContainers)[placementIndex]?.container.spaceLimit.min;
  }
});

export const getContainerWeightMinLimit = selectorFamily<Limit['min'], number>({
  key: 'containerWeightMinLimit',
  get: (placementIndex) => ({ get }): Limit['min'] => {
    return get(getContainers)[placementIndex]?.container.weightLimit.min;
  }
});

export const getContainerItemsInfo = selectorFamily<PlacementItem[], number>({
  key: 'containerItemsInfo',
  get: (placementIndex) => ({ get }): PlacementItem[] => {
    return get(getContainers)[placementIndex]?.items.map((item) => {
      return {
        ...item,
        groupVolumePercent: item.groupVolumePercent,
        groupWeightPercent: item.groupWeightPercent
      };
    });
  }
});

export const getContainerWeightColorsInfo = selectorFamily<ColorBar[], number>({
  key: 'containerWeightColorsInfo',
  get: (placementIndex) => ({ get }): ColorBar[] =>
    get(getContainers)[placementIndex]?.items.map(
      ({ color, groupWeightPercent, name }) => {
        return {
          color,
          percent: `${groupWeightPercent.toString()}%`,
          name
        };
      }
    )
});

export const getContainerVolumeColorsInfo = selectorFamily<ColorBar[], number>({
  key: 'containerVolumeColorsInfo',
  get: (placementIndex) => ({ get }): ColorBar[] => {
    return get(getContainers)[placementIndex]?.items.map(
      ({ color, groupVolumePercent, name }) => {
        return {
          color,
          percent: `${groupVolumePercent.toString()}%`,
          name
        };
      }
    );
  }
});

export const getTotalVolumePercent = selectorFamily<number, number>({
  key: 'containerTotalVolume',
  get: (placementIndex) => ({ get }): number => {
    return get(getContainers)[placementIndex]?.totalVolumePercent;
  }
});

export const getTotalWeightPercent = selectorFamily<number, number>({
  key: 'containerTotalWeight',
  get: (placementIndex) => ({ get }): number => {
    return get(getContainers)[placementIndex]?.totalWeightPercent;
  }
});
