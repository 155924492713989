import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { Paths } from '../constants/paths';
import { homePageState } from '../state/homePageState';

export const useRouteGuard = (): void => {
  const [homePageStateValue] = useRecoilState(homePageState);
  const history = useHistory();
  window.onbeforeunload = (event: BeforeUnloadEvent) => {
    const e = event || window.event;
    e.preventDefault();
    if (e) {
      e.returnValue = '';
    }
    return '';
  };

  useEffect(() => {
    if (!homePageStateValue) {
      history.replace(Paths.Home);
    }
  }, [homePageStateValue]);
};
