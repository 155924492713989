import { useFormContext } from 'react-hook-form';
import { useRecoilState } from 'recoil';
import { UnitsSymbolByName } from '../../../constants/unitsConstants';
import { unitsState } from '../../../state/unitsState';
import { LoadGroupFields } from '../../../types';
import useCalculatedPallets from './useCalculatedPallets';

interface UseUpdateTotalWeightProps {
  groupIndex: number;
}

interface UseUpdateWeightApi {
  weightSymbol: string;
  totalWeight: number;
}

const useUpdateTotalWeight = ({
  groupIndex
}: UseUpdateTotalWeightProps): UseUpdateWeightApi => {
  const { watch } = useFormContext<LoadGroupFields>();
  const [currentUnits] = useRecoilState(unitsState);
  const groupFieldName = `loadGroups.${groupIndex as 0}` as const;

  const quantityField = `${groupFieldName}.quantity` as const;
  const weightField = `${groupFieldName}.weight` as const;

  const quantityValue = watch(quantityField);
  const weightValue = watch(weightField);

  const { palletsWeight } = useCalculatedPallets({
    groupIndex
  });

  const totalWeight = weightValue * quantityValue + palletsWeight;

  const weightSymbol = UnitsSymbolByName[currentUnits].weight;

  return {
    weightSymbol,
    totalWeight
  };
};

export default useUpdateTotalWeight;
