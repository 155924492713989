import React from 'react';
import Button from '../Button/Button';
import { ReactComponent as ButtonIcon } from '../../assets/images/go-up.svg';

import sm from './styles.module.scss';

const FloatingButton: React.FC = () => {
  return (
    <div className={sm.FloatingButton_Container}>
      <Button
        iconPre={<ButtonIcon />}
        onClick={() => window.scrollTo(0, 0)}
        classname={sm.FloatingButton}
      />
    </div>
  );
};

export default FloatingButton;
