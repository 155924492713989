import React from 'react';
import sm from './styles.module.scss';

import { ReactComponent as TextSvg } from '../../assets/images/500text.svg';
import { ReactComponent as ImageCat } from '../../assets/images/boxWithCat.svg';
import { ReactComponent as ImageBox } from '../../assets/images/box.svg';

const Error500: React.FC = () => {
  return (
    <div className={sm.Error500}>
      <ImageBox className={sm.Error500_ImageBoxS} />
      <ImageBox className={sm.Error500_ImageBoxM} />
      <ImageBox className={sm.Error500_ImageBoxL} />

      <TextSvg />

      <div className={sm.Error500_Text}>Internal Server Error</div>

      <ImageCat className={sm.Error500_ImageCat} />
    </div>
  );
};

export default Error500;
