import React, { InputHTMLAttributes } from 'react';
import { FieldPath, useFormContext } from 'react-hook-form';
import cn from 'classnames';
import sm from './styles.module.scss';
import Input from '../../../components/Input/Input';
import { SendResultsFormFields } from '../../../types';
import useFieldError from '../../../hooks/useFieldError';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  fieldName: FieldPath<SendResultsFormFields>;
  label: string;
  defaultValue: string;
}

export type NameFieldProps = Props;

const InputField: React.FC<Props> = ({
  fieldName,
  label,
  defaultValue,
  ...rest
}) => {
  const { register } = useFormContext();
  const error = useFieldError(fieldName);
  return (
    <div>
      <label htmlFor={fieldName} className={sm.InputField_Label}>
        {label}
      </label>
      <div className={sm.InputField}>
        <Input
          {...rest}
          type="text"
          classname={cn(sm.InputField_Input, {
            [sm.InputField_Input__Error]: !!error
          })}
          defaultValue={defaultValue}
          {...register(fieldName)}
        />
        <div
          className={cn(sm.InputField_InputErrorText__Hidden, {
            [sm.InputField_InputErrorText__Visible]: !!error
          })}
        >
          {error?.message}
        </div>
      </div>
    </div>
  );
};

export default InputField;
