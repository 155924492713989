import React, { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { useLocation } from 'react-router-dom';

import { Helmet, HelmetProvider } from 'react-helmet-async';
import cn from 'classnames';
import useFetchData from '../hooks/useFetchData';
import sm from './styles.module.scss';
import HomePageContent from '../containers/HomePageContent/HomePageContent';
import { useRouteGuard } from '../hooks/useRouteGuard';
import Loader from '../components/Loader';
import HomePageLanding from '../containers/HomePageLanding/HomePageLanding';

import { scrollState } from '../state/scrollState';

const HomePage: React.FC = () => {
  const { isLoading } = useFetchData();
  const [, setIsScroll] = useRecoilState(scrollState);
  const location = useLocation();

  useRouteGuard();
  useEffect(() => {
    setIsScroll(false);
    if (window !== undefined) {
      const { scrollRestoration } = window.history;
      if (scrollRestoration === 'auto') {
        window.history.scrollRestoration = 'manual';
      }
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <HelmetProvider>
      <div className={cn(sm.Page, sm.Page_Home)}>
        <Helmet>
          <title>
            Load Space Calculator for Trucks, Containers, and Warehouses
          </title>
          <meta
            name="description"
            content="Optimize storage space in your warehouses and space usage in vehicles and containers to ensure efficient cargo shipping and reduce delivery costs."
          />
        </Helmet>
        {isLoading ? (
          <div className={sm.Page_Loader}>
            <Loader />
          </div>
        ) : (
          <>
            <HomePageContent />
            <HomePageLanding />
          </>
        )}
      </div>
    </HelmetProvider>
  );
};

export default HomePage;
