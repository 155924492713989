import { useFormContext } from 'react-hook-form';
import { useRecoilState } from 'recoil';
import { customLoadTypeDefaultData } from '../../../constants/loadGroupsConstants';
import useFetchData from '../../../hooks/useFetchData';
import { unitsState } from '../../../state/unitsState';

import {
  LoadGroupFields,
  LoadTypeInfo,
  LoadTypeValue,
  Units
} from '../../../types';
import numberDisplay from '../../../utils/displayNumber';
import useUpdateInputQuantity from './useUpdateInputQuantity';
import useUpdateTotalWeight from './useUpdateTotalWeight';
import useUpdateVolumeValue from './useUpdateVolumeValue';

export interface UseFormDataProps {
  groupIndex: number;
}

export interface UseFormDataApi {
  volumeValue: string;
  totalVolume: string;
  volumeSymbol: string;
  totalWeight: string;
  weightSymbol: string;
  loadTypeValue: LoadTypeValue;
  sizeValue: string;
  loadTypes: LoadTypeValue[];
  defaultValueConstant: LoadTypeInfo;
  currentUnits: Units;
  handleRemove: (
    name: `loadGroups.0.quantity` | `loadGroups.0.pallets`
  ) => void;
  handleAdd: (name: `loadGroups.0.quantity` | `loadGroups.0.pallets`) => void;
}

const useFormData = ({ groupIndex }: UseFormDataProps): UseFormDataApi => {
  const { watch } = useFormContext<LoadGroupFields>();
  const { loadTypes, loadGroupsData: predefinedData } = useFetchData();
  const [currentUnits] = useRecoilState(unitsState);

  const loadGroupTypeField = `loadGroups.${groupIndex as 0}.loadType` as const;
  const loadGroupSizeField = `loadGroups.${groupIndex as 0}.size` as const;

  const loadTypeValue = watch(loadGroupTypeField);
  const sizeValue = watch(loadGroupSizeField);

  const loadTypesData = (predefinedData[currentUnits][loadTypeValue] ||
    customLoadTypeDefaultData) as LoadTypeInfo[];

  const { volumeValue, volumeSymbol, totalVolume } = useUpdateVolumeValue({
    groupIndex
  });

  const { totalWeight, weightSymbol } = useUpdateTotalWeight({
    groupIndex
  });

  const { handleRemove, handleAdd } = useUpdateInputQuantity();

  return {
    volumeValue: numberDisplay({
      num: volumeValue
    }),
    volumeSymbol,
    loadTypeValue,
    sizeValue,
    loadTypes,
    handleRemove,
    totalWeight: numberDisplay({
      num: totalWeight
    }),
    weightSymbol,
    handleAdd,
    defaultValueConstant: loadTypesData[0],
    currentUnits,
    totalVolume: numberDisplay({
      num: totalVolume
    })
  };
};

export default useFormData;
