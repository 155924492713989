import React from 'react';
import { useRecoilState } from 'recoil';
import CreateLoadBar from '../CreateLoadBar/CreateLoadBar';
import CreateLoadGroupList from '../CreateLoadGroupList/CreateLoadGroupList';
import useCreateLoad from './useCreateLoad';
import sm from './styles.module.scss';
import AddGroupButton from '../AddGroupButton/AddGroupButton';
import useFetchData from '../../hooks/useFetchData';
import Loader from '../../components/Loader';
import BackButton from '../../components/BackButton/BackButton';
import LoadGroupsInfoMessage from '../../components/InfoMessage/InfoMessage';
import FloatingButton from '../../components/FloatingButton/FloatingButton';
import { scrollState } from '../../state/scrollState';

const CreateLoadFormContainer: React.FC = () => {
  const {
    handleSubmit,
    onSubmit,
    totalLoadGroupsVolume,
    totalLoadGroupsWeight,
    groupsQuantityErrorMessage,
    loadGroups
  } = useCreateLoad();
  const { isLoading } = useFetchData();
  const [isScroll] = useRecoilState(scrollState);

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={sm.CreateLoadFormContainer_Content}
    >
      <BackButton />
      {isScroll && <FloatingButton />}
      <CreateLoadBar
        totalVolume={totalLoadGroupsVolume}
        totalWeight={totalLoadGroupsWeight}
      />
      <div className={sm.CreateLoadFormContainer_MainContent}>
        <div className={sm.CreateLoadFormContainer_Button}>
          <AddGroupButton isDisabled={loadGroups.length === 10 || isLoading} />
        </div>

        {loadGroups.length >= 8 && (
          <LoadGroupsInfoMessage title={groupsQuantityErrorMessage} />
        )}

        {isLoading ? (
          <div className={sm.CreateLoadFormContainer_Loader}>
            <Loader />
          </div>
        ) : (
          <CreateLoadGroupList />
        )}
      </div>
    </form>
  );
};

export default CreateLoadFormContainer;
