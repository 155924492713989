export enum UnitsValues {
  metric = 'metric',
  imperial = 'imperial'
}

export const UnitsSymbolByName = {
  metric: { volume: 'm³', weight: 'kg', lengthS: 'cm', lengthM: 'm' },
  imperial: { volume: 'ft³', weight: 'lb', lengthS: 'in', lengthM: 'ft' }
};

export const UnitsOptions = [
  { label: 'Metric (m, kg)', value: UnitsValues.metric },
  { label: 'Imperial (in, lb)', value: UnitsValues.imperial }
];
