import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useRecoilState } from 'recoil';
import {
  customSpaceWeightImperial,
  customSpaceWeightMetric,
  SpaceType
} from '../../../constants/spaceConstants';
import { UnitsValues } from '../../../constants/unitsConstants';
import { unitsState } from '../../../state/unitsState';
import { SpaceFormFields } from '../../../types';
import useUpdateSpaceType from './useUpdateSpaceType';

interface UseUpdateWeightProps {
  defaultValue?: number | undefined;
}

const useUpdateWeight = ({ defaultValue }: UseUpdateWeightProps): number => {
  const {
    setValue,
    watch,
    formState: { touchedFields }
  } = useFormContext<SpaceFormFields>();
  const [currentUnits] = useRecoilState(unitsState);

  const spaceTypeValue = useUpdateSpaceType();

  const weightField = `space.maxWeight`;
  const weightValue = watch(weightField);

  const defaultCustomWeight =
    currentUnits === UnitsValues.imperial
      ? customSpaceWeightImperial
      : customSpaceWeightMetric;

  const isTouched = touchedFields.space?.maxWeight;

  useEffect(() => {
    if (!isTouched) {
      if (spaceTypeValue === SpaceType.Custom) {
        setValue(weightField, defaultCustomWeight);
      } else {
        setValue(weightField, defaultValue ?? 0);
      }
    }
  }, [defaultValue, spaceTypeValue, isTouched]);

  return weightValue;
};

export default useUpdateWeight;
