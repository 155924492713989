import { atom } from 'recoil';
import { defaultColorsBarState } from '../constants/spaceConstants';
import { ColorsBarState } from '../types';

export const volumeColorsBarState = atom<ColorsBarState>({
  key: 'volumeColorsBarState',
  default: defaultColorsBarState
});

export const weightColorsBarState = atom<ColorsBarState>({
  key: 'weightColorsBarState',
  default: defaultColorsBarState
});
