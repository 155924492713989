import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  LoadType,
  volumeDivisorImperial,
  volumeDivisorMetric
} from '../../../constants/loadGroupsConstants';
import { UnitsValues } from '../../../constants/unitsConstants';
import calculateVolume from '../utils/calculateVolumeUtil';

export default function useCalculatedVolume(
  groupIndex: number
): {
  volume: number;
  totalVolume: number;
} {
  const { watch } = useFormContext();
  const groupFieldName = `loadGroups.${groupIndex as 0}` as const;

  const loadGroupTypeField = `${groupFieldName}.loadType` as const;
  const widthField = `${groupFieldName}.width` as const;
  const heightField = `${groupFieldName}.height` as const;
  const diameterField = `${groupFieldName}.diameter` as const;
  const lengthField = `${groupFieldName}.length` as const;
  const quantityField = `${groupFieldName}.quantity` as const;
  const units = `measurementSystem`;

  const loadTypeValue = watch(loadGroupTypeField);
  const widthValue = watch(widthField) ?? 0;
  const heightValue = watch(heightField);
  const diameterValue = watch(diameterField) ?? 0;
  const lengthValue = watch(lengthField) ?? 0;
  const quantityValue = watch(quantityField);
  const unitsValue = watch(units);

  const { totalVolume, volume } = useMemo(
    () =>
      calculateVolume({
        isCylinder: loadTypeValue === LoadType.Drums,
        diameter: diameterValue,
        width: widthValue,
        length: lengthValue,
        height: heightValue,
        quantity: quantityValue,
        volumeMeasurmentCoef:
          unitsValue === UnitsValues.metric
            ? volumeDivisorMetric
            : volumeDivisorImperial
      }),
    [
      loadTypeValue,
      diameterValue,
      widthValue,
      lengthValue,
      heightValue,
      quantityValue,
      unitsValue
    ]
  );

  return {
    totalVolume,
    volume
  };
}
