import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { FormProvider } from 'react-hook-form';
import { useRecoilState } from 'recoil';
import LoadGroupsList from '../containers/LoadGroupsList/LoadGroupsList';
import sm from './styles.module.scss';
import CalculationsSection from '../containers/CalculationsSection/CalculationsSection';
import SpaceForm from '../containers/SpaceForm/SpaceForm';
import { calculationsLoadingState } from '../state/calculationsState';
import Loader from '../components/Loader';
import useFetchData from '../hooks/useFetchData';
import Error500 from '../components/Error500/Error500';
import Modal from '../components/Modal/Modal';
import ResultsModalContent from '../containers/ResultsModalContent/ResultsModalContent';
import { getModalTitles } from '../utils/getModalTitles';
import { modalOpenState } from '../state/modalState';
import { resultsSentState } from '../state/calculationResultsState';
import { useRouteGuard } from '../hooks/useRouteGuard';
import { useGlobalFormMethods } from '../containers/GlobalFormsProvider/GlobalFormsProvider';
import useSpaceForm from '../containers/SpaceForm/useSpaceForm';
import ToastWithDeleteBtn from '../components/ToastWithDeleteBtn/ToastWithDeleteBtn';
import { containerTypeState } from '../state/containerTypeState';

const ContainerPage: React.FC = () => {
  useRouteGuard();
  const { spaceFormMethods } = useGlobalFormMethods();
  const { isError: fetchError } = useFetchData();
  const [isCalculationLoading] = useRecoilState(calculationsLoadingState);
  const [isModalOpen] = useRecoilState(modalOpenState);
  const [isModalFormSent] = useRecoilState(resultsSentState);
  const { shouldDisplayPlates } = useSpaceForm(spaceFormMethods);
  const [currentContainer] = useRecoilState(containerTypeState);
  const [showPlate, setShowPlate] = useState<boolean>(false);

  useEffect(() => {
    setShowPlate(true);
  }, [currentContainer]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const deteteToastDataFromState = () => {
    setShowPlate(false);
  };

  if (fetchError) {
    return <Error500 />;
  }

  return (
    <>
      {shouldDisplayPlates.boldMessage !== '' && showPlate && (
        <div className={sm.Page_ToastWarrning}>
          <ToastWithDeleteBtn
            title={shouldDisplayPlates.boldMessage}
            subtitle={shouldDisplayPlates.infoMes}
            onDeleteClick={() => deteteToastDataFromState()}
          />
        </div>
      )}
      <FormProvider {...spaceFormMethods}>
        <div className={cn(sm.Page, sm.Page_Container)}>
          <div className={sm.Page_ContainerContent}>
            <SpaceForm formMethods={spaceFormMethods} />

            {isCalculationLoading ? <Loader /> : <CalculationsSection />}
          </div>
          <LoadGroupsList />
        </div>
      </FormProvider>
      <div
        className={cn(sm.Page_Container__ModalClosed, {
          [sm.Page_Container__ModalOpened]: isModalOpen
        })}
      >
        <Modal
          isOpen={isModalOpen}
          content={<ResultsModalContent />}
          title={getModalTitles(isModalFormSent).title}
          subtitle={getModalTitles(isModalFormSent).subtitle}
        />
      </div>
    </>
  );
};

export default ContainerPage;
