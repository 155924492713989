const scaleCalc = (
  width: number,
  height: number,
  length: number
): { findBiggesCoeff: number; findMaxOfBiggesCoeff: number } => {
  const startWidth = 2;
  const startHeight = 2;
  const startLenght = 4;
  const findBiggesCoeff = Math.min(
    startWidth / width,
    startHeight / height,
    startLenght / length
  );
  const findMaxOfBiggesCoeff = Math.max(
    width / startWidth,
    height / startHeight,
    length / startLenght
  );
  return { findBiggesCoeff, findMaxOfBiggesCoeff };
};

export default scaleCalc;
