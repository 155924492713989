import React from 'react';
import { TLangingPageTopDescrCardListData } from '../../types';
import LangingPageTopDescrCardItem from '../LangingPageTopDescrCardItem/LangingPageTopDescrCardItem';
import sm from './styles.module.scss';

type TProps = {
  listData: TLangingPageTopDescrCardListData[];
};

const LangingPageTopDescrCardList: React.FC<TProps> = ({ listData }) => {
  return (
    <div className={sm.cardListWrapper}>
      <ul className={sm.cardListWrapper_CardList}>
        {listData.map((item) => {
          return (
            <LangingPageTopDescrCardItem key={`${item.text}`} item={item} />
          );
        })}
      </ul>
    </div>
  );
};

export default LangingPageTopDescrCardList;
