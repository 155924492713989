import {
  FieldArrayMethodProps,
  useFieldArray,
  UseFormHandleSubmit
} from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import useFetchData from '../../hooks/useFetchData';
import { loadGroupsState } from '../../state/loadGroupsState';
import { scrollState } from '../../state/scrollState';

import { LoadGroup, LoadGroupFields, TMes } from '../../types';
import {
  getTextByItemsLength,
  getGroupsQuantityErrorMessage
} from '../../utils/getLoadGroupsInfoTexts';
import getMintypesMessArr from '../../utils/getMintypesMessArr';
import useUpdateTotalLoadGroupsData from '../CreateLoadGroupItem/hooks/useUpdateTotalLoadGroupsData';
import { useGlobalFormMethods } from '../GlobalFormsProvider/GlobalFormsProvider';

interface UseCreateLoadApi {
  errorTextFirstPart: string;
  onSubmit: (data: LoadGroupFields) => void;
  totalLoadGroupsVolume: number;
  totalLoadGroupsWeight: number;
  insert: (
    index: number,
    value: Partial<LoadGroup> | Partial<LoadGroup>[],
    options?: FieldArrayMethodProps | undefined
  ) => void;
  handleSubmit: UseFormHandleSubmit<LoadGroupFields>;
  defaultFormValues: LoadGroup;
  loadGroups: LoadGroup[];
  groupsQuantityErrorMessage: string;
  shouldDisplayErrorToast: boolean;
  shouldDisplayErrorToastWithSize: TMes[];
}

const useCreateLoad = (): UseCreateLoadApi => {
  const { loadGroupsDefaultFormValues: defaultFormValues } = useFetchData();
  const history = useHistory();
  const [, setLoadGroupsData] = useRecoilState(loadGroupsState);
  const [, setScroll] = useRecoilState(scrollState);

  const { loadGroupsFormMethods } = useGlobalFormMethods();

  const {
    control,
    getValues,
    handleSubmit,
    formState: { errors, isSubmitting }
  } = loadGroupsFormMethods;
  const { loadGroups } = getValues();
  const { insert } = useFieldArray<LoadGroupFields>({
    control,
    name: 'loadGroups'
  });

  const groupFieldNamesArr = loadGroups.map(
    (loadGroup, index) => `loadGroups.${index}`
  ) as `loadGroups.${number}`[];

  const {
    totalLoadGroupsVolume,
    totalLoadGroupsWeight
  } = useUpdateTotalLoadGroupsData({
    groupFieldNamesArr
  });

  const onSubmit = (data: LoadGroupFields) => {
    setLoadGroupsData(data.loadGroups);
    history.push('/container');
    window.scrollTo(0, 0);
    setScroll(false);
  };

  const loadGroupsWithErrors = isSubmitting
    ? errors.loadGroups?.filter(Boolean).length
    : 0;
  const errorTextFirstPart = getTextByItemsLength(loadGroupsWithErrors);

  const groupsQuantityErrorMessage = getGroupsQuantityErrorMessage(
    loadGroups.length
  );
  const isMinTypes = errors.loadGroups?.filter((item) => {
    return (
      item?.length?.type === 'min' ||
      item?.height?.type === 'min' ||
      item?.width?.type === 'min' ||
      item?.diameter?.type === 'min'
    );
  });

  const shouldDisplayErrorToast = !!errors.loadGroups?.length;
  const shouldDisplayErrorToastWithSize =
    isMinTypes && isMinTypes?.length > 0 ? getMintypesMessArr(isMinTypes) : [];

  return {
    errorTextFirstPart,
    onSubmit,
    totalLoadGroupsVolume,
    totalLoadGroupsWeight,
    insert,
    handleSubmit,
    defaultFormValues,
    loadGroups,
    groupsQuantityErrorMessage,
    shouldDisplayErrorToast,
    shouldDisplayErrorToastWithSize
  };
};

export default useCreateLoad;
