import { DeepMap, FieldError } from 'react-hook-form';
import { masValMessStr } from '../state/data-static';
import { Container } from '../types';

const chekForMinMessageText = (
  err: DeepMap<Container, FieldError>
): boolean => {
  const { length, width, height } = err;
  return (
    !length?.message?.includes(masValMessStr) &&
    !width?.message?.includes(masValMessStr) &&
    !height?.message?.includes(masValMessStr)
  );
};

export default chekForMinMessageText;
