import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { DimensionsDefault, SpaceFormFields } from '../../../types';

interface UseUpdateDimensionsProps {
  defaultValue?: number | undefined;
  dimension: keyof DimensionsDefault;
}

const useUpdateDimension = ({
  defaultValue,
  dimension
}: UseUpdateDimensionsProps): void => {
  const {
    setValue,
    trigger,
    formState: { touchedFields }
  } = useFormContext<SpaceFormFields>();
  const field = `space.${dimension}` as const;

  const isTouched = touchedFields.space?.[dimension];

  useEffect(() => {
    if (!isTouched) {
      setValue(field, defaultValue ?? 0);
    }
    trigger([`space.${dimension}` as const]);
  }, [defaultValue, isTouched]);
};

export default useUpdateDimension;
