import { UnitsValues } from './unitsConstants';

export const infoMessage = `Maximum 10 groups can be calculated.`;

export const refrigeratorData = {
  [UnitsValues.imperial]: {
    length: 32,
    width: 32,
    height: 69,
    weight: 262
  },
  [UnitsValues.metric]: {
    length: 81.28,
    width: 81.28,
    height: 175.26,
    weight: 118.8
  }
};

export const mattressData = {
  [UnitsValues.imperial]: {
    length: 80,
    width: 60,
    height: 5.5,
    weight: 25.1
  },
  [UnitsValues.metric]: {
    length: 203.2,
    width: 152.4,
    height: 13.97,
    weight: 11.39
  }
};

export const LoadType = {
  Boxes: 'boxes',
  Drums: 'drums',
  Totes: 'totes',
  Custom: 'custom'
} as const;

export const loadTypeLabelByType = {
  [LoadType.Boxes]: 'Box',
  [LoadType.Drums]: 'Drum',
  [LoadType.Totes]: 'Totes',
  [LoadType.Custom]: 'Custom'
} as const;

export const customLoadTypeDefaultData = [
  {
    name: loadTypeLabelByType[LoadType.Custom],
    height: 0,
    width: 0,
    length: 0,
    weight: 0,
    maxWeight: 0
  }
];

export const LoadTypeOptions = [
  {
    value: LoadType.Boxes,
    label: 'Box'
  },
  {
    value: LoadType.Drums,
    label: 'Drum'
  },
  {
    value: LoadType.Totes,
    label: 'Totes'
  },
  {
    value: LoadType.Custom,
    label: 'Custom'
  }
];

export const defaultLoadGroupsFormState = {
  loadGroups: [],
  totalGroupsVolume: 0,
  totalGroupsWeight: 0
};

export const volumeDivisorMetric = 1000000;
export const volumeDivisorImperial = 1728;
