interface CalculateVolumeProps {
  isCylinder: boolean;
  diameter: number;
  height: number;
  width: number;
  length: number;
  quantity: number;
  volumeMeasurmentCoef: number;
}

interface CalculateVolumeApi {
  totalVolume: number;
  volume: number;
}

const calculateVolumeByLoadType = ({
  isCylinder,
  diameter,
  height,
  width,
  length,
  quantity,
  volumeMeasurmentCoef
}: CalculateVolumeProps): CalculateVolumeApi => {
  const volumeRaw = isCylinder
    ? Math.PI * height * (diameter / 2) ** 2
    : height * width * length;

  const volume = volumeRaw / volumeMeasurmentCoef;

  const totalVolume = quantity * volume;

  return { totalVolume, volume };
};

export default calculateVolumeByLoadType;
