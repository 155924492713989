import React from 'react';
import cn from 'classnames';
import Button from '../Button/Button';
import Toast from '../Toast/Toast';
import { ReactComponent as ArrowLeft } from '../../assets/images/icon-close-red.svg';
import sm from './styles.module.scss';

type TProps = {
  title: string;
  subtitle: string;
  onDeleteClick: () => void;
};

const ToastWithDeleteBtn: React.FC<TProps> = ({
  title,
  subtitle,
  onDeleteClick
}) => {
  return (
    <>
      <Toast
        title={title}
        subtitle={subtitle}
        positionClassname={cn({
          [sm.ToastesWithClose_toastOnList]: sm.ToastesWithClose_toastOnList
        })}
      >
        <Button
          variant="control"
          classname={sm.ToastesWithClose_closeToast}
          iconPre={<ArrowLeft />}
          onClick={() => onDeleteClick()}
        />
      </Toast>
    </>
  );
};

export default ToastWithDeleteBtn;
