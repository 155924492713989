import { useState } from 'react';

interface UseShowFeedbackFormApi {
  handleSetFeedback: () => void;
  showFeedbackForm: boolean;
}

export const useShowFeedbackForm = (): UseShowFeedbackFormApi => {
  const [feedbackState, setFeedbackState] = useState(
    Boolean(localStorage.getItem('feedbackReceived'))
  );

  const handleSetFeedback = () => {
    localStorage.setItem('feedbackReceived', 'true');

    setFeedbackState(Boolean(localStorage.getItem('feedbackReceived')));
  };

  return {
    showFeedbackForm: !feedbackState,
    handleSetFeedback
  };
};
