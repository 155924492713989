import React from 'react';
import { FormProvider } from 'react-hook-form';
import Button from '../../components/Button/Button';
import FeedbackForm from '../FeedbackForm/FeedbackForm';
import sm from './styles.module.scss';
import { useSuccessModalContent } from './useSuccessModalContent';

const SuccessModalContent: React.FC = () => {
  const {
    setModalOpen,
    feedbackFormMethods,
    setSentResults,
    showFeedbackForm
  } = useSuccessModalContent();

  return showFeedbackForm ? (
    <FormProvider {...feedbackFormMethods}>
      <FeedbackForm formMethods={feedbackFormMethods} />
    </FormProvider>
  ) : (
    <div className={sm.SuccessModalContent_ButtonContainer}>
      <Button
        title="Good"
        classname={sm.SuccessModalContent_Button}
        onClick={() => {
          setModalOpen(false);
          setSentResults(false);
        }}
      />
    </div>
  );
};

export default SuccessModalContent;
