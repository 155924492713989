import { useFormContext } from 'react-hook-form';
import { useRecoilState } from 'recoil';
import {
  volumeDivisorImperial,
  volumeDivisorMetric
} from '../../../constants/loadGroupsConstants';
import { UnitsValues } from '../../../constants/unitsConstants';
import useFetchData from '../../../hooks/useFetchData';
import { unitsState } from '../../../state/unitsState';
import { LoadGroupFields } from '../../../types';

interface UseCalculatedPalletsProps {
  groupIndex: number;
}

interface UseCalculatedPalletsApi {
  palletsWeight: number;
  palletsVolume: number;
}

const useCalculatedPallets = ({
  groupIndex
}: UseCalculatedPalletsProps): UseCalculatedPalletsApi => {
  const { palletsData } = useFetchData();
  const { watch } = useFormContext<LoadGroupFields>();
  const [currentUnits] = useRecoilState(unitsState);

  const divisor =
    currentUnits === UnitsValues.metric
      ? volumeDivisorMetric
      : volumeDivisorImperial;
  const palletsField = `loadGroups.${groupIndex as 0}.pallets` as const;

  const pallets = watch(palletsField);

  const palletsWeight = palletsData?.weight * pallets;

  const volumePerPallet =
    (palletsData?.height * palletsData?.width * palletsData?.length) / divisor;

  const palletsVolume = volumePerPallet * pallets;

  return {
    palletsWeight,
    palletsVolume
  };
};

export default useCalculatedPallets;
