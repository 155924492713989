import React, { InputHTMLAttributes, MutableRefObject } from 'react';
import cn from 'classnames';
import sm from './styles.module.scss';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  minInputValue: number;
  handleMinInputChange: (e: number) => void;
  maxInputValue: number;
  handleMaxInputChange: (e: number) => void;
  range: MutableRefObject<HTMLDivElement | null>;
  max: number;
}

export type MultipleRangeProps = Props;

const MultipleRange: React.FC<MultipleRangeProps> = ({
  max,
  range,
  minInputValue,
  handleMinInputChange,
  maxInputValue,
  handleMaxInputChange,
  ...rest
}) => {
  const isCloseToMax = minInputValue > max - 100 && minInputValue > 50;
  return (
    <div className={sm.MultipleRange}>
      <input
        {...rest}
        type="range"
        min={0}
        max={100}
        value={minInputValue}
        className={cn(sm.MultipleRange_Thumb, sm.MultipleRange_ThumbLeft)}
        style={{ zIndex: isCloseToMax ? 5 : 3 }}
        onChange={(e) => {
          handleMinInputChange(Number(e.target.value));
        }}
      />

      <input
        {...rest}
        type="range"
        min={0}
        max={100}
        value={maxInputValue}
        onChange={(e) => {
          handleMaxInputChange(Number(e.target.value));
        }}
        className={cn(sm.MultipleRange_Thumb, sm.MultipleRange_ThumbRight)}
        style={{ zIndex: isCloseToMax ? 3 : 5 }}
      />
      <div className={sm.MultipleRange_Slider}>
        <div className={sm.MultipleRange_SliderTrack} />
        <div ref={range} className={sm.MultipleRange_SliderRange} />
      </div>
    </div>
  );
};

export default MultipleRange;
