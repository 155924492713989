import React from 'react';
import { useHistory } from 'react-router-dom';
import Button from '../Button/Button';
import { ReactComponent as ArrowLeft } from '../../assets/images/icon-arrowLeft.svg';
import sm from './styles.module.scss';
import { Paths } from '../../constants/paths';

const BackButton: React.FC = () => {
  const history = useHistory();

  return (
    <div className={sm.BackButton}>
      <Button
        variant="linkLike"
        title="Back"
        iconPre={<ArrowLeft />}
        onClick={() => history.push(Paths.Home)}
        classname={sm.BackButton_Button}
        textClassname={sm.BackButton_Text}
        iconClassname={sm.BackButton_Icon}
      />
    </div>
  );
};

export default BackButton;
