import React, { useEffect, useState } from 'react';
import {
  Route,
  Switch,
  BrowserRouter as Router,
  useLocation
} from 'react-router-dom';
import ReactGA from 'react-ga';
import ErrorBoundry from './components/ErrorBoundry';
import Header from './components/Header/Header';
import ContainerPage from './pages/Container';
import LoadGroupsPage from './pages/LoadGroups';

import sm from './styles.module.scss';
import NotFoundPage from './pages/NotFoundPage';
import HomePage from './pages/Home';
import Footer from './components/Footer/Footer';
import { Paths } from './constants/paths';
import Policies from './containers/Policies/Policies';
import useFetchData from './hooks/useFetchData';
import { GlobalFormsProvider } from './containers/GlobalFormsProvider/GlobalFormsProvider';

const usePageViews = () => {
  const location = useLocation();
  const [locationState, setlocationState] = useState(location.pathname);
  const isProduction = process.env.REACT_APP_NODE_ENV === 'production';

  useEffect(() => {
    if (typeof window !== 'undefined' && isProduction) {
      ReactGA.initialize('UA-31105176-1');
      ReactGA.pageview(location.pathname);
    }
  }, []);
  useEffect(() => {
    if (location.pathname !== locationState && isProduction) {
      setlocationState(location.pathname);
      ReactGA.pageview(location.pathname);
    }
  }, [location]);
};

const AppWrapper: React.FC = () => {
  usePageViews();
  return (
    <div className={sm.App}>
      <Header />
      <Switch>
        <Route exact path={Paths.Home} component={HomePage} />
        <Route exact path={Paths.LoadGroups} component={LoadGroupsPage} />
        <Route exact path={Paths.Container} component={ContainerPage} />
        <Route component={NotFoundPage} />
      </Switch>
      <Policies />
      <Footer />
    </div>
  );
};
const App: React.FC = () => {
  useFetchData();

  return (
    <Router>
      <ErrorBoundry>
        <GlobalFormsProvider>
          <AppWrapper />
        </GlobalFormsProvider>
      </ErrorBoundry>
    </Router>
  );
};

export default App;
