import { UseFormWatch } from 'react-hook-form';
import { LoadGroupFields, PalletsData } from '../../../types';

export const getTotalWeightCalculation = ({
  groupFieldName,
  watch,
  palletsData
}: {
  watch: UseFormWatch<LoadGroupFields>;
  groupFieldName: `loadGroups.${number}`;
  palletsData: PalletsData;
}): number => {
  const weightField = `${groupFieldName}.weight` as const;
  const quantityField = `${groupFieldName}.quantity` as const;
  const palletsField = `${groupFieldName}.pallets` as const;

  const weight = watch(weightField);
  const quantity = watch(quantityField);
  const pallets = watch(palletsField);

  const palletsWeight = palletsData?.weight * pallets;

  return quantity * weight + palletsWeight;
};
