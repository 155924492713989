import React from 'react';
import BenefitsCalculatorListItems from '../BenefitsCalculatorListItems/BenefitsCalculatorListItems';
import { BenefitsCalculatorListItemsData } from '../HomePageLanding/data';
import sm from './styles.module.scss';

const BenefitsCalculatorList: React.FC = () => {
  return (
    <div className={sm.BenefitsCalculatorList}>
      <h3 className={sm.BenefitsCalculatorList_title}>
        Benefits of our load space calculator
      </h3>
      <BenefitsCalculatorListItems listData={BenefitsCalculatorListItemsData} />
    </div>
  );
};

export default BenefitsCalculatorList;
