import { UseFormReturn, UseFormHandleSubmit } from 'react-hook-form';
import { useRecoilState } from 'recoil';
import { useCalculationMutation } from '../../hooks/useCalculationMutation';
import useFetchData from '../../hooks/useFetchData';
import { calculationsLoadingState } from '../../state/calculationsState';
import { containerTypeState } from '../../state/containerTypeState';
import { sizeValidation } from '../../state/data-static';
import { loadGroupsState } from '../../state/loadGroupsState';
import { unitsState } from '../../state/unitsState';
import { SpaceFormFields, TExtraSizeWarning } from '../../types';
import chekForErrorPlates from '../../utils/chekForErrorPlates';
import { formsDataToRequestEntry } from '../../utils/formsDataToRequestEntry';

interface UseSpaceFormApi {
  onSubmit: (data: SpaceFormFields) => void;
  handleSubmit: UseFormHandleSubmit<SpaceFormFields>;
  disableCalculation: boolean;
  isCalculationLoading: boolean;
  isError: boolean;
  shouldDisplayPlates: TExtraSizeWarning;
}

const useSpaceForm = (
  methods: UseFormReturn<SpaceFormFields>
): UseSpaceFormApi => {
  const {
    handleSubmit,
    formState: { errors }
  } = methods;
  const [currentUnits] = useRecoilState(unitsState);
  const { mutate, isError } = useCalculationMutation();
  const { colorsList } = useFetchData();
  const [isCalculationLoading] = useRecoilState(calculationsLoadingState);
  const [loadGroupsData] = useRecoilState(loadGroupsState);
  const [containerType] = useRecoilState(containerTypeState);
  const [currentContainer] = useRecoilState(containerTypeState);

  const onSubmit = (data: SpaceFormFields) => {
    return mutate(
      formsDataToRequestEntry({
        spaceData: data.space,
        loadGroupsData,
        units: currentUnits,
        colors: colorsList
      })
    );
  };

  const disableCalculation =
    !loadGroupsData.length || Boolean(errors.space) || isCalculationLoading;

  const shouldDisplayPlates: TExtraSizeWarning = chekForErrorPlates(
    errors.space,
    containerType,
    currentUnits,
    sizeValidation[currentContainer][currentUnits],
    methods.getValues('space.length'),
    methods.getValues('space.width'),
    methods.getValues('space.height')
  );

  return {
    handleSubmit,
    onSubmit,
    disableCalculation,
    isCalculationLoading,
    isError,
    shouldDisplayPlates
  };
};

export default useSpaceForm;
