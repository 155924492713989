import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useRecoilState } from 'recoil';
import { homePageState } from '../../../state/homePageState';
import { SpaceFormFields, Spaces } from '../../../types';
import getDataByHomePageSelection from '../../../utils/getDataByHomePageSelection';

const useUpdateSpaceType = (): Spaces => {
  const {
    watch,
    setValue,
    formState: { touchedFields }
  } = useFormContext<SpaceFormFields>();
  const [homePageSelectedOption] = useRecoilState(homePageState);

  const spaceTypeField = `space.name`;
  const spaceTypeValue = watch(spaceTypeField);

  const isTouched = touchedFields.space?.name;

  useEffect(() => {
    if (homePageSelectedOption && !isTouched) {
      setValue(
        spaceTypeField,
        getDataByHomePageSelection(homePageSelectedOption).space
      );
    }
  }, [isTouched, homePageSelectedOption]);

  return spaceTypeValue;
};

export default useUpdateSpaceType;
