import { MutableRefObject, useEffect, useRef } from 'react';

export const useClickOutside = ({
  onOutsideClick
}: {
  onOutsideClick: () => void;
}): MutableRefObject<HTMLElement | null> => {
  const wrapperRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    if (!wrapperRef.current) {
      return undefined;
    }
    const handleClickOutside = (event: MouseEvent) => {
      if (!wrapperRef.current?.contains(event.target as Node)) {
        onOutsideClick();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  return wrapperRef;
};
