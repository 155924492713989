import { atom } from 'recoil';
import { contactInfoDefault } from '../constants';
import { ContactInfo } from '../types';

export const contactInfoState = atom<ContactInfo>({
  key: 'contactInfoState',
  default: contactInfoDefault
});

export const feedbackErrorState = atom<boolean>({
  key: 'feedbackErrorState',
  default: false
});
