import React, { Component } from 'react';
import ErrorIndicator from '../ErrorIndicator';

export default class ErrorBoundry extends Component {
  state = {
    hasError: false
  };

  componentDidCatch(): void {
    this.setHasError(true);
  }

  setHasError = (hasError: boolean): void => this.setState({ hasError });

  render(): React.ReactNode {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return <ErrorIndicator />;
    }
    return children;
  }
}
