import React from 'react';
import sm from './styles.module.scss';

import { ReactComponent as LogoFooter } from '../../assets/images/logoFooter.svg';
import { ReactComponent as LinkedinIcon } from '../../assets/images/social/linkedin.svg';
import { ReactComponent as TwitterIcon } from '../../assets/images/social/twitter.svg';
import { ReactComponent as WebIcon } from '../../assets/images/social/site.svg';
import { ReactComponent as GithubIcon } from '../../assets/images/social/github.svg';
import Button from '../Button/Button';

const Footer: React.FC = () => {
  return (
    <div className={sm.Footer}>
      <div className={sm.Footer_Content}>
        <div className={sm.Footer_BlockLogo}>
          <LogoFooter />
          <div className={sm.Footer_Subtitle}>Designed by Yalantis</div>
          <div className={sm.Footer_Social}>
            <a href="https://dribbble.com/yalantis" target="blank">
              <WebIcon />
            </a>
            <a href="https://github.com/Yalantis" target="blank">
              <GithubIcon />
            </a>
            <a
              href="https://www.linkedin.com/company/yalantis/mycompany/"
              target="blank"
            >
              <LinkedinIcon />
            </a>
            <a href="https://twitter.com/yalantis" target="blank">
              <TwitterIcon />
            </a>
          </div>
        </div>
        <div className={sm.Footer_BlockLinks}>
          <a href="https://yalantis.com/works/" target="blank">
            Works
          </a>
          <a href="https://yalantis.com/services/" target="blank">
            Services
          </a>
          <a href="https://yalantis.com/expertise/" target="blank">
            Expertise
          </a>
          <a href="https://yalantis.com/industries/" target="blank">
            Domains
          </a>
          <a href="https://yalantis.com/company/" target="blank">
            Company
          </a>
          <a href="https://yalantis.com/privacy-policy/" target="blank">
            Privacy Policy
          </a>
        </div>

        <a href="https://yalantis.com/contacts/" target="blank">
          <Button
            variant="outlined"
            title="Contact us"
            classname={sm.Footer_BlockContact}
          />
        </a>
      </div>
    </div>
  );
};

export default Footer;
