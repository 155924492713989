import { TBoxItems, TBoxValidation, TSizeValidation } from '../types';

export const boxValidation: TBoxValidation = {
  imperial: 4,
  metric: 10
};

export const boxItems: TBoxItems = {
  imperial: 'in',
  metric: 'cm'
};

export const sizeValidation: TSizeValidation = {
  Container: {
    imperial: 98,
    metric: 30
  },
  Vehicle: {
    imperial: 65,
    metric: 20
  },
  Storage: {
    imperial: 65,
    metric: 20
  },
  Custom: {
    imperial: 98,
    metric: 30
  }
};

export const masValMessStr = 'Max value is';
