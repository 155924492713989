import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { SpaceFormFields } from '../../../types';

const useUpdateVolume = (): number => {
  const { setValue, watch } = useFormContext<SpaceFormFields>();
  const widthField = `space.width`;
  const heightField = `space.height`;
  const lengthField = `space.length`;
  const volumeField = `space.volume`;

  const heightValue = watch(heightField);
  const widthValue = watch(widthField);
  const lengthValue = watch(lengthField);

  const volume = heightValue * widthValue * lengthValue;

  useEffect(() => {
    setValue(volumeField, volume);
  }, [volume]);

  return volume;
};

export default useUpdateVolume;
