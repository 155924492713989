import { Dispatch, SetStateAction, useState } from 'react';

import { SetterOrUpdater, useRecoilState, useRecoilValue } from 'recoil';
import {
  getContainerItemsInfo,
  getContainerSpaceMinLimit,
  getContainerType,
  getContainerVolumeColorsInfo,
  getContainerWeightColorsInfo,
  getContainerWeightMinLimit,
  getTotalVolumePercent,
  getTotalWeightPercent
} from '../../state/calculationsState';
import {
  volumeColorsBarState,
  weightColorsBarState
} from '../../state/colorsBarState';
import { unitsState } from '../../state/unitsState';
import {
  ColorBar,
  ColorsBarState,
  PlacementItem,
  Spaces,
  Units
} from '../../types';
import { getWarningMessageText } from '../../utils/getWarningMessageText';

interface UseContainerInfoApi {
  containerType: Spaces;
  volumeInfo: ColorBar[];
  weightInfo: ColorBar[];
  itemsInfo: PlacementItem[];
  isOpen: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  totalVolumePercent: number;
  totalWeightPercent: number;
  shouldDisplayWarning: boolean;
  warningText: string;
  weightHoveredState: ColorsBarState;
  setWeightHoveredColor: SetterOrUpdater<ColorsBarState>;
  volumeHoveredState: ColorsBarState;
  setVolumeHoveredColor: SetterOrUpdater<ColorsBarState>;
  currentUnits: Units;
}

export const useContainerInfo = (index: number): UseContainerInfoApi => {
  const containerType = useRecoilValue(getContainerType);
  const [currentUnits] = useRecoilState(unitsState);

  const volumeInfo = useRecoilValue(getContainerVolumeColorsInfo(index));
  const weightInfo = useRecoilValue(getContainerWeightColorsInfo(index));
  const totalVolumePercent = useRecoilValue(getTotalVolumePercent(index));
  const totalWeightPercent = useRecoilValue(getTotalWeightPercent(index));
  const itemsInfo = useRecoilValue(getContainerItemsInfo(index));
  const minSpaceLimit = useRecoilValue(getContainerSpaceMinLimit(index));
  const minWeightLimit = useRecoilValue(getContainerWeightMinLimit(index));

  const [weightHoveredState, setWeightHoveredColor] = useRecoilState(
    weightColorsBarState
  );
  const [volumeHoveredState, setVolumeHoveredColor] = useRecoilState(
    volumeColorsBarState
  );

  const [isOpen, setOpen] = useState(false);

  const shouldDisplayWarning =
    totalVolumePercent < minSpaceLimit ||
    (totalVolumePercent < minSpaceLimit && totalWeightPercent < minWeightLimit);

  const warningText = getWarningMessageText({
    volume: { percent: totalVolumePercent, min: minSpaceLimit },
    weight: { percent: totalWeightPercent, min: minWeightLimit }
  });

  return {
    containerType,
    volumeInfo,
    weightInfo,
    itemsInfo,
    isOpen,
    setOpen,
    totalVolumePercent,
    totalWeightPercent,
    shouldDisplayWarning,
    warningText,
    weightHoveredState,
    setWeightHoveredColor,
    volumeHoveredState,
    setVolumeHoveredColor,
    currentUnits
  };
};
