export const getModalTitles = (
  formDataIsSent: boolean
): { title: string; subtitle: string } => {
  if (formDataIsSent) {
    return {
      title: 'The results have been sent',
      subtitle: 'Thanks!🎉'
    };
  }
  return {
    title: 'Save results',
    subtitle: 'We will send you calculation results by email '
  };
};
