import React from 'react';
import {
  LangingPageTopDescrCardListData,
  SpaceCalculatorLandingListData
} from '../HomePageLanding/data';
import LangingPageTopDescrCardList from '../LangingPageTopDescrCardList/LangingPageTopDescrCardList';
import SpaceCalculatorLandingList from '../SpaceCalculatorLandingList/SpaceCalculatorLandingList';
import sm from './styles.module.scss';
import { ReactComponent as RightCircle } from '../../assets/images/right-circle.svg';

const SpaceCalculatorLanding: React.FC = () => {
  return (
    <div className={sm.SpaceCalculatorLanding}>
      <div className={sm.SpaceCalculatorLanding_bgWrapper}>
        <RightCircle />
      </div>
      <LangingPageTopDescrCardList listData={LangingPageTopDescrCardListData} />
      <div className={sm.SpaceCalculatorLanding_integration}>
        <div className={sm.SpaceCalculatorLanding_text}>
          <h3 className={sm.SpaceCalculatorLanding_title}>
            Seamless integration with your supply chain systems
          </h3>
          <p className={sm.SpaceCalculatorLanding_subtitle}>
            Integrate our ContainerWizard space calculator as a module in your
            supply chain systems:
          </p>
        </div>
        <SpaceCalculatorLandingList listData={SpaceCalculatorLandingListData} />
      </div>
    </div>
  );
};

export default SpaceCalculatorLanding;
