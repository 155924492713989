import React from 'react';
import Button from '../../components/Button/Button';

import sm from './styles.module.scss';
import { usePolicies } from './usePolicies';

const Policies: React.FC = () => {
  const { allowPolicies, handleSetPolicies } = usePolicies();
  return !allowPolicies ? (
    <div className={sm.Policies}>
      <div className={sm.Policies_Text}>
        We use cookies to personalize our service and to improve your experience
        on the website and its subdomains. We also use this information for
        analytics.
      </div>

      <div className={sm.Policies_Buttons}>
        <a href="https://yalantis.com/privacy-policy/" target="blank">
          <Button
            variant="linkLike"
            title="More info"
            classname={sm.Policies_LinkBtn}
          />
        </a>
        <Button
          title="Got it!"
          classname={sm.Policies_PrimaryBtn}
          onClick={handleSetPolicies}
        />
      </div>
    </div>
  ) : null;
};

export default Policies;
