import React from 'react';
import { useRecoilState } from 'recoil';
import sm from './styles.module.scss';
import EmptyContainers from '../EmptyContainers/EmptyContainers';
import ContainersList from '../ContainersList/ContainersList';
import CalculationResultsTitle from '../CalculationResultsTitle/CalculationResultsTitle';
import { isDefaultContainersState } from '../../state/containersState';

const CalculationsSection: React.FC = () => {
  const [isDefaultContent] = useRecoilState(isDefaultContainersState);

  return (
    <div className={sm.CalculationsSection}>
      {isDefaultContent ? (
        <EmptyContainers />
      ) : (
        <>
          <CalculationResultsTitle />
          <ContainersList />
        </>
      )}
    </div>
  );
};

export default CalculationsSection;
