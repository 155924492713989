import React from 'react';
import { ReactComponent as InfoIcon } from '../../assets/images/icon-info.svg';
import sm from './styles.module.scss';

interface LoadGroupsInfoMessageProps {
  title: string;
}

const LoadGroupsInfoMessage: React.FC<LoadGroupsInfoMessageProps> = ({
  title
}) => {
  return (
    <div className={sm.InfoMessage}>
      <div className={sm.InfoMessage_Icon}>
        <InfoIcon />
      </div>
      <div className={sm.InfoMessage_Text}>
        <div className={sm.InfoMessage_Title}>{title}</div>

        <div className={sm.InfoMessage_Subtitle}>
          Maximum 10 groups can be calculated
        </div>
      </div>
    </div>
  );
};

export default LoadGroupsInfoMessage;
