import { AxiosError, AxiosResponse } from 'axios';
import { UseMutateFunction, useMutation } from 'react-query';
import { useRecoilState } from 'recoil';
import { sendResults } from '../network/services';
import {
  resultsErrorState,
  resultsSentState
} from '../state/calculationResultsState';
import { SendResultsRequestEntry } from '../types';
import { getRequestErrorByStatus } from '../utils/getRequestErrorByStatus';

interface UseSendResultsMutationApi {
  mutate: UseMutateFunction<
    AxiosResponse,
    unknown,
    SendResultsRequestEntry,
    unknown
  >;
  isLoading: boolean;
  isError: boolean;
}

export const useSendResultsMutation = (): UseSendResultsMutationApi => {
  const [, setIsSent] = useRecoilState(resultsSentState);
  const [isError, setErrorState] = useRecoilState(resultsErrorState);
  const { mutate, isLoading } = useMutation('sendResults', sendResults, {
    onSuccess: () => setIsSent(true),
    onError: (err) => getRequestErrorByStatus(err as AxiosError, setErrorState)
  });

  return {
    mutate,
    isLoading,
    isError
  };
};
