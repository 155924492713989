import React from 'react';
import { useFormContext } from 'react-hook-form';
import EmptyLoadsList from '../../components/EmptyLoadsList/EmptyLoadsList';
import useFetchData from '../../hooks/useFetchData';
import { LoadGroupFields } from '../../types';

import CreateLoadGroupItem from '../CreateLoadGroupItem/CreateLoadGroupItem';
import { useFormSideEffects } from '../GlobalFormsProvider/useFormSideEffects';
import sm from './styles.module.scss';

const CreateLoadGroupList: React.FC = () => {
  useFormSideEffects();
  const { colorsList } = useFetchData();
  const { getValues } = useFormContext<LoadGroupFields>();
  const { loadGroups } = getValues();

  return (
    <div className={sm.CreateLoadGroupList}>
      {!loadGroups.length ? (
        <div className={sm.CreateLoadGroupList_Empty}>
          <EmptyLoadsList />
        </div>
      ) : (
        loadGroups.map((item, index) => {
          return (
            <div
              key={`loadGroups.${index}` as const}
              className={sm.CreateLoadGroupList_Content}
            >
              <CreateLoadGroupItem
                loadGroup={item}
                groupIndex={index}
                color={colorsList[index]}
              />
            </div>
          );
        })
      )}
    </div>
  );
};

export default CreateLoadGroupList;
