import React, { ReactElement } from 'react';
import Button from '../Button/Button';
import sm from './styles.module.scss';
import { ReactComponent as CloseIcon } from '../../assets/images/icon-close.svg';

interface ModalProps {
  content: ReactElement;
  title?: string;
  subtitle?: string;
  setModalOpen?: (
    valOrUpdater: boolean | ((currVal: boolean) => boolean)
  ) => void;
  setIsSent?: (valOrUpdater: boolean | ((currVal: boolean) => boolean)) => void;
  isThreeDModal?: boolean;
  setOpen?: (value: React.SetStateAction<boolean>) => void;
  modalClass?: string;
}

const ModalWrapper: React.FC<ModalProps> = ({
  setModalOpen,
  setIsSent,
  setOpen,
  title,
  subtitle,
  content,
  isThreeDModal = false,
  modalClass = ''
}) => {
  const closeBtnClickHedler = () => {
    if (!isThreeDModal && setModalOpen && setIsSent) {
      setModalOpen(false);
      setIsSent(false);
    } else if (isThreeDModal && setOpen) {
      setOpen(false);
    }
  };
  return (
    <div className={`${sm.Modal} ${modalClass}`}>
      <div className={sm.Modal_CloseBtn}>
        <Button
          iconPre={<CloseIcon />}
          variant="linkLike"
          onClick={closeBtnClickHedler}
        />
      </div>
      <div className={sm.Modal_Content}>
        {title && <div className={sm.Modal_Title}>{title}</div>}
        {subtitle && <div className={sm.Modal_Subtitle}>{subtitle}</div>}
        {content}
      </div>
    </div>
  );
};

export default ModalWrapper;
