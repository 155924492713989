import { DeepMap, FieldError } from 'react-hook-form';
import { Container, TExtraSizeWarning } from '../types';
import chekForMinMessageText from './chekForMinMessageText';

const chekForErrorPlates = (
  err: DeepMap<Container, FieldError> | undefined,
  containerType: string,
  currentUnits: string,
  size: number,
  lenght: string | number,
  width: string | number,
  height: string | number
): TExtraSizeWarning => {
  if (
    err === undefined ||
    Object.keys(err).length === 0 ||
    (err && chekForMinMessageText(err))
  ) {
    return { boldMessage: '', infoMes: '', lenght, width, height };
  }

  const boldMessage = `Values are too large for ${containerType.toLocaleLowerCase()}`;
  const infoMes = `Maximum values are ${size}х${size}х${size} ${
    currentUnits === 'metric' ? 'm' : 'ft'
  }`;
  return { boldMessage, infoMes, lenght, width, height };
};

export default chekForErrorPlates;
