import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { Limit } from '../../types';

interface UseUpdateRangeProps {
  value: Limit;
  onChange: (nextValue: Limit) => void;
  limit: number;
}
interface UseUpdateRangeApi {
  minPercent: MutableRefObject<HTMLDivElement | null>;
  maxPercent: MutableRefObject<HTMLDivElement | null>;
  range: MutableRefObject<HTMLDivElement | null>;
  minInputValue: number;
  maxInputValue: number;
  handleMaxInputChange: (e: number) => void;
  handleMinInputChange: (e: number) => void;
  minLimitValue: number;
  maxLimitValue: number;
}

export const useUpdateRange = ({
  value: { min, max },
  onChange,
  limit
}: UseUpdateRangeProps): UseUpdateRangeApi => {
  const [minInputValue, setMinVal] = useState(min);
  const [maxInputValue, setMaxVal] = useState(max);

  const range = useRef<HTMLDivElement | null>(null);
  const minPercent = useRef<HTMLDivElement | null>(null);
  const maxPercent = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (range.current) {
      range.current.style.left = `${minInputValue}%`;
      range.current.style.width = `${maxInputValue - minInputValue}%`;
    }
    if (minPercent.current) {
      minPercent.current.style.left = `${minInputValue}%`;
      if (minInputValue >= 15 && minInputValue < 80) {
        minPercent.current.style.left = `calc(${minInputValue}% - 20px)`;
      }
      if (minInputValue >= 80 && minInputValue < 90) {
        minPercent.current.style.left = `calc(${minInputValue}% - 40px)`;
      }
      if (minInputValue >= 90) {
        minPercent.current.style.left = `calc(${minInputValue}% - 60px)`;
      }
    }
    if (maxPercent.current) {
      maxPercent.current.style.left = `calc(${maxInputValue}% + 20px)`;
      if (maxInputValue >= 25 && maxInputValue <= 90) {
        maxPercent.current.style.left = `${maxInputValue}%`;
      }
      if (maxInputValue >= 90 && maxInputValue <= 95) {
        maxPercent.current.style.left = `calc(${maxInputValue}% - 20px)`;
      }
      if (maxInputValue > 95) {
        maxPercent.current.style.left = `calc(${maxInputValue}% - 40px)`;
      }
    }
  }, [minInputValue, maxInputValue]);

  const handleMinInputChange = (val: number) => {
    const value = Math.min(Number(val), maxInputValue - 5);
    setMinVal(value);
  };

  const handleMaxInputChange = (val: number) => {
    const value = Math.max(Number(val), minInputValue + 5);
    setMaxVal(value);
  };

  const minLimitValue = (minInputValue * limit) / 100;
  const maxLimitValue = (maxInputValue * limit) / 100;

  useEffect(() => {
    onChange({
      max: maxInputValue,
      min: minInputValue
    });
  }, [minInputValue, maxInputValue]);

  return {
    range,
    maxPercent,
    minPercent,
    minInputValue,
    maxInputValue,
    handleMinInputChange,
    handleMaxInputChange,
    minLimitValue,
    maxLimitValue
  };
};
