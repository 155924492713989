import React from 'react';
import sm from './styles.module.scss';
import RangesField from '../SpaceForm/RangesField/RangesField';
import useSpaceFormData from '../SpaceForm/hooks/useSpaceFormData';

const SpaceOptimization: React.FC = () => {
  const { weightValue, volumeValue } = useSpaceFormData();
  return (
    <div className={sm.SpaceOptimization}>
      <div className={sm.SpaceOptimization_Title}>Space optimization</div>

      <div className={sm.SpaceOptimization_RangeContainer}>
        <RangesField
          name="space.spaceLimit"
          limitType="volume"
          limit={volumeValue}
        />

        <RangesField
          name="space.weightLimit"
          limitType="weight"
          limit={weightValue}
        />
      </div>
    </div>
  );
};

export default SpaceOptimization;
