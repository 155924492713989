import { UseFormWatch } from 'react-hook-form';
import {
  LoadType,
  volumeDivisorImperial,
  volumeDivisorMetric
} from '../../../constants/loadGroupsConstants';
import { UnitsValues } from '../../../constants/unitsConstants';
import { LoadGroupFields, PalletsData, Units } from '../../../types';
import calculateVolume from './calculateVolumeUtil';

export const getTotalVolumeCalculation = ({
  groupFieldName,
  watch,
  palletsData,
  currentUnits
}: {
  watch: UseFormWatch<LoadGroupFields>;
  groupFieldName: `loadGroups.${number}`;
  palletsData: PalletsData;
  currentUnits: Units;
}): {
  totalVolume: number;
  volume: number;
} => {
  const loadGroupTypeField = `${groupFieldName}.loadType` as const;
  const widthField = `${groupFieldName}.width` as const;
  const heightField = `${groupFieldName}.height` as const;
  const diameterField = `${groupFieldName}.diameter` as const;
  const lengthField = `${groupFieldName}.length` as const;
  const quantityField = `${groupFieldName}.quantity` as const;
  const palletsField = `${groupFieldName}.pallets` as const;

  const loadTypeValue = watch(loadGroupTypeField);
  const widthValue = watch(widthField) ?? 0;
  const heightValue = watch(heightField);
  const diameterValue = watch(diameterField) ?? 0;
  const lengthValue = watch(lengthField) ?? 0;
  const quantityValue = watch(quantityField);
  const pallets = watch(palletsField);

  const divisor =
    currentUnits === UnitsValues.metric
      ? volumeDivisorMetric
      : volumeDivisorImperial;

  const { totalVolume, volume } = calculateVolume({
    isCylinder: loadTypeValue === LoadType.Drums,
    diameter: diameterValue,
    width: widthValue,
    length: lengthValue,
    height: heightValue,
    quantity: quantityValue,
    volumeMeasurmentCoef: divisor
  });

  const volumePerPallet =
    (palletsData?.height * palletsData?.width * palletsData?.length) / divisor;

  const palletsVolume = volumePerPallet * pallets;

  return {
    totalVolume: totalVolume + palletsVolume,
    volume
  };
};
