import { useRecoilState } from 'recoil';
import useFetchData from '../../../hooks/useFetchData';
import { calculationsLoadingState } from '../../../state/calculationsState';
import { unitsState } from '../../../state/unitsState';
import { Spaces, Units } from '../../../types';
import useUpdateDimension from './useUpdateDimension';
import useUpdateSpaceType from './useUpdateSpaceType';
import useUpdateVolume from './useUpdateVolume';
import useUpdateWeight from './useUpdateWeight';

interface UseSpaceFormDataApi {
  spaceTypeValue: Spaces;
  volumeValue: number;
  weightValue: number;
  currentUnits: Units;
  isCalculationsLoading: boolean;
}

const useSpaceFormData = (): UseSpaceFormDataApi => {
  const { loadGroupsData: predefinedData } = useFetchData();
  const [currentUnits] = useRecoilState(unitsState);

  const [isCalculationsLoading] = useRecoilState(calculationsLoadingState);

  const spaceTypeValue = useUpdateSpaceType();

  const spaceInfo = predefinedData[currentUnits].containers.find(
    (container) => container.name === spaceTypeValue
  );

  useUpdateDimension({ defaultValue: spaceInfo?.length, dimension: 'length' });
  useUpdateDimension({ defaultValue: spaceInfo?.width, dimension: 'width' });
  useUpdateDimension({ defaultValue: spaceInfo?.height, dimension: 'height' });

  const weightValue = useUpdateWeight({ defaultValue: spaceInfo?.maxWeight });

  const volumeValue = useUpdateVolume();

  return {
    spaceTypeValue,
    volumeValue,
    weightValue,
    currentUnits,
    isCalculationsLoading
  };
};

export default useSpaceFormData;
