import React from 'react';
import sm from './styles.module.scss';

type TProps = {
  linkTxt: string;
  linkPath: string;
};

const LinkButton: React.FC<TProps> = ({ linkTxt, linkPath }) => {
  return (
    <a
      href={linkPath}
      target="_blank"
      rel="noreferrer"
      className={sm.LinkButton}
    >
      {linkTxt}
    </a>
  );
};

export default LinkButton;
