import React, { forwardRef, PropsWithRef, ReactElement } from 'react';
import cn from 'classnames';
import sm from './styles.module.scss';

type ButtonProps = PropsWithRef<{
  onClick?: () => void;
  title?: string;
  variant?: 'primary' | 'outlined' | 'simple' | 'control' | 'linkLike';
  fluid?: boolean;
  disabled?: boolean;
  iconPre?: ReactElement;
  iconAfter?: ReactElement;
  errorMsg?: string;
  infoMsgComponent?: ReactElement | null;
  iconClassname?: string;
  classname?: string;
  textClassname?: string;
}>;

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      onClick,
      title,
      variant = 'primary',
      fluid,
      disabled = false,
      iconPre,
      iconAfter,
      errorMsg,
      infoMsgComponent,
      iconClassname,
      classname,
      textClassname
    },
    ref
  ) => {
    return (
      <button
        ref={ref}
        disabled={disabled}
        type="button"
        onClick={onClick}
        className={cn(
          sm.Button,
          {
            [sm.Button_Primary]: variant === 'primary',
            [sm.Button_Outlined]: variant === 'outlined',
            [sm.Button_Simple]: variant === 'simple',
            [sm.Button_Link]: variant === 'linkLike',
            [sm.Button_Control]: variant === 'control',
            [sm.Button_Fluid]: fluid
          },
          classname
        )}
      >
        <div className={sm.Button_TitleWithIcon}>
          {iconPre && <div className={iconClassname}>{iconPre}</div>}
          {title && (
            <div className={cn(sm.Button_Title, textClassname)}>{title}</div>
          )}
          {iconAfter && <div className={iconClassname}>{iconAfter}</div>}
          {errorMsg && <div className={sm.Button_ErrorMsg}>{errorMsg}</div>}
        </div>
        {infoMsgComponent && (
          <div className={sm.Button_InfoMsg}>{infoMsgComponent}</div>
        )}
      </button>
    );
  }
);

export default Button;
