import React from 'react';
import cn from 'classnames';
import NotFoundPageContent from '../containers/NotFoundPageContent/NotFoundPageContent';
import sm from './styles.module.scss';

const NotFoundPage: React.FC = () => {
  return (
    <div className={cn(sm.Page, sm.Page_404)}>
      <NotFoundPageContent />
    </div>
  );
};

export default NotFoundPage;
