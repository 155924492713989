import React from 'react';
import * as THREE from 'three';
import { TNumberPossition, TNumberSizes } from '../../types';
import CilinderBoxWithEdge from '../CilinderBoxWithEdge/CilinderBoxWithEdge';

interface IProps {
  sizes: TNumberSizes;
  position: TNumberPossition;
  color: string;
  islastItem: boolean;
}

const Cilinder: React.FC<IProps> = ({ position, sizes, color }) => {
  const { width, height: cylindrHeight, length, diametr } = sizes;
  const radius = diametr / 2;
  const height = cylindrHeight || width || length || 0.1;
  const rotated = Math.PI / 2;

  const calcPosition = () => {
    let pos0 = 0;
    let pos1 = 0;
    let pos2 = 0;
    if (cylindrHeight) {
      pos0 = position[0] + radius;
      pos1 = position[1] + cylindrHeight / 2;
      pos2 = position[2] * -1 - radius;
    }
    if (length) {
      pos0 = position[0] + radius;
      pos1 = position[1] + radius;
      pos2 = position[2] * -1 - length / 2;
    }
    if (width) {
      pos0 = position[0] + width / 2;
      pos1 = position[1] + radius;
      pos2 = position[2] * -1 - radius;
    }

    return [pos0, pos1, pos2];
  };

  const vectorThreeArr = [];
  vectorThreeArr.push(
    new THREE.Vector3(calcPosition()[0], calcPosition()[1], calcPosition()[2])
  );

  return (
    <mesh
      position={vectorThreeArr[0]}
      rotation={[length ? rotated : 0, 0, width ? rotated : 0]}
    >
      <CilinderBoxWithEdge
        radius={radius}
        height={height}
        edgeColor="#FFFFFF"
        color={color}
      />

      <lineBasicMaterial attach="material" color={color} transparent />
    </mesh>
  );
};

export default Cilinder;
