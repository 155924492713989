import { AxiosResponse } from 'axios';
import ENDPOINTS from '../constants/endpoints';
import {
  CalculateRequestEntry,
  CalculcationResult,
  FeedbackRequestEntry,
  PredefinedData,
  SendResultsRequestEntry
} from '../types';
import httpRequest from './httpClient';

export const fetchLoadDefaultData: Promise<
  AxiosResponse<PredefinedData>
> = httpRequest({
  url: ENDPOINTS.LOAD_GROUPS.DEFAULT_DATA,
  method: 'GET'
});

export const calculateContainers = ({
  ...containerData
}: CalculateRequestEntry): Promise<AxiosResponse<CalculcationResult>> =>
  httpRequest({
    url: ENDPOINTS.CALCULATE,
    method: 'POST',
    data: containerData,
    timeout: 180000
  });

export const sendResults = ({
  ...formsData
}: SendResultsRequestEntry): Promise<AxiosResponse> =>
  httpRequest({
    url: ENDPOINTS.EXPORT,
    method: 'POST',
    data: formsData
  });

export const sendFeedback = ({
  ...formsData
}: FeedbackRequestEntry): Promise<AxiosResponse> =>
  httpRequest({
    url: ENDPOINTS.FEEDBACK,
    method: 'POST',
    data: formsData
  });
