import React from 'react';
import sm from './styles.module.scss';
import { ReactComponent as Box } from '../../assets/images/boxShadow.svg';

const EmptyLoadsList: React.FC = () => {
  return (
    <div className={sm.EmptyLoadsList}>
      <div className={sm.EmptyLoadsListTitle}>Add a group</div>
      <div className={sm.EmptyLoadsListText}>
        Add a group to calculate space usage capacity
      </div>
      <div className={sm.EmptyLoadsListImage}>
        <Box />
      </div>
    </div>
  );
};

export default EmptyLoadsList;
