import React from 'react';
import { TLangingPageTopDescrCardListData } from '../../types';
import sm from './styles.module.scss';

type TProps = {
  listData: TLangingPageTopDescrCardListData[];
};

const BenefitsCalculatorListItems: React.FC<TProps> = ({ listData }) => {
  return (
    <ul className={sm.BenefitsCalculatorListItems}>
      {listData.map(({ text, Icon }) => (
        <li key={`${text}`} className={sm.BenefitsCalculatorListItems_listItem}>
          <div className={sm.BenefitsCalculatorListItems_iconWrapper}>
            <Icon />{' '}
          </div>
          <p className={sm.BenefitsCalculatorListItems_text}>{text}</p>
        </li>
      ))}
    </ul>
  );
};

export default BenefitsCalculatorListItems;
