import React from 'react';
import { Canvas } from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';
import sm from './styles.module.scss';
import WrapBox from '../WrapBox/WrapBox';
import { TContainerThreeDProps } from '../../types';

const ThreeDMainBox: React.FC<TContainerThreeDProps> = ({
  currentContainer,
  customCalcPosition,
  calculatedContainerSize
}) => {
  return (
    <div className={sm.wrapper_ThreeDMainBox}>
      <Canvas>
        {true && <OrbitControls />}

        <WrapBox
          currentContainer={currentContainer}
          customCalcPosition={customCalcPosition}
          calculatedContainerSize={calculatedContainerSize}
        />
      </Canvas>
    </div>
  );
};

export default ThreeDMainBox;
