import React from 'react';
import cn from 'classnames';
import sm from './styles.module.scss';

interface TotalCounterProps {
  label: string;
  value: number | string;
  units?: string;
  isScroll: boolean;
  shouldDecreaseFont?: boolean;
}

const TotalCounter: React.FC<TotalCounterProps> = ({
  label,
  value,
  units,
  isScroll,
  shouldDecreaseFont
}) => {
  return (
    <div className={sm.TotalCounter}>
      <div
        className={cn(sm.TotalCounter_Label, {
          [sm.TotalCounter_Label__Scrolled]: isScroll,
          [sm.TotalCounter_Label__Decreased]: shouldDecreaseFont
        })}
      >
        {label}
      </div>
      <div className={sm.TotalCounter_Row}>
        <div
          className={cn(sm.TotalCounter_RowValue, {
            [sm.TotalCounter_RowValue__Scrolled]: isScroll,
            [sm.TotalCounter_RowValue__Decreased]: shouldDecreaseFont
          })}
        >
          {value}
        </div>
        {units && (
          <div
            className={cn(sm.TotalCounter_RowUnits, {
              [sm.TotalCounter_RowUnits__Scrolled]: isScroll,
              [sm.TotalCounter_RowUnits__Decreased]: shouldDecreaseFont
            })}
          >
            {units}
          </div>
        )}
      </div>
    </div>
  );
};

export default TotalCounter;
