import { useRecoilState } from 'recoil';
import { unitsState } from '../../../state/unitsState';
import useCalculatedVolume from './useCalculatedVolume';
import useCalculatedPallets from './useCalculatedPallets';
import { UnitsSymbolByName } from '../../../constants/unitsConstants';

interface UseUpdateVolumeProps {
  groupIndex: number;
}

interface UseUpdateVolumeApi {
  volumeValue: number;
  volumeSymbol: string;
  totalVolume: number;
}

const useUpdateVolumeValue = ({
  groupIndex
}: UseUpdateVolumeProps): UseUpdateVolumeApi => {
  const [currentUnits] = useRecoilState(unitsState);

  const { palletsVolume } = useCalculatedPallets({
    groupIndex
  });
  const { volume, totalVolume } = useCalculatedVolume(groupIndex);

  const volumeSymbol = UnitsSymbolByName[currentUnits].volume;

  return {
    volumeValue: volume,
    volumeSymbol,
    totalVolume: totalVolume + palletsVolume
  };
};

export default useUpdateVolumeValue;
