import React from 'react';
import cn from 'classnames';
import { FieldPath, useFormContext } from 'react-hook-form';
import sm from './styles.module.scss';
import { LoadGroupFields } from '../../../../types';

interface RadioProps {
  label: string;
  isSelected: boolean;
  fieldName: FieldPath<LoadGroupFields>;
  defaultValue: string;
}

const RadioField: React.FC<RadioProps> = ({
  label,
  isSelected,
  fieldName,
  ...rest
}) => {
  const { register } = useFormContext();
  return (
    <div className={sm.Radio}>
      <input
        {...rest}
        id={`${fieldName}${label}`}
        type="radio"
        checked={isSelected}
        className={sm.Radio_Input}
        {...register(fieldName)}
      />
      <label
        className={cn(sm.Radio_DefaultLabel, {
          [sm.Radio_Selected]: isSelected
        })}
        htmlFor={`${fieldName}${label}`}
      >
        {label}
      </label>
    </div>
  );
};

export default RadioField;
