import React from 'react';
import Button from '../../components/Button/Button';
import useCreateLoad from '../CreateLoadFormContainer/useCreateLoad';
import { ReactComponent as PlusIcon } from '../../assets/images/icon-plus.svg';
import sm from './styles.module.scss';

interface AddGroupButtonProps {
  isDisabled: boolean;
}

const AddGroupButton: React.FC<AddGroupButtonProps> = ({ isDisabled }) => {
  const { defaultFormValues, insert, loadGroups } = useCreateLoad();
  return (
    <Button
      title="Add group"
      variant="simple"
      fluid
      disabled={isDisabled}
      iconPre={<PlusIcon />}
      classname={sm.AddButton}
      iconClassname={sm.AddButton_Icon}
      onClick={() => insert(loadGroups.length, defaultFormValues)}
    />
  );
};

export default AddGroupButton;
