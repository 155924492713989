import React, { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import sm from './styles.module.scss';
import useFetchData from '../../hooks/useFetchData';
import RadioField from '../SpaceForm/FormFields/RadioField/RadioField';
import useSpaceFormData from '../SpaceForm/hooks/useSpaceFormData';
import getSpaceDataByType from '../../utils/getSpacesDataByType';

import { containerTypeState } from '../../state/containerTypeState';

const SpaceSelection: React.FC = () => {
  const { containerTypes } = useFetchData();
  const { spaceTypeValue } = useSpaceFormData();
  const [, setContainerType] = useRecoilState(containerTypeState);

  useEffect(() => {
    setContainerType(spaceTypeValue);
  }, [spaceTypeValue]);

  return (
    <div className={sm.SpaceSelection}>
      <div className={sm.SpaceSelection_Title}>Space selection</div>
      <div className={sm.SpaceSelection_Content}>
        <div className={sm.SpaceSelection_Containers}>
          {containerTypes.map((container) => (
            <RadioField
              key={container}
              isSelected={spaceTypeValue === container}
              fieldName="space.name"
              defaultValue={`${container}`}
              icon={getSpaceDataByType(container).icon}
              label={getSpaceDataByType(container).label}
            />
          ))}
        </div>
        <div className={sm.SpaceSelection__Image}>
          {spaceTypeValue
            ? getSpaceDataByType(spaceTypeValue).image
            : getSpaceDataByType(containerTypes[0]).image}
        </div>
      </div>
    </div>
  );
};

export default SpaceSelection;
