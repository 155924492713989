import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import Error500Toast from '../../components/Error500Toast/Error500Toast';
import Loader from '../../components/Loader';
import { SendResultsFormFields } from '../../types';
import InputField from './FormFields/InputField';
import sm from './styles.module.scss';
import useResultsForm from './useResultsForm';

interface SendResultsFormProps {
  formMethods: UseFormReturn<SendResultsFormFields>;
}

const SendResultsForm: React.FC<SendResultsFormProps> = ({ formMethods }) => {
  const { handleSubmit, onSubmit, isLoading, isError } = useResultsForm(
    formMethods
  );

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className={sm.SendResultsForm}>
        {isLoading ? (
          <div className={sm.SendResultsForm_Loader}>
            <Loader />
          </div>
        ) : (
          <>
            <InputField
              fieldName="contactInfo.name"
              label="Name"
              defaultValue=""
            />
            <InputField
              fieldName="contactInfo.email"
              label="Email"
              defaultValue=""
            />
            <input
              type="submit"
              value="Send"
              className={sm.SendResultsForm_Button}
            />
          </>
        )}
      </form>
      {isError && <Error500Toast />}
    </>
  );
};

export default SendResultsForm;
