import { yupResolver } from '@hookform/resolvers/yup';
import React, { createContext, useContext } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { useRecoilState } from 'recoil';
import { defaultSpaceFormState } from '../../constants/spaceConstants';
import { containerTypeState } from '../../state/containerTypeState';
import { boxValidation, sizeValidation } from '../../state/data-static';
import { unitsState } from '../../state/unitsState';
import { LoadGroupFields, SpaceFormFields } from '../../types';
import { getDefaultUnitsValue } from '../../utils/getDefaultUnitsValue';
import loadGroupValidationSchema from '../../validation/loadGroupValidationSchema';
import spaceValidationSchema from '../../validation/spaceValidationSchema';

interface GlobalFormMethods {
  loadGroupsFormMethods: UseFormReturn<LoadGroupFields>;
  spaceFormMethods: UseFormReturn<SpaceFormFields>;
}

const FormsContext = createContext({
  loadGroupsFormMethods: {},
  spaceFormMethods: {}
} as GlobalFormMethods);

export const useGlobalFormMethods = (): GlobalFormMethods =>
  useContext(FormsContext);

export const GlobalFormsProvider: React.FC = ({ children }) => {
  const [currentUnits] = useRecoilState(unitsState);
  const [currentContainer] = useRecoilState(containerTypeState);

  const loadGroupsFormMethods = useForm<LoadGroupFields>({
    resolver: yupResolver(
      loadGroupValidationSchema(boxValidation[currentUnits])
    ),
    defaultValues: {
      loadGroups: [],
      measurementSystem: getDefaultUnitsValue()
    }
  });

  const spaceFormMethods = useForm<SpaceFormFields>({
    resolver: yupResolver(
      spaceValidationSchema(sizeValidation[currentContainer][currentUnits])
    ),
    mode: 'onChange',
    reValidateMode: 'onBlur',
    defaultValues: {
      space: defaultSpaceFormState
    }
  });

  return (
    <FormsContext.Provider
      value={{
        loadGroupsFormMethods,
        spaceFormMethods
      }}
    >
      {children}
    </FormsContext.Provider>
  );
};
