import { Euler } from '@react-three/fiber/dist/declarations/src/three-types';
import React, { useMemo } from 'react';
import * as THREE from 'three';

type TProps = {
  isRotate?: boolean;
  vertices: THREE.Vector3[];
  meshPosition: [x: number, y: number, z: number];
};

const Triangle: React.FC<TProps> = ({
  vertices,
  meshPosition,
  isRotate = false
}) => {
  const f32array = useMemo(
    () =>
      Float32Array.from(
        new Array(vertices.length)
          .fill(null)
          .flatMap((item, index) => vertices[index].toArray())
      ),
    [vertices]
  );

  const rotationData: Euler | undefined = [
    isRotate ? (Math.PI / 2) * 2 : 0,
    0,
    0
  ];

  return (
    <mesh position={meshPosition} rotation={rotationData}>
      <bufferGeometry attach="geometry">
        <bufferAttribute
          attachObject={['attributes', 'position']}
          args={[f32array, 3]}
        />
      </bufferGeometry>
      <meshBasicMaterial
        attach="material"
        color="grey"
        wireframe={false}
        side={THREE.DoubleSide}
      />
    </mesh>
  );
};

export default Triangle;
