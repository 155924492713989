import React from 'react';
import { Controller, FieldPath, useFormContext } from 'react-hook-form';
import cn from 'classnames';
import { useRecoilState } from 'recoil';
import MultipleRangeWithValues from '../../../components/MultipleRangeWithValues/MultipleRangeWithValues';
import Tooltip from '../../../components/Tooltip/Tooltip';
import { DimencionTypes, SpaceFormFields } from '../../../types';
import { ReactComponent as InfoIcon } from '../../../assets/images/icon-info.svg';
import sm from './styles.module.scss';
import { unitsState } from '../../../state/unitsState';
import { UnitsSymbolByName } from '../../../constants/unitsConstants';

interface RangesFieldProps {
  name: FieldPath<SpaceFormFields>;
  limitType: DimencionTypes;
  limit: number;
}

const RangesField: React.FC<RangesFieldProps> = ({
  name,
  limitType,
  limit
}) => {
  const { control } = useFormContext<SpaceFormFields>();
  const [currentUnits] = useRecoilState(unitsState);

  return (
    <div className={sm.RangesField}>
      <div className={sm.RangesField_Row}>
        <div
          className={sm.RangesField_Label}
        >{`Usable ${limitType} limit`}</div>

        <InfoIcon className={sm.RangesField_Icon} />

        <Tooltip
          message={
            <div className={sm.RangesField_TooltipText}>
              If the{' '}
              <span
                className={sm.RangesField_Tooltip__TextBold}
              >{`${limitType}`}</span>
              {` is less than the minimum value - warning will be
              displayed. Maximum value represents loss of capacity due to
              packaging or irregular shape.`}
            </div>
          }
          classname={cn(sm.RangesField_Tooltip)}
        />
      </div>
      <Controller
        control={control}
        name={name}
        render={({ field: { value, onChange } }) => (
          <MultipleRangeWithValues
            value={value}
            onChange={(nextValue) => onChange(nextValue)}
            limitSymbol={UnitsSymbolByName[currentUnits][limitType]}
            limit={limit}
          />
        )}
      />
    </div>
  );
};

export default RangesField;
