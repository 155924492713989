import React from 'react';
import * as THREE from 'three';
import { TEdgeBox } from '../../types';

const BoxWithEdge: React.FC<TEdgeBox> = ({ sizes, color, edgeColor }) => {
  const cube = new THREE.BoxBufferGeometry(sizes[0], sizes[1], sizes[2]);

  return (
    <>
      <mesh>
        <lineSegments>
          <edgesGeometry args={[cube]} />
          <lineBasicMaterial attach="material" color={edgeColor} />
        </lineSegments>
      </mesh>
      <mesh>
        <boxGeometry attach="geometry" args={[sizes[0], sizes[1], sizes[2]]} />
        <lineBasicMaterial attach="material" color={color} />
      </mesh>
    </>
  );
};

export default BoxWithEdge;
