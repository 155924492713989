import React from 'react';
import cn from 'classnames';
import TextWithColor from '../../../components/TextWithColor/TextWithColor';

import { getPercentDisplaying } from '../../../utils/getPercentDisplaying';
import sm from './styles.module.scss';
import { useContainerInfo } from '../useContainerInfo';
import numberDisplay from '../../../utils/displayNumber';
import { UnitsSymbolByName } from '../../../constants/unitsConstants';
import { defaultColorsBarState } from '../../../constants/spaceConstants';

interface ContainerLoadsListProps {
  index: number;
}

const ContainerLoadsList: React.FC<ContainerLoadsListProps> = ({ index }) => {
  const {
    setVolumeHoveredColor,
    setWeightHoveredColor,
    volumeHoveredState,
    weightHoveredState,
    itemsInfo,
    currentUnits
  } = useContainerInfo(index);
  return (
    <div className={sm.ContainerLoadsList}>
      <div className={sm.ContainerLoadsList_Head}>
        <div>Group name</div>
        <div>{`Weight, ${UnitsSymbolByName[currentUnits].weight}`}</div>
        <div>{`Volume, ${UnitsSymbolByName[currentUnits].volume}`}</div>
      </div>
      {itemsInfo.map(
        ({
          color,
          name,
          groupVolumePercent,
          groupWeightPercent,
          groupWeight,
          groupVolume,
          description
        }) => {
          const isWeightHovered =
            weightHoveredState.color === color &&
            weightHoveredState.index === index;
          const isVolumeHovered =
            volumeHoveredState.color === color &&
            volumeHoveredState.index === index;

          const isHoveredItem = isWeightHovered || isVolumeHovered;

          return (
            <div className={sm.ContainerLoadsList_ItemContainer} key={color}>
              <div
                className={cn(sm.ContainerLoadsList_Item, {
                  [sm.ContainerLoadsList_Item__Hovered]: isHoveredItem
                })}
                onMouseEnter={() => {
                  setWeightHoveredColor({ color, index });
                  setVolumeHoveredColor({ color, index });
                }}
                onMouseLeave={() => {
                  setWeightHoveredColor(defaultColorsBarState);
                  setVolumeHoveredColor(defaultColorsBarState);
                }}
              >
                <TextWithColor color={color} label={name} />
                <div>{`${numberDisplay({
                  num: groupWeight
                })} • ${getPercentDisplaying(groupWeightPercent)}`}</div>
                <div>{`${numberDisplay({
                  num: groupVolume
                })} • ${getPercentDisplaying(groupVolumePercent)}`}</div>
              </div>
              <div className={sm.ContainerLoadsList_ItemDescription}>
                {description}
              </div>
            </div>
          );
        }
      )}
    </div>
  );
};

export default ContainerLoadsList;
