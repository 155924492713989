import React from 'react';
import cn from 'classnames';
import { UseFormReturn } from 'react-hook-form';
import SpaceOptimization from '../SpaceOptimization/SpaceOptimization';
import sm from './styles.module.scss';
import useSpaceForm from './useSpaceForm';
import { SpaceFormFields } from '../../types';
import SizeSelection from '../SizeSelection/SizeSelection';
import SpaceSelection from '../SpaceSelection/SpaceSelection';
import useFetchData from '../../hooks/useFetchData';
import Skeleton from '../../components/Skeleton/Skeleton';
import Error500Toast from '../../components/Error500Toast/Error500Toast';

interface SpaceFormProps {
  formMethods: UseFormReturn<SpaceFormFields>;
}

const SpaceForm: React.FC<SpaceFormProps> = ({ formMethods }) => {
  const { handleSubmit, onSubmit, disableCalculation, isError } = useSpaceForm(
    formMethods
  );

  const { isLoading } = useFetchData();
  return isLoading ? (
    <Skeleton />
  ) : (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className={sm.SpaceForm}>
        <div className={sm.Row}>
          <div className={cn(sm.Column, sm.SpaceForm_SpaceSection)}>
            <SpaceSelection />
          </div>
          <div className={sm.Column}>
            <SizeSelection />
          </div>
          <div className={sm.Column}>
            <SpaceOptimization />
          </div>
        </div>
        <div className={sm.Row}>
          <input
            type="submit"
            disabled={disableCalculation}
            value="Calculate"
            className={sm.SpaceForm_Button}
          />
        </div>
      </form>
      {isError && <Error500Toast />}
    </>
  );
};

export default SpaceForm;
