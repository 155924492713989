import React, { useCallback, useState } from 'react';
import { useRecoilValue } from 'recoil';
import Button from '../../components/Button/Button';
import ModalThreeDWrapper from '../../components/Modal/ModalThreeDWrapper';
import ThreeDContainer from '../../pages/ThreeDContainer';
import { calculationsState } from '../../state/calculationsState';
import { Placement, TGetSizes, TLinePosition } from '../../types';
import BraketsSvg from '../../ui-elemets/svg-icons/BraketsSvg';
import sm from './styles.module.scss';

type TProps = {
  index: number;
  customCalcPosition: TLinePosition[];
  calculatedContainerSize: TGetSizes;
};

type TWrapper = {
  isBig: boolean;
};

const Container3DVizualSmall: React.FC<TProps> = ({
  index,

  customCalcPosition,
  calculatedContainerSize
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [isModalHidden, setIsModalHidden] = useState<boolean>(true);
  const currentContainer: Placement = useRecoilValue(calculationsState)
    .placements[index];

  const Wrapper: React.FC<TWrapper> = React.memo(({ isBig }) => {
    return (
      <ThreeDContainer
        index={index}
        isBig={isBig}
        currentContainer={currentContainer}
        customCalcPosition={customCalcPosition}
        calculatedContainerSize={calculatedContainerSize}
      />
    );
  });

  const showModalBtnHeadler = () => {
    if (!open) {
      setOpen(true);
      setIsModalHidden(false);
    } else {
      setIsModalHidden(!isModalHidden);
    }
  };
  const StartWrapper = useCallback(() => {
    return <Wrapper isBig={false} />;
  }, []);

  return (
    <div className={sm.Container3DVizualSmall}>
      <StartWrapper />
      <Button
        classname={sm.callModalBtn}
        iconPre={<BraketsSvg />}
        onClick={showModalBtnHeadler}
      />
      {open && (
        <div style={{ display: `${isModalHidden ? 'none' : 'block'}  ` }}>
          <ModalThreeDWrapper
            setOpen={showModalBtnHeadler}
            modalClass={sm.bigThreeDModal}
            component={<Wrapper isBig={!false} />}
          />
        </div>
      )}
    </div>
  );
};

export default Container3DVizualSmall;
