import React from 'react';
import { useHistory } from 'react-router-dom';
import sm from './styles.module.scss';

import { ReactComponent as TextSvg } from '../../assets/images/404text.svg';
import { ReactComponent as ImageSvg } from '../../assets/images/404image.svg';
import Button from '../../components/Button/Button';
import { Paths } from '../../constants/paths';

const NotFoundPageContent: React.FC = () => {
  const history = useHistory();
  return (
    <div className={sm.NotFoundPageContent}>
      <TextSvg />

      <div className={sm.NotFoundPageContent_Text}>Page not found</div>

      <Button
        title="Go to main page"
        onClick={() => history.push(Paths.Home)}
        classname={sm.NotFoundPageContent_Button}
      />

      <ImageSvg className={sm.NotFoundPageContent_Image} />
    </div>
  );
};

export default NotFoundPageContent;
