import { useFormContext } from 'react-hook-form';
import { useRecoilState } from 'recoil';
import { unitsState } from '../state/unitsState';
import { LoadGroupFields, Units } from '../types';
import { defaultUnitsState } from '../utils/getDefaultUnitsValue';

interface UseShowFeedbackFormApi {
  handleSetUnits: (units: Units) => void;
}

export const useUnitsState = (): UseShowFeedbackFormApi => {
  const [, setUnitsState] = useRecoilState(unitsState);
  const { setValue } = useFormContext<LoadGroupFields>();
  const fieldName = 'measurementSystem';

  const handleSetUnits = (units: Units) => {
    localStorage.setItem('units', `${units}`);

    setUnitsState(
      (localStorage.getItem('units') || defaultUnitsState) as Units
    );
    setValue(
      fieldName,
      (localStorage.getItem('units') || defaultUnitsState) as Units
    );
  };

  return {
    handleSetUnits
  };
};
