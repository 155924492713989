import React from 'react';
import sm from './styles.module.scss';
import { ReactComponent as Logo } from '../../assets/images/logo.svg';

const Header: React.FC = () => {
  return (
    <div className={sm.Header}>
      <Logo />
    </div>
  );
};

export default Header;
