import React, { forwardRef, InputHTMLAttributes } from 'react';
import cn from 'classnames';
import sm from './styles.module.scss';

interface Props extends InputHTMLAttributes<HTMLTextAreaElement> {
  error?: boolean;
  classname: string;
}

export type TextAreaProps = Props;

const TextArea: React.FC<Props> = forwardRef<
  HTMLTextAreaElement,
  TextAreaProps
>(({ error, classname, ...rest }, ref) => {
  return (
    <div className={sm.TextArea}>
      <textarea
        {...rest}
        className={cn(sm.TextArea_Native, classname, {
          [sm.TextArea_Native__Error]: error
        })}
        ref={ref}
      />
    </div>
  );
});

export default TextArea;
