import React from 'react';
import * as THREE from 'three';

type TProps = {
  radius: number;
  height: number;
  color: string;
};

const CilinderEdgedMesh: React.FC<TProps> = ({ radius, height, color }) => {
  const cube = new THREE.CylinderBufferGeometry(radius, 0, 0, 32);
  return (
    <mesh position={[0, height, 0]}>
      <lineSegments>
        <edgesGeometry args={[cube]} />
        <lineBasicMaterial attach="material" color={color} />
      </lineSegments>
    </mesh>
  );
};

export default CilinderEdgedMesh;
