import { useRecoilState } from 'recoil';
import useFetchData from '../../../hooks/useFetchData';
import { unitsState } from '../../../state/unitsState';
import { getTotalVolumeCalculation } from '../utils/getTotalVolumeCalculation';
import { useGlobalFormMethods } from '../../GlobalFormsProvider/GlobalFormsProvider';
import { getTotalWeightCalculation } from '../utils/getTotalWeightCalculation';

interface UseUpdateTotalGroupsDataProps {
  groupFieldNamesArr: `loadGroups.${number}`[];
}

interface UseUpdateTotalGroupsDataApi {
  totalLoadGroupsVolume: number;
  totalLoadGroupsWeight: number;
}

const useUpdateTotalLoadGroupsData = ({
  groupFieldNamesArr
}: UseUpdateTotalGroupsDataProps): UseUpdateTotalGroupsDataApi => {
  const { loadGroupsFormMethods } = useGlobalFormMethods();
  const { watch } = loadGroupsFormMethods;
  const { palletsData } = useFetchData();
  const [currentUnits] = useRecoilState(unitsState);

  const totalLoadGroupsVolume = groupFieldNamesArr
    .map((groupFieldName) => {
      const { totalVolume } = getTotalVolumeCalculation({
        groupFieldName,
        watch,
        currentUnits,
        palletsData
      });
      return totalVolume;
    })
    .reduce((prev, curr) => prev + curr, 0);

  const totalLoadGroupsWeight = groupFieldNamesArr
    .map((groupFieldName) => {
      const totalWeight = getTotalWeightCalculation({
        watch,
        groupFieldName,
        palletsData
      });
      return totalWeight;
    })
    .reduce((prev, curr) => prev + curr, 0);

  return {
    totalLoadGroupsWeight,
    totalLoadGroupsVolume
  };
};
export default useUpdateTotalLoadGroupsData;
