import { useQuery } from 'react-query';
import { useRecoilState } from 'recoil';
import { AxiosError } from 'axios';
import { fetchLoadDefaultData } from '../network/services';
import {
  Container,
  LoadGroup,
  LoadPredefinedData,
  LoadTypeValue,
  PalletsData,
  PredefinedData,
  Spaces
} from '../types';
import { unitsState } from '../state/unitsState';
import {
  LoadType,
  mattressData,
  refrigeratorData
} from '../constants/loadGroupsConstants';
import {
  spaceLimitDefault,
  weightLimitDefault
} from '../constants/spaceConstants';

export interface UseFetchDataApi {
  loadGroupsData: PredefinedData;
  colorsList: string[];
  metricData: LoadPredefinedData;
  imperialData: LoadPredefinedData;
  palletsData: PalletsData;
  loadTypes: LoadTypeValue[];
  loadGroupsDefaultFormValues: LoadGroup;
  containerTypes: Spaces[];
  spaceFormValues: Container;
  isLoading: boolean;
  isError: boolean;
  moveOptionDefaultFormData: LoadGroup[];
}

const defaultPredefinedData: PredefinedData = {
  groupColors: [],
  metric: {
    boxes: [],
    totes: [],
    drums: [],
    custom: [],
    pallets: [],
    containers: []
  },
  imperial: {
    boxes: [],
    totes: [],
    drums: [],
    custom: [],
    pallets: [],
    containers: []
  }
};

const useFetchData = (): UseFetchDataApi => {
  const {
    data = defaultPredefinedData,
    isLoading,
    error
  } = useQuery('predefinedData', () =>
    fetchLoadDefaultData.then((res) => res.data)
  );

  const responseError = error as AxiosError;
  const errorStatus = responseError?.response?.status;
  const isError = (errorStatus && errorStatus >= 500) || false;

  const [units] = useRecoilState(unitsState);

  const palletsData = data[units].pallets[0] as PalletsData;

  const loadTypes =
    data &&
    (Object.keys(data[units])
      .filter((key) =>
        ([LoadType.Boxes, LoadType.Drums, LoadType.Totes] as string[]).includes(
          key
        )
      )
      .map((key) => key) as LoadTypeValue[]);

  const containerTypes = data && data[units].containers.map(({ name }) => name);

  const loadGroupsDefaultFormValues: LoadGroup = {
    name: '',
    quantity: 1,
    pallets: 0,
    loadType: loadTypes && loadTypes[0],
    description: '',
    height: data[units].boxes[0]?.height ?? 0,
    width: data[units].boxes[0]?.width ?? 0,
    length: data[units].boxes[0]?.length ?? 0,
    weight: data[units].boxes[0]?.weight ?? 0,
    size: data[units].boxes[0]?.name
  };

  const moveOptionDefaultFormData: LoadGroup[] = [
    {
      name: '',
      quantity: 200,
      pallets: 0,
      loadType: loadTypes && loadTypes[0],
      description: '',
      height: data[units].boxes[1]?.height ?? 0,
      width: data[units].boxes[1]?.width ?? 0,
      length: data[units].boxes[1]?.length ?? 0,
      weight: data[units].boxes[1]?.weight ?? 0,
      size: data[units].boxes[1]?.name
    },
    {
      name: 'Refrigerator',
      quantity: 1,
      pallets: 0,
      loadType: 'custom',
      description: '',
      height: refrigeratorData[units].height,
      width: refrigeratorData[units].width,
      length: refrigeratorData[units].length,
      weight: refrigeratorData[units].weight,
      size: 'Custom'
    },
    {
      name: 'Mattress',
      quantity: 1,
      pallets: 0,
      loadType: 'custom',
      description: '',
      height: mattressData[units].height,
      width: mattressData[units].width,
      length: mattressData[units].length,
      weight: mattressData[units].weight,
      size: 'Custom'
    }
  ];

  const spaceFormValues: Container = {
    name: containerTypes[0],
    length: data[units].containers[0]?.length,
    width: data[units].containers[0]?.width,
    height: data[units].containers[0]?.height,
    maxWeight: data[units].containers[0]?.maxWeight,
    volume: 0,
    spaceLimit: data[units].containers[0]?.spaceLimit || spaceLimitDefault,
    weightLimit: data[units].containers[0]?.weightLimit || weightLimitDefault
  };

  return {
    isLoading,
    containerTypes: [...containerTypes, 'Custom'],
    loadGroupsData: data,
    colorsList: data.groupColors,
    metricData: data.metric,
    imperialData: data.imperial,
    palletsData,
    loadTypes: [...loadTypes, LoadType.Custom],
    loadGroupsDefaultFormValues,
    spaceFormValues,
    isError,
    moveOptionDefaultFormData
  };
};

export default useFetchData;
