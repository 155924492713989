export const getWarningMessageText = ({
  volume,
  weight
}: {
  volume: { percent: number; min: number };
  weight: { percent: number; min: number };
}): string => {
  if (weight.percent < weight.min && volume.percent < volume.min) {
    return `Less than ${volume.min}% of volume and ${weight.min}% of weight is filled`;
  }
  if (volume.percent < volume.min) {
    return `Less than ${volume.min}% of volume is filled`;
  }
  return '';
};
