import { useLayoutEffect, useState } from 'react';
import { ScreenSizesOptions } from '../../../constants';
import { ScreenSizes } from '../../../types';

export const useHomePageScreenHeight = (): ScreenSizes => {
  const [screenSize, setScreenSize] = useState<ScreenSizes>(
    ScreenSizesOptions.Default
  );
  useLayoutEffect(() => {
    if (document.body.clientHeight > 864 && document.body.clientHeight < 960) {
      setScreenSize(ScreenSizesOptions.Default);
    }
    if (document.body.clientHeight < 864) {
      setScreenSize(ScreenSizesOptions.Small);
    }
    if (document.body.clientHeight > 960) {
      setScreenSize(ScreenSizesOptions.Big);
    }
  }, [document.body.clientHeight]);

  return screenSize;
};
