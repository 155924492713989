import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import camelize from 'camelcase-keys';
import qs from 'qs';
import decamelize from 'snakecase-keys';

const baseURL = process.env.REACT_APP_API_BASE;

export default function httpRequest<SuccessPayload>(
  config: AxiosRequestConfig
): Promise<AxiosResponse<SuccessPayload>> {
  return httpClient.request<SuccessPayload>(config);
}

const httpClient = axios.create({
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  },
  baseURL,
  transformRequest,
  transformResponse,
  paramsSerializer
});

function transformRequest(data: Record<string, unknown>) {
  if (!data) {
    return data;
  }

  if (data instanceof FormData) {
    return data;
  }

  return JSON.stringify(decamelize(data, { deep: true }));
}

function transformResponse(data: string) {
  try {
    return camelize(JSON.parse(data), { deep: true });
  } catch (e) {
    return data;
  }
}

function paramsSerializer(params: Record<string, unknown>) {
  return qs.stringify(decamelize(params, { deep: true }), {
    arrayFormat: 'brackets'
  });
}
