import React, { ReactElement } from 'react';
import cn from 'classnames';
import sm from './styles.module.scss';

interface TooltipProps {
  message: ReactElement;
  classname: string;
}

const Tooltip: React.FC<TooltipProps> = ({ message, classname }) => {
  return <div className={cn(sm.Tooltip, classname)}>{message}</div>;
};

export default Tooltip;
