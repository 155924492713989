import React, { InputHTMLAttributes } from 'react';
import { FieldPath, useFormContext } from 'react-hook-form';
import cn from 'classnames';
import { LoadGroupFields } from '../../../../types';
import Input from '../../../../components/Input/Input';
import sm from '../styles.module.scss';
import setValueAsDecimal from '../../utils/setValueAsDecimal';
import useFieldError from '../../../../hooks/useFieldError';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  fieldName: FieldPath<LoadGroupFields>;
  label: string;
  defaultValue: string;
  textAfter?: string;
}

export type IputFieldProps = Props;

const InputField: React.FC<Props> = ({
  fieldName,
  label,
  defaultValue,
  textAfter,
  ...rest
}) => {
  const { watch, register } = useFormContext();

  const error = useFieldError(fieldName);
  return (
    <div className={sm.Field_InputDefault}>
      <label htmlFor={fieldName} className={sm.Field_Label}>
        {`${label}, ${textAfter}`}
      </label>
      <Input
        {...rest}
        classname={cn(sm.Field, sm.Field_WidthDefault, {
          [sm.Field__Error]: !!error
        })}
        defaultValue={defaultValue}
        {...register(fieldName, {
          setValueAs: (value) =>
            setValueAsDecimal({
              value,
              fieldName,
              watch,
              integerPartLength: 5
            })
        })}
      />
    </div>
  );
};

export default InputField;
