import React from 'react';
import { TGetSizes, TVectorOrNumArr } from '../../types';
import SizeLine from '../SizeLine/SizeLine';

type TProps = {
  width: number;
  height: number;
  length: number;
  calculatedContainerSize: TGetSizes;
  findMaxOfBiggesCoeff: number;
};
const SizeLinesOfWrapperLines: React.FC<TProps> = ({
  width,
  height,
  length,
  calculatedContainerSize,
  findMaxOfBiggesCoeff
}) => {
  const { Gwidth, Gheight, Glength } = calculatedContainerSize;
  const lineTextPositionStep = 0.2 * findMaxOfBiggesCoeff;
  const widthPointLine: TVectorOrNumArr = [
    [0, 0, -lineTextPositionStep],
    [0, 0, -Gwidth + lineTextPositionStep]
  ];
  const heightPointLine: TVectorOrNumArr = [
    [0, 0, -lineTextPositionStep],
    [0, 0, -Gheight + lineTextPositionStep]
  ];
  const widthPointLineRight: TVectorOrNumArr = [
    [0, 0, -lineTextPositionStep],
    [0, 0, -Glength + lineTextPositionStep]
  ];

  return (
    <>
      <SizeLine
        position={[Glength + lineTextPositionStep, 0, 0]}
        rotate={[0, 0, 0]}
        points={widthPointLine}
        lineText={width.toString()}
        lineTextPosition={[lineTextPositionStep, 0, -Gwidth / 2]}
        lineTextRotation={[-1.6, 0, 1.55]}
        triangleEndPosition={Gwidth - lineTextPositionStep}
        triangleStartPosition={lineTextPositionStep}
        lineWidth={2}
        findMaxOfBiggesCoeff={findMaxOfBiggesCoeff}
      />

      <SizeLine
        position={[Glength + lineTextPositionStep, Gheight, 0]}
        rotate={[-Math.PI / 2, 0, 0]}
        points={heightPointLine}
        lineText={height.toString()}
        lineTextPosition={[lineTextPositionStep, 0, -Gheight / 2]}
        lineTextRotation={[-1.6, 0, 1.55]}
        triangleEndPosition={Gheight - lineTextPositionStep}
        triangleStartPosition={lineTextPositionStep}
        lineWidth={2}
        findMaxOfBiggesCoeff={findMaxOfBiggesCoeff}
      />
      <SizeLine
        position={[Glength, 0, -Gwidth - lineTextPositionStep]}
        rotate={[0, Math.PI / 2, 0]}
        points={widthPointLineRight}
        lineText={length.toString()}
        lineTextPosition={[lineTextPositionStep, 0, -Glength / 2]}
        lineTextRotation={[-1.6, 0, 1.55]}
        triangleEndPosition={Glength - lineTextPositionStep}
        triangleStartPosition={lineTextPositionStep}
        lineWidth={2}
        findMaxOfBiggesCoeff={findMaxOfBiggesCoeff}
      />
    </>
  );
};

export default SizeLinesOfWrapperLines;
