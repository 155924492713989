import { useEffect } from 'react';
import { UseFormReturn, UseFormHandleSubmit } from 'react-hook-form';
import { useRecoilState } from 'recoil';
import { contactInfoDefault } from '../../constants';
import { useSendResultsMutation } from '../../hooks/useSendResultsMutation';
import { resultsSentState } from '../../state/calculationResultsState';
import { calculationsState } from '../../state/calculationsState';
import { contactInfoState } from '../../state/contactInfoState';

import { SendResultsFormFields } from '../../types';

interface UseResultsFormApi {
  onSubmit: (data: SendResultsFormFields) => void;
  handleSubmit: UseFormHandleSubmit<SendResultsFormFields>;
  isLoading: boolean;
  isError: boolean;
}

const useResultsForm = (
  methods: UseFormReturn<SendResultsFormFields>
): UseResultsFormApi => {
  const { handleSubmit, reset } = methods;
  const { mutate, isLoading, isError } = useSendResultsMutation();
  const [calculationResult] = useRecoilState(calculationsState);
  const [, setContactInfo] = useRecoilState(contactInfoState);
  const [isSent] = useRecoilState(resultsSentState);

  const onSubmit = async (data: SendResultsFormFields) => {
    mutate({
      ...calculationResult,
      ...data.contactInfo,
      date: new Date()
    });
    setContactInfo(data.contactInfo);
  };

  useEffect(() => {
    if (isSent) {
      reset({ contactInfo: contactInfoDefault });
    }
  }, [isSent]);

  return {
    handleSubmit,
    onSubmit,
    isLoading,
    isError
  };
};

export default useResultsForm;
