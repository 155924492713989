import React, { InputHTMLAttributes } from 'react';
import { FieldPath, useFormContext } from 'react-hook-form';
import cn from 'classnames';
import { LoadGroupFields } from '../../../../types';
import TextArea from '../../../../components/TextArea/TextArea';
import sm from '../styles.module.scss';
import useFieldError from '../../../../hooks/useFieldError';

interface Props extends InputHTMLAttributes<HTMLTextAreaElement> {
  fieldName: FieldPath<LoadGroupFields>;
  label: string;
  defaultValue: string;
}

export type TextAreaFieldProps = Props;

const TextAreaField: React.FC<Props> = ({
  fieldName,
  label,
  defaultValue,
  ...rest
}) => {
  const { register } = useFormContext();
  const error = useFieldError(fieldName);
  return (
    <div>
      <label htmlFor={fieldName} className={sm.Field_Label}>
        {label}
      </label>
      <TextArea
        {...rest}
        classname={cn(sm.Field, {
          [sm.Field__Error]: !!error
        })}
        defaultValue={defaultValue}
        {...register(fieldName)}
      />
    </div>
  );
};

export default TextAreaField;
