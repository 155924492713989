import React from 'react';
import { useFormContext } from 'react-hook-form';
import cn from 'classnames';
import { useRecoilState } from 'recoil';
import Select from '../../components/Select/Select';
import { LoadGroupFields, Units } from '../../types';
import sm from './styles.module.scss';
import CountersRow from './CountersRow/CountersRow';
import { scrollState } from '../../state/scrollState';
import { homePageState } from '../../state/homePageState';
import getDataByHomePageSelection from '../../utils/getDataByHomePageSelection';
import { useUnitsState } from '../../hooks/useUnitsState';
import { unitsState } from '../../state/unitsState';
import { UnitsOptions } from '../../constants/unitsConstants';

interface CreateLoadBarProps {
  totalVolume: number;
  totalWeight: number;
}

const CreateLoadBar: React.FC<CreateLoadBarProps> = ({
  totalVolume,
  totalWeight
}) => {
  const { getValues } = useFormContext<LoadGroupFields>();
  const { loadGroups } = getValues();
  const [isScroll] = useRecoilState(scrollState);
  const [currentUnits] = useRecoilState(unitsState);
  const { handleSetUnits } = useUnitsState();
  const [homePageSelection] = useRecoilState(homePageState);

  return (
    <div
      className={cn(sm.CreateLoadBar, {
        [sm.CreateLoadBar__Scrolled]: isScroll
      })}
    >
      <div
        className={cn(sm.CreateLoadBar_Content, {
          [sm.CreateLoadBar_Content__Scrolled]: isScroll
        })}
      >
        <div
          className={cn(sm.CreateLoadBar_Title, {
            [sm.CreateLoadBar_Title__Scrolled]: isScroll
          })}
        >
          {homePageSelection &&
            getDataByHomePageSelection(homePageSelection).label}
        </div>

        <CountersRow totalVolume={totalVolume} totalWeight={totalWeight} />

        <div className={sm.CreateLoadBar_ButtonSection}>
          {!isScroll && (
            <Select
              label="Units"
              handleChange={(val) => handleSetUnits(val as Units)}
              options={UnitsOptions}
              value={currentUnits}
            />
          )}

          <div>
            <input
              type="submit"
              value="Next"
              disabled={!loadGroups.length}
              className={sm.CreateLoadBar_Button}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateLoadBar;
