import React, { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { FormProvider } from 'react-hook-form';

import { useRecoilState } from 'recoil';
import sm from './styles.module.scss';

import Toast from '../components/Toast/Toast';
import useCreateLoad from '../containers/CreateLoadFormContainer/useCreateLoad';
import CreateLoadFormContainer from '../containers/CreateLoadFormContainer/CreateLoadFormContainer';
import useScrollPosition from '../hooks/useScrollPosition';
import useFetchData from '../hooks/useFetchData';
import Error500 from '../components/Error500/Error500';
import { useRouteGuard } from '../hooks/useRouteGuard';
import { scrollState } from '../state/scrollState';
import { useGlobalFormMethods } from '../containers/GlobalFormsProvider/GlobalFormsProvider';
import ToastesWithCloseBoxSelection from '../components/ToastesWithClose/ToastesWithClose';
import { TMes } from '../types';

const LoadGroupsPage: React.FC = () => {
  useScrollPosition();
  useRouteGuard();

  const [isScroll] = useRecoilState(scrollState);
  const { loadGroupsFormMethods } = useGlobalFormMethods();
  const { isError } = useFetchData();
  const [stateErrorWithToast, setStateErrorWithToast] = useState<boolean>(
    false
  );
  const [
    stateErrorWithToastWithSize,
    setStateErrorWithToastWithSize
  ] = useState<TMes[] | null>(null);

  const {
    errorTextFirstPart,
    shouldDisplayErrorToast,
    loadGroups,
    shouldDisplayErrorToastWithSize
  } = useCreateLoad();

  const {
    formState: { isSubmitting }
  } = loadGroupsFormMethods;

  const pageref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setStateErrorWithToast(shouldDisplayErrorToast);
    setStateErrorWithToastWithSize(shouldDisplayErrorToastWithSize);
  }, [isSubmitting]);

  if (isError) {
    return <Error500 />;
  }

  return (
    <FormProvider {...loadGroupsFormMethods}>
      <div
        ref={pageref}
        className={cn(sm.Page, sm.Page_LoadGroups, {
          [sm.Page_Home__Empty]: loadGroups.length === 0
        })}
      >
        <CreateLoadFormContainer />

        {stateErrorWithToast && (
          <Toast
            title={`${errorTextFirstPart} require additional details`}
            subtitle="Fill in with valid value"
            positionClassname={cn({
              [sm.Page_LoadGroups__Toast]: !isScroll,
              [sm.Page_LoadGroups__ToastScrolled]: isScroll
            })}
          />
        )}
        {!!stateErrorWithToastWithSize &&
          stateErrorWithToastWithSize.length > 0 && (
            <ToastesWithCloseBoxSelection
              data={stateErrorWithToastWithSize}
              isScroll={isScroll}
            />
          )}
      </div>
    </FormProvider>
  );
};

export default LoadGroupsPage;
