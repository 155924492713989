import React from 'react';
import { TEdgeCylindr } from '../../types';
import CilinderEdgedMesh from '../CilinderEdgedMesh/CilinderEdgedMesh';

const CilinderBoxWithEdge: React.FC<TEdgeCylindr> = ({
  radius,
  color,
  height,
  edgeColor
}) => {
  return (
    <>
      <CilinderEdgedMesh
        radius={radius}
        height={height / 2}
        color={edgeColor}
      />
      <CilinderEdgedMesh
        radius={radius}
        height={-height / 2}
        color={edgeColor}
      />
      <mesh>
        <cylinderBufferGeometry
          attach="geometry"
          args={[radius, radius, height, 32]}
        />
        <lineBasicMaterial attach="material" color={color} />
      </mesh>
    </>
  );
};

export default CilinderBoxWithEdge;
