import { UnitsValues } from '../constants/unitsConstants';
import { Units } from '../types';

export const defaultUnitsState =
  navigator.language.toLowerCase() === 'en-US'.toLowerCase()
    ? UnitsValues.imperial
    : UnitsValues.metric;

export const getDefaultUnitsValue = (): Units => {
  const storedValue = localStorage.getItem('units');

  if (
    storedValue === UnitsValues.imperial ||
    storedValue === UnitsValues.metric
  ) {
    return storedValue;
  }
  localStorage.removeItem('units');
  return defaultUnitsState;
};
