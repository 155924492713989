import React from 'react';
import Button from '../Button/Button';
import sm from './styles.module.scss';
import { ReactComponent as DeleteIcon } from '../../assets/images/icon-delete.svg';
import DeletePopup from '../DeletePopup/DeletePopup';

interface DeleteButtonProps {
  handleDeleteItem: () => void;
  isPopupOpen: boolean;
  handlePopupOpen: () => void;
  handlePopupClose: () => void;
}

const DeleteButton: React.FC<DeleteButtonProps> = ({
  isPopupOpen,
  handlePopupOpen,
  handlePopupClose,
  handleDeleteItem
}) => {
  return (
    <div className={sm.Delete_Button}>
      <Button
        iconPre={<DeleteIcon />}
        iconClassname={sm.Delete_Icon}
        variant="linkLike"
        onClick={handlePopupOpen}
      />
      {isPopupOpen && (
        <DeletePopup
          handlePopupClose={handlePopupClose}
          onClick={handleDeleteItem}
        />
      )}
    </div>
  );
};

export default DeleteButton;
