import {
  TGetSizes,
  TLinePosition,
  TNumberPossition,
  TVectorOrNumArr
} from '../types';

interface ICusomeLinePosionWithSizes {
  customLinePositions: TLinePosition[];
  getedSizes: TGetSizes;
}

const caclCustomLinePosition = (
  width: number,
  height: number,
  length: number
): ICusomeLinePosionWithSizes => {
  const getedSizes: TGetSizes = {
    Gwidth: width,
    Gheight: height,
    Glength: length
  };

  const startCorner: TNumberPossition = [0, 0, 0];
  const pointsSjhortTopAnfBottomLines: TVectorOrNumArr = [
    startCorner,
    [0, 0, -getedSizes.Gwidth]
  ];
  const pointsSjhortRightAndLeftLongLines: TVectorOrNumArr = [
    startCorner,
    [getedSizes.Glength, 0, 0]
  ];
  const pointsSjhortRightAndLeftShortLines: TVectorOrNumArr = [
    startCorner,
    [0, getedSizes.Gheight, 0]
  ];
  const customLinePositions: TLinePosition[] = [
    { position: startCorner, points: [...pointsSjhortTopAnfBottomLines] },
    {
      position: [0, getedSizes.Gheight, 0],
      points: [...pointsSjhortTopAnfBottomLines]
    },
    {
      position: [getedSizes.Glength, 0, 0],
      points: [...pointsSjhortTopAnfBottomLines]
    },
    {
      position: [getedSizes.Glength, getedSizes.Gheight, 0],
      points: [...pointsSjhortTopAnfBottomLines]
    },
    { position: startCorner, points: [...pointsSjhortRightAndLeftLongLines] },
    {
      position: [0, getedSizes.Gheight, 0],
      points: [...pointsSjhortRightAndLeftLongLines]
    },
    {
      position: [0, 0, -getedSizes.Gwidth],
      points: [...pointsSjhortRightAndLeftLongLines]
    },
    {
      position: [0, getedSizes.Gheight, -getedSizes.Gwidth],
      points: [...pointsSjhortRightAndLeftLongLines]
    },
    { position: startCorner, points: [...pointsSjhortRightAndLeftShortLines] },
    {
      position: [getedSizes.Glength, 0, 0],
      points: [...pointsSjhortRightAndLeftShortLines]
    },
    {
      position: [0, 0, -getedSizes.Gwidth],
      points: [...pointsSjhortRightAndLeftShortLines]
    },
    {
      position: [getedSizes.Glength, 0, -getedSizes.Gwidth],
      points: [...pointsSjhortRightAndLeftShortLines]
    }
  ];
  return { customLinePositions, getedSizes };
};

export default caclCustomLinePosition;
