import { atom } from 'recoil';

export const resultsSentState = atom<boolean>({
  key: 'resultsSentState',
  default: false
});

export const resultsErrorState = atom<boolean>({
  key: 'resultsErrorState',
  default: false
});
