import React from 'react';
import ModalWrapper from './ModalWrapper';
import sm from './styles.module.scss';

type TProps = {
  setOpen: (value: React.SetStateAction<boolean>) => void;
  component: React.ReactElement;
  modalClass: string;
};

const ModalThreeDWrapper: React.FC<TProps> = ({
  setOpen,
  component,
  modalClass
}) => {
  return (
    <div className={sm.ThreeDModalStyleContainer}>
      <ModalWrapper
        isThreeDModal={!false}
        content={component}
        setOpen={setOpen}
        modalClass={modalClass}
      />
    </div>
  );
};

export default ModalThreeDWrapper;
