import React from 'react';
import sm from './styles.module.scss';
import formStyles from '../../styles.module.scss';
import InputWithControls from '../../FormFields/InputWithControls/InputWithControls';
import NameField from '../../FormFields/NameField/NameField';
import useFormData from '../../hooks/useFormData';
import { LoadGroup } from '../../../../types';
import DeleteButton from '../../../../components/DeleteButton/DeleteButton';
import { useDeleteLoadGroup } from '../../hooks/useDeleteLoadGroup';

interface FirstLineFormSectionProps {
  loadGroup: LoadGroup;
  color: string;
  groupIndex: number;
}

const FirstLineFormSection: React.FC<FirstLineFormSectionProps> = ({
  color,
  loadGroup,
  groupIndex
}) => {
  const {
    volumeSymbol,
    totalVolume,
    totalWeight,
    weightSymbol,
    handleAdd,
    handleRemove
  } = useFormData({
    groupIndex
  });
  const groupFieldName = `loadGroups.${groupIndex as 0}` as const;

  const {
    openedPopupIndex,
    handleDeleteItem,
    handlePopupOpen,
    handleCloseAnyPopup
  } = useDeleteLoadGroup();

  const quantityField = `${groupFieldName}.quantity` as const;
  const palletsField = `${groupFieldName}.pallets` as const;
  return (
    <div className={sm.FirstLineFormSection}>
      <div className={sm.FirstLineFormSection_Column}>
        <NameField
          defaultValue=""
          fieldName={`${groupFieldName}.name` as const}
          placeholder="Add group name"
          label="Name"
          color={color}
        />
      </div>

      <div className={sm.FirstLineFormSection_Content}>
        <div className={sm.FirstLineFormSection_Row}>
          <InputWithControls
            defaultValue={`${loadGroup.quantity}`}
            fieldName={`${groupFieldName}.quantity` as const}
            label="Quantity"
            handleRightControl={() => handleAdd(quantityField)}
            handleLeftControl={() => handleRemove(quantityField)}
          />

          <InputWithControls
            defaultValue={`${loadGroup.pallets}`}
            fieldName={`${groupFieldName}.pallets` as const}
            label="Add Pallet"
            handleRightControl={() => handleAdd(palletsField)}
            handleLeftControl={() => handleRemove(palletsField)}
          />

          <div className={sm.FirstLineFormSection_Weight}>
            <div
              className={formStyles.CreateLoadGroupItem_Label}
            >{`Total weight, ${weightSymbol}`}</div>
            <div className={formStyles.CreateLoadGroupItem_Value}>
              {totalWeight}
            </div>
          </div>
        </div>

        <div>
          <div
            className={formStyles.CreateLoadGroupItem_Label}
          >{`Total volume, ${volumeSymbol}`}</div>
          <div className={formStyles.CreateLoadGroupItem_Value}>
            {totalVolume}
          </div>
        </div>

        <div className={sm.FirstLineFormSection_Icon}>
          <DeleteButton
            isPopupOpen={openedPopupIndex === groupIndex}
            handlePopupClose={handleCloseAnyPopup}
            handleDeleteItem={() => handleDeleteItem(groupIndex)}
            handlePopupOpen={() => handlePopupOpen(groupIndex)}
          />
        </div>
      </div>
    </div>
  );
};

export default FirstLineFormSection;
