import React, { ReactElement } from 'react';
import { useRecoilState } from 'recoil';
import { modalOpenState } from '../../state/modalState';
import { resultsSentState } from '../../state/calculationResultsState';
import ModalWrapper from './ModalWrapper';

interface ModalProps {
  isOpen: boolean;
  content: ReactElement;
  title: string;
  subtitle: string;
}

const Modal: React.FC<ModalProps> = ({ content, title, subtitle }) => {
  const [isOpen, setModalOpen] = useRecoilState(modalOpenState);
  const [, setIsSent] = useRecoilState(resultsSentState);
  return isOpen ? (
    <ModalWrapper
      setIsSent={setIsSent}
      setModalOpen={setModalOpen}
      content={content}
      title={title}
      subtitle={subtitle}
    />
  ) : null;
};

export default Modal;
