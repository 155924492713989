import React from 'react';
import { TLinePosition } from '../../types';
import CustomLine from '../CustomLine/CustomLine';

type TProps = {
  customLinePositions: TLinePosition[];
  borderColor: string;
};

const CustomWrapperLines: React.FC<TProps> = ({
  customLinePositions,
  borderColor
}) => {
  return (
    <>
      {customLinePositions.map(({ position, points }) => {
        return (
          <CustomLine
            key={`${position}-${points}`}
            position={position}
            points={points}
            color={borderColor}
          />
        );
      })}
    </>
  );
};

export default CustomWrapperLines;
