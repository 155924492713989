import React from 'react';
import numberDisplay from '../../utils/displayNumber';
import TextWithColor from '../TextWithColor/TextWithColor';
import sm from './styles.module.scss';

interface LoadGroupItemProps {
  label: string;
  color: string;
  weight: number;
  volume: number;
}

const LoadGroupItem: React.FC<LoadGroupItemProps> = ({
  label,
  color,
  volume,
  weight
}) => {
  return (
    <div className={sm.LoadGroupItem}>
      <TextWithColor color={color} label={label} />
      <div>{numberDisplay({ num: weight })}</div>
      <div>{numberDisplay({ num: volume })}</div>
    </div>
  );
};

export default LoadGroupItem;
