import { useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { LoadGroupFields } from '../../../types';

interface UseDeleteLoadGroupApi {
  openedPopupIndex: number;
  handleDeleteItem: (index: number) => void;
  handlePopupOpen: (index: number) => void;
  handleCloseAnyPopup: () => void;
}

export const useDeleteLoadGroup = (): UseDeleteLoadGroupApi => {
  const { control } = useFormContext<LoadGroupFields>();

  const { remove } = useFieldArray<LoadGroupFields>({
    control,
    name: 'loadGroups'
  });

  const [openedPopupIndex, setOpenedPopupIndex] = useState(-1);

  const handlePopupOpen = (index: number) => setOpenedPopupIndex(index);
  const handleCloseAnyPopup = () => setOpenedPopupIndex(-1);

  const handleDeleteItem = (index: number) => {
    remove(index);
    handleCloseAnyPopup();
  };

  return {
    openedPopupIndex,
    handleDeleteItem,
    handlePopupOpen,
    handleCloseAnyPopup
  };
};
