import React, { useMemo, useState } from 'react';
import Button from '../../components/Button/Button';
import ColorsBar from '../../components/ColorsBar/ColorsBar';
import sm from './styles.module.scss';
import { ReactComponent as UpArrow } from '../../assets/images/icon-UpArrow.svg';
import { ReactComponent as DownArrow } from '../../assets/images/icon-DownArrow.svg';
import ContainerLoadsList from './LoadsList/ContainerLoadsList';
import WarningMessage from '../../components/WarningMessage/WarningMessage';
import { useContainerInfo } from './useContainerInfo';
import Container3DVizualSmall from '../Container3DVizualSmall/Container3DVizualSmall';
import { Placement } from '../../types';
import caclCustomLinePosition from '../../utils/caclCustomLinePosition';

interface ContainersListItemProps {
  index: number;
  container: Placement;
  totalSumQuantity: number;
}

const ContainersListItem: React.FC<ContainersListItemProps> = ({
  index,
  container,
  totalSumQuantity
}) => {
  const {
    containerType,
    weightInfo,
    volumeInfo,
    isOpen,
    setOpen,
    totalWeightPercent,
    totalVolumePercent,
    shouldDisplayWarning,
    warningText,
    weightHoveredState,
    setVolumeHoveredColor,
    setWeightHoveredColor,
    volumeHoveredState
  } = useContainerInfo(index);

  const [isHideThreeD, setisHideThreeD] = useState<boolean>(true);
  const {
    container: { width, height, length }
  } = container;

  const customCalcPosition = useMemo(
    () => caclCustomLinePosition(width, height, length),
    [width]
  );

  const openLoadersHeadler = () => {
    if (!isOpen) {
      setOpen(true);
      setisHideThreeD(false);
    } else {
      setisHideThreeD(!isHideThreeD);
    }
  };

  return (
    <div className={sm.ContainersListItem}>
      <div className={sm.ContainersListItem_Title}>{`${containerType} ${
        index + 1
      }`}</div>
      <div className={sm.ContainersListItem_Quantity}>
        {`Total quantity: ${totalSumQuantity} item${
          totalSumQuantity > 1 ? 's' : ''
        }`}
      </div>

      {shouldDisplayWarning ? (
        <WarningMessage message={warningText} />
      ) : (
        <div className={sm.ContainersListItem_Divider} />
      )}
      <div className={sm.ContainersListItem_Content}>
        <ColorsBar
          title="Weight"
          totalPercent={totalWeightPercent}
          colors={weightInfo}
          hoveredColor={weightHoveredState}
          setHoveredColor={setWeightHoveredColor}
          index={index}
        />
        <ColorsBar
          title="Volume"
          totalPercent={totalVolumePercent}
          colors={volumeInfo}
          hoveredColor={volumeHoveredState}
          setHoveredColor={setVolumeHoveredColor}
          index={index}
        />

        <Button
          variant="linkLike"
          title={!isHideThreeD ? 'Hide loads' : 'View loads'}
          iconAfter={!isHideThreeD ? <UpArrow /> : <DownArrow />}
          onClick={() => openLoadersHeadler()}
          classname={sm.ContainersListItem_OpenBtn}
        />

        {isOpen && (
          <div style={{ display: `${isHideThreeD ? 'none' : 'block'}  ` }}>
            <Container3DVizualSmall
              index={index}
              customCalcPosition={customCalcPosition.customLinePositions}
              calculatedContainerSize={customCalcPosition.getedSizes}
            />
            <ContainerLoadsList index={index} />
          </div>
        )}
      </div>
    </div>
  );
};

export default ContainersListItem;
