import React from 'react';
import cn from 'classnames';
import { useHistory } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import Button from '../../../components/Button/Button';
import Total from '../../../components/Total/Total';
import sm from './styles.module.scss';
import useSpaceFormData from '../../SpaceForm/hooks/useSpaceFormData';
import { Paths } from '../../../constants/paths';
import { isDefaultContainersState } from '../../../state/containersState';
import { UnitsSymbolByName } from '../../../constants/unitsConstants';

interface TitleSectionProps {
  totalWeight: number;
  totalVolume: number;
}

const TitleSection: React.FC<TitleSectionProps> = ({
  totalVolume,
  totalWeight
}) => {
  const { currentUnits, isCalculationsLoading } = useSpaceFormData();
  const history = useHistory();
  const [, setContainerState] = useRecoilState(isDefaultContainersState);

  return (
    <div>
      <div className={cn(sm.Row, sm.TitleSection)}>
        <div className={sm.TitleSection_Title}>Load Groups</div>
        <Button
          variant="outlined"
          title="Edit groups"
          classname={sm.TitleSection_Button}
          disabled={isCalculationsLoading}
          onClick={() => {
            setContainerState(true);
            history.push(Paths.LoadGroups);
          }}
        />
      </div>
      <div className={cn(sm.Row, sm.TitleSection_Total)}>
        <div className={cn(sm.Column, sm.TitleSection_Border)}>
          <Total
            label="Total weight"
            value={totalWeight}
            units={UnitsSymbolByName[currentUnits].weight}
          />
        </div>
        <div className={cn(sm.Column, sm.Column_End)}>
          <Total
            label="Total volume"
            value={totalVolume}
            units={UnitsSymbolByName[currentUnits].volume}
          />
        </div>
      </div>
    </div>
  );
};

export default TitleSection;
