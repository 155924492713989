import React from 'react';
import MultipleRange from './MultipleRange/MultipleRange';
import { Limit } from '../../types';
import { useUpdateRange } from './useUpdateRange';
import sm from './styles.module.scss';
import numberDisplay from '../../utils/displayNumber';

interface MultipleRangeFieldProps {
  limitSymbol: string;
  value: Limit;
  onChange: (value: Limit) => void;
  limit: number;
}

const MultipleRangeWithValues: React.FC<MultipleRangeFieldProps> = ({
  value,
  onChange,
  limitSymbol,
  limit
}) => {
  const { max } = value;

  const {
    range,
    maxPercent,
    minPercent,
    minInputValue,
    maxInputValue,
    handleMinInputChange,
    handleMaxInputChange,
    maxLimitValue,
    minLimitValue
  } = useUpdateRange({
    value,
    onChange,
    limit
  });

  return (
    <div className={sm.MultipleRangeWithValues}>
      <div className={sm.MultipleRangeWithValues_Percent}>
        <div ref={minPercent} className={sm.MultipleRangeWithValues_PercentMin}>
          {`${minInputValue}%`}
        </div>
        <div ref={maxPercent} className={sm.MultipleRangeWithValues_PercentMax}>
          {`${maxInputValue}%`}
        </div>
      </div>

      <MultipleRange
        max={max}
        range={range}
        minInputValue={minInputValue}
        handleMinInputChange={handleMinInputChange}
        maxInputValue={maxInputValue}
        handleMaxInputChange={handleMaxInputChange}
      />

      <div className={sm.MultipleRangeWithValues_Value}>
        <div className={sm.MultipleRangeWithValues_ValueLeft}>
          {`${numberDisplay({ num: minLimitValue })} ${limitSymbol}`}
        </div>
        <div className={sm.MultipleRangeWithValues_ValueRight}>
          {`${numberDisplay({ num: maxLimitValue })} ${limitSymbol}`}
        </div>
      </div>
    </div>
  );
};

export default MultipleRangeWithValues;
