import React from 'react';
import cn from 'classnames';
import sm from './styles.module.scss';

const Skeleton: React.FC = () => {
  return (
    <div className={sm.Skeleton}>
      <div className={sm.Skeleton_Content}>
        <div className={sm.Skeleton_Row}>
          <div className={cn(sm.Skeleton_Column, sm.Skeleton_Space)}>
            <div className={sm.Skeleton_SpaceTitle} />

            <div className={cn(sm.Skeleton_Row)}>
              <div className={cn(sm.Skeleton_Column, sm.Skeleton_Animation)}>
                <div className={sm.Skeleton_SpaceText} />
                <div className={sm.Skeleton_SpaceText} />
                <div className={sm.Skeleton_SpaceText} />
                <div className={sm.Skeleton_SpaceText} />
              </div>

              <div className={sm.Skeleton_Column}>
                <div className={sm.Skeleton_SpaceImage} />
              </div>
            </div>
          </div>

          <div className={cn(sm.Skeleton_Column, sm.Skeleton_Size)}>
            <div className={sm.Skeleton_SizeTitle} />

            <div className={cn(sm.Skeleton_Column, sm.Skeleton_Animation)}>
              <div className={sm.Skeleton_SizeText} />
              <div className={sm.Skeleton_SizeText} />
              <div className={sm.Skeleton_SizeText} />
              <div className={sm.Skeleton_SizeText} />
              <div className={sm.Skeleton_SizeText} />
            </div>
          </div>

          <div className={cn(sm.Skeleton_Column, sm.Skeleton_Optimization)}>
            <div className={sm.Skeleton_OptimizationTitle} />

            <div className={cn(sm.Skeleton_Column, sm.Skeleton_Animation)}>
              <div className={sm.Skeleton_OptimizationRange} />
              <div className={sm.Skeleton_OptimizationRange} />
            </div>
          </div>
        </div>

        <div className={sm.Skeleton_Row}>
          <div className={sm.Skeleton_Button} />
        </div>
      </div>
    </div>
  );
};

export default Skeleton;
