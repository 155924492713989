import React from 'react';
import sm from './styles.module.scss';

const Loader: React.FC = () => {
  return (
    <div className={sm.SpinnerContainer}>
      <div className={sm.Spinner}>
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  );
};

export default Loader;
