import React from 'react';
import { LoadGroup } from '../../types';
import sm from './styles.module.scss';

import FirstLineFormSection from './FormSections/FirstLineFormSection/FirstLineFormSection';
import SecondLineFormSection from './FormSections/SecondLineFormSection/SecondLineFormSection';

interface CreateLoadGroupItemProps {
  loadGroup: LoadGroup;
  color: string;
  groupIndex: number;
}

const CreateLoadGroupItem: React.FC<CreateLoadGroupItemProps> = ({
  loadGroup,
  groupIndex,
  color
}) => {
  return (
    <div className={sm.CreateLoadGroupItem}>
      <FirstLineFormSection
        loadGroup={loadGroup}
        color={color}
        groupIndex={groupIndex}
      />

      <div className={sm.CreateLoadGroupItem_Divider} />

      <SecondLineFormSection groupIndex={groupIndex} />
    </div>
  );
};

export default CreateLoadGroupItem;
