import React from 'react';
import LinkButton from '../LinkButton/LinkButton';
import sm from './styles.module.scss';

type TProps = {
  text: string;
  linkTxt: string;
  linkPath: string;
};

const BluePlateWithLink: React.FC<TProps> = ({ text, linkTxt, linkPath }) => {
  return (
    <div className={sm.BluePlateWithLink}>
      <div className={sm.BluePlateWithLink_wrapper}>
        <span className={sm.BluePlateWithLink_text}>{text}</span>
        <LinkButton linkTxt={linkTxt} linkPath={linkPath} />
      </div>
    </div>
  );
};

export default BluePlateWithLink;
