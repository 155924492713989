import React, { ReactElement } from 'react';
import cn from 'classnames';
import { FieldPath, useFormContext } from 'react-hook-form';
import sm from './styles.module.scss';
import { SpaceFormFields } from '../../../../types';

interface RadioProps {
  label: string;
  isSelected: boolean;
  fieldName: FieldPath<SpaceFormFields>;
  defaultValue: string;
  icon: ReactElement;
}

const RadioField: React.FC<RadioProps> = ({
  label,
  isSelected,
  fieldName,
  icon,
  ...rest
}) => {
  const { register } = useFormContext<SpaceFormFields>();

  return (
    <div className={sm.Radio}>
      <input
        {...rest}
        id={`${fieldName}${label}`}
        type="radio"
        checked={isSelected}
        className={sm.Radio_Input}
        {...register(fieldName)}
      />
      <div
        className={cn(sm.Radio_Line, {
          [sm.Radio_Line_Selected]: isSelected
        })}
      />
      <label
        className={cn(sm.Radio_LabelRow, {
          [sm.Radio_Selected]: isSelected
        })}
        htmlFor={`${fieldName}${label}`}
      >
        <div className={sm.Radio_Icon}>{icon}</div>
        <div className={sm.Radio_Label}>{label}</div>
      </label>
    </div>
  );
};

export default RadioField;
