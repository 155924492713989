import React from 'react';
import cn from 'classnames';
import { useRecoilState } from 'recoil';
import { useFormContext } from 'react-hook-form';
import TotalCounter from '../../../components/TotalCounter/TotalCounter';
import numberDisplay from '../../../utils/displayNumber';
import { unitsState } from '../../../state/unitsState';
import { LoadGroupFields } from '../../../types';
import sm from './styles.module.scss';
import { scrollState } from '../../../state/scrollState';
import { UnitsSymbolByName } from '../../../constants/unitsConstants';

interface CountersRowProps {
  totalVolume: number;
  totalWeight: number;
}

const CountersRow: React.FC<CountersRowProps> = ({
  totalVolume,
  totalWeight
}) => {
  const { getValues } = useFormContext<LoadGroupFields>();
  const { loadGroups } = getValues();
  const [isScroll] = useRecoilState(scrollState);
  const [currentUnits] = useRecoilState(unitsState);

  const shouldDecreaseFont =
    numberDisplay({
      num: totalVolume
    }).length >= 8 &&
    numberDisplay({
      num: totalWeight
    }).length >= 8;

  return (
    <div
      className={cn(sm.CountersRow, {
        [sm.CountersRow__Scrolled]: isScroll,
        [sm.CountersRow__Decreased]: shouldDecreaseFont
      })}
    >
      <TotalCounter
        label="Load groups"
        value={loadGroups.length}
        isScroll={isScroll}
        shouldDecreaseFont={shouldDecreaseFont}
      />
      <TotalCounter
        label="Total volume"
        value={numberDisplay({
          num: totalVolume
        })}
        units={UnitsSymbolByName[currentUnits].volume}
        isScroll={isScroll}
        shouldDecreaseFont={shouldDecreaseFont}
      />
      <TotalCounter
        label="Total weight"
        value={numberDisplay({
          num: totalWeight
        })}
        units={UnitsSymbolByName[currentUnits].weight}
        isScroll={isScroll}
        shouldDecreaseFont={shouldDecreaseFont}
      />
    </div>
  );
};

export default CountersRow;
