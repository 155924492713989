import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';

import { boxItems, boxValidation } from '../../state/data-static';
import { unitsState } from '../../state/unitsState';
import { TMes } from '../../types';

import sm from './styles.module.scss';

import ToastWithDeleteBtn from '../ToastWithDeleteBtn/ToastWithDeleteBtn';

type TProps = {
  data: TMes[];
  isScroll: boolean;
};
const ToastesWithCloseBoxSelection: React.FC<TProps> = ({ data, isScroll }) => {
  const [currentUnits] = useRecoilState(unitsState);
  const unit = boxItems[currentUnits];
  const val = boxValidation[currentUnits];
  const [stateData, setStateData] = useState(data);

  useEffect(() => {
    if (data) {
      setStateData(data);
    }
  }, [data]);

  const deleteItemFromState = (): void => {
    setStateData([]);
  };

  return (
    <>
      {stateData.length > 0 && (
        <div
          className={`${sm.ToastesWithClose} ${
            isScroll ? `${sm.ToastesWithClose_scroll}` : ''
          }`}
        >
          <div className={sm.ToastesWithClose_item}>
            <ToastWithDeleteBtn
              title={`${stateData.length} load group${
                stateData.length > 1 ? 's' : ''
              } values are too small`}
              subtitle={`Minimum size values are ${val} ${unit}`}
              onDeleteClick={() => deleteItemFromState()}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default ToastesWithCloseBoxSelection;
