import { AxiosError } from 'axios';
import { SetterOrUpdater } from 'recoil';

export const getRequestErrorByStatus = (
  err: AxiosError,
  setErrorState: SetterOrUpdater<boolean>
): void => {
  const errorStatus = err?.response?.status;
  const hasError = (errorStatus && errorStatus >= 500) || false;

  setErrorState(hasError);

  setTimeout(() => setErrorState(false), 5000);
};
