import * as yup from 'yup';

export const sendResultValidationSchema = yup.object().shape({
  contactInfo: yup.object().shape({
    name: yup
      .string()
      .required('This field is required')
      .max(64, 'Invalid field length'),
    email: yup
      .string()
      .required('This field is required')
      .matches(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        { message: 'Incorrect email' }
      )
  })
});
