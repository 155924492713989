import React from 'react';
import sm from './styles.module.scss';

interface WarningMessageProps {
  message: string;
}

const WarningMessage: React.FC<WarningMessageProps> = ({ message }) => {
  return <div className={sm.WarningMessage}>{message}</div>;
};

export default WarningMessage;
