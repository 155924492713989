import { ReactComponent as Box } from '../../assets/images/landing/descr_data/box.svg';
import { ReactComponent as Calc } from '../../assets/images/landing/descr_data/calc.svg';
import { ReactComponent as Manual } from '../../assets/images/landing/descr_data/manual.svg';
import { ReactComponent as Reslist } from '../../assets/images/landing/descr_data/reslist.svg';
import { ReactComponent as Container } from '../../assets/images/spaceContainer.svg';
import { ReactComponent as Vichle } from '../../assets/images/spaceVehicle.svg';
import { ReactComponent as Storage } from '../../assets/images/spaceStorage.svg';
import { ReactComponent as BoxSpace } from '../../assets/images/box.svg';
import { ReactComponent as CompSmallIcon } from '../../assets/images/landing/benefits_data/comp_small_icon.svg';
import { ReactComponent as HeartSmallIcon } from '../../assets/images/landing/benefits_data/heart_small_icon.svg';
import { ReactComponent as ListSmallIcon } from '../../assets/images/landing/benefits_data/list_small_icon.svg';
import { ReactComponent as MoneySmallIcon } from '../../assets/images/landing/benefits_data/money_small_icon.svg';
import {
  TLangingPageTopDescrCardListData,
  TSpaceCalculatorLandingListData
} from '../../types';

export const LangingPageTopDescrCardListData: TLangingPageTopDescrCardListData[] = [
  {
    text:
      'Calculate the optimal amount of space for load transportation or storage',
    Icon: Calc
  },
  {
    text:
      'Reduce manual data input with predefined cargo groups and calculations',
    Icon: Manual
  },
  { text: 'Create and customize multiple load types', Icon: Box },
  {
    text:
      'Get calculation results straight to your inbox in PDF, CSV, and JSON formats',
    Icon: Reslist
  }
];

export const SpaceCalculatorLandingListData: TSpaceCalculatorLandingListData[] = [
  {
    title: 'Transportation management systems (TMS)',
    featuresList: [
      'Efficiently distribute loads among containers and vehicles',
      'Build optimal dispatch routes with the exact number of containers or vehicles'
    ],
    Icon: Container
  },
  {
    title: 'Fleet management systems (FMS)',
    featuresList: [
      'Quickly assign appropriate trucks and drivers to shipments',
      'Simplify delivery by knowing each truck’s load'
    ],
    Icon: Vichle
  },
  {
    title: 'Warehouse management systems (WMS)',
    featuresList: [
      'Optimize warehouse space usage',
      'Logically allocate loads in warehouses to improve slotting'
    ],
    Icon: Storage
  },
  {
    title: 'Material requirements planning (MRP) systems',
    featuresList: [
      'Optimize inventory for storage or production',
      'Forecast inventory needs'
    ],
    Icon: BoxSpace
  }
];

export const BenefitsCalculatorListItemsData: TLangingPageTopDescrCardListData[] = [
  {
    text:
      'Simplified strategic and tactical planning thanks to optimized space usage for routing and load storage',
    Icon: ListSmallIcon
  },
  {
    text: 'Reduced vehicle and container expenses thanks to efficient loading',
    Icon: MoneySmallIcon
  },
  {
    text:
      'More satisfied customers as enhanced fleet management leads to faster delivery',
    Icon: HeartSmallIcon
  },
  {
    text: 'Manual labor reduction leading to faster time to market',
    Icon: CompSmallIcon
  }
];

export const customLogisticLink =
  'https://yalantis.com/industries/supply-chain-management/';
