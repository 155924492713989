import { DeepMap, FieldError } from 'react-hook-form';
import { LoadGroup, TMes } from '../types';

const getMintypesMessArr = (
  types: (DeepMap<LoadGroup, FieldError> | undefined)[]
): TMes[] => {
  return Object.entries(types)
    .map((item) => item[1])
    .map((item) => {
      if (item?.length) {
        return {
          mes: item.length.message
        };
      }
      if (item?.width) {
        return {
          mes: item.width.message
        };
      }
      if (item?.height) {
        return {
          mes: item.height.message
        };
      }
      return {
        mes: ''
      };
    });
};

export default getMintypesMessArr;
