import React, { ReactElement } from 'react';
import { Container } from '../types';

import { ReactComponent as ContainerImage } from '../assets/images/spaceContainer.svg';
import { ReactComponent as VehicleImage } from '../assets/images/spaceVehicle.svg';
import { ReactComponent as StorageImage } from '../assets/images/spaceStorage.svg';
import { ReactComponent as CustomImage } from '../assets/images/spaceCustom.svg';
import { ReactComponent as ContainerIcon } from '../assets/images/icon-spaceContainer.svg';
import { ReactComponent as VehicleIcon } from '../assets/images/icon-spaceVehicle.svg';
import { ReactComponent as StorageIcon } from '../assets/images/icon-spaceStorage.svg';
import { ReactComponent as CustomIcon } from '../assets/images/icon-spaceCustom.svg';
import { SpaceType } from '../constants/spaceConstants';

interface SpacesDataByTypeApi {
  icon: ReactElement;
  image: ReactElement;
  label: string;
}

const getSpaceDataByType = (space: Container['name']): SpacesDataByTypeApi => {
  const bySpaceType = {
    [SpaceType.Container]: {
      icon: <ContainerIcon />,
      label: 'Container',
      image: <ContainerImage />
    },
    [SpaceType.Vehicle]: {
      icon: <VehicleIcon />,
      label: 'Vehicle',
      image: <VehicleImage />
    },
    [SpaceType.Storage]: {
      icon: <StorageIcon />,
      label: 'Storage',
      image: <StorageImage />
    },
    [SpaceType.Custom]: {
      icon: <CustomIcon />,
      label: 'Custom space',
      image: <CustomImage />
    }
  };

  return bySpaceType[space];
};

export default getSpaceDataByType;
