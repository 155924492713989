import React, { forwardRef, InputHTMLAttributes } from 'react';
import cn from 'classnames';
import sm from './styles.module.scss';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  error?: boolean;
  classname: string;
}

export type IputProps = Props;

const Input: React.FC<Props> = forwardRef<HTMLInputElement, IputProps>(
  ({ error, classname, ...rest }, ref) => {
    return (
      <div className={sm.Input_Container}>
        <input {...rest} className={cn(sm.Input, classname)} ref={ref} />
      </div>
    );
  }
);

export default Input;
