import { FieldPath, UseFormWatch, FieldValues } from 'react-hook-form';
import { LoadGroupFields } from '../../../types';

interface Props {
  value: string;
  fieldName: FieldPath<LoadGroupFields>;
  watch: UseFormWatch<FieldValues>;
}

const setValueAsInteger = ({
  value,
  fieldName,
  watch
}: Props): FieldValues | number => {
  const numVal = parseInt(value, 10);
  if (Number.isNaN(numVal) || value.length >= 4) {
    return watch(fieldName);
  }
  return numVal;
};

export default setValueAsInteger;
